/* import __COLOCATED_TEMPLATE__ from './setting.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type {
  NavSection,
  NavItem,
} from 'embercom/components/new-settings/navigation/submenu-items';
interface SettingArgs {
  config: NavSection;
  setting: NavItem;
  isInteractive: boolean;
}

interface Signature {
  Element: HTMLDivElement;
  Args: SettingArgs;
}

export default class Setting extends Component<Signature> {
  @service declare appService: $TSFixMe;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Home::Setting': typeof Setting;
    'new-settings/home/Setting': typeof Setting;
  }
}
