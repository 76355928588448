/* import __COLOCATED_TEMPLATE__ from './email-dropdown-item.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  item: {
    text: string;
    value: string;
    componentAttrs: {
      verified: boolean;
      isSelected: boolean;
      isAuthenticated: boolean;
      id: string;
    };
  };
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
  Blocks: {
    default: [];
  };
}

export default templateOnlyComponent<Signature>();

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::Advanced::EmailDropdownItem': ReturnType<
      typeof templateOnlyComponent<Signature>
    >;
  }
}
