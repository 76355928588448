/* import __COLOCATED_TEMPLATE__ from './editor.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from 'tracked-built-ins';
import { action } from '@ember/object';
import type AwayStatusReason from 'embercom/models/away-status-reason';
import { randomEmoji } from 'embercom/lib/available-emoji';
import type IntlService from 'ember-intl/services/intl';
// @ts-ignore
import type NotificationsService from 'embercom/services/notifications-service';
import { ResponseError } from 'embercom/lib/inbox/requests';

interface Args {
  awayStatusReasons: any;
  isOpen: boolean;
  onClose: () => void;
}

interface Signature {
  Element: never;
  Args: Args;
}

export default class AwayReasonsEditor extends Component<Signature> {
  @service declare session: any;
  @service store: any;
  @service declare intl: IntlService;
  // @ts-ignore
  @service declare notificationsService: NotificationsService;

  @tracked currentTab = 'live';
  @tracked unsavedChangesModalVisible = false;

  static MINIMUM_ACTIVE_REASONS_COUNT = 3;
  static MAXIMUM_ACTIVE_REASONS_COUNT = 100;

  get liveReasons() {
    return this.args.awayStatusReasons
      .filter((reason: AwayStatusReason) => !reason.deleted)
      .sortBy('order');
  }

  get archivedReasons() {
    return this.args.awayStatusReasons
      .filter((reason: AwayStatusReason) => reason.deleted)
      .sortBy('label');
  }

  get currentReasonsList() {
    return this.currentTab === 'live' ? this.liveReasons : this.archivedReasons;
  }

  get canCreateNew() {
    return this.liveReasons.length < AwayReasonsEditor.MAXIMUM_ACTIVE_REASONS_COUNT;
  }

  get hasMinimumReasonsCount() {
    return this.liveReasons.length > AwayReasonsEditor.MINIMUM_ACTIVE_REASONS_COUNT;
  }

  get newAddedItemOrder() {
    return Math.max(...this.args.awayStatusReasons.mapBy('order')) + 1;
  }

  @action createNew() {
    this.currentTab = 'live';
    this.store.createRecord('away-status-reason', {
      label: '',
      emoji: randomEmoji(),
      order: this.newAddedItemOrder,
    });
  }

  @action async reorder(sortedItems: Array<AwayStatusReason>, draggedItem: AwayStatusReason) {
    try {
      sortedItems.forEach((item, index) => {
        this.store.pushPayload('away-status-reason', {
          away_status_reason: {
            id: item.id,
            order: index,
          },
        });
      });
      await draggedItem.reorder(draggedItem.order);
    } catch (e) {
      if (e instanceof ResponseError && e.response.status === 404) {
        this.handle404Gracefully('reorder');
        return;
      }
      this.notificationsService.notifyError(
        this.intl.t(this._translationKey(`notifications.reorder-failure`)),
      );
    }
  }

  @action handle404Gracefully(type: string) {
    this.notificationsService.notifyError(
      this.intl.t(this._translationKey(`notifications.not-found.${type}-failure`)),
    );
    this.store.findAll('away-status-reason', { reload: true });
  }

  @action onClose() {
    if (this.args.awayStatusReasons.isAny('hasDirtyAttributes')) {
      this.unsavedChangesModalVisible = true;
    } else {
      this.args.onClose();
    }
  }

  @action forceClose() {
    this.unsavedChangesModalVisible = false;
    this.args.awayStatusReasons.forEach((reason: AwayStatusReason) => {
      if (reason.hasDirtyAttributes as unknown as boolean) {
        reason.rollbackAttributes();
      }
    });
    this.args.onClose();
  }

  _translationKey(key: string) {
    return `new-settings.helpdesk.assignments.other.away-reasons.${key}`;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Helpdesk::AwayStatusReasons::Editor': typeof AwayReasonsEditor;
  }
}
