/* import __COLOCATED_TEMPLATE__ from './overview-step.hbs'; */
/* RESPONSIBLE TEAM: team-channels */

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { taskFor } from 'ember-concurrency-ts';
import type IntlService from 'ember-intl/services/intl';
import { type Domain, type DomainEmailAddress } from 'embercom/services/domain-service';
import type EntriConnectService from 'embercom/services/entri-connect-service';
import type SenderEmailVerificationService from 'embercom/services/sender-email-verification-service';
import { type SafeString } from 'handlebars';
import { type CtaType } from './step';

interface Signature {
  Args: {
    emailAddress: DomainEmailAddress | undefined;
    changeStep: (step: string | null, place: string) => void;
    domain: Domain | null;
    setPreviousStepForDomainSetup: (step: string | null) => void;
  };
}

type AuthenticateDomainStepData = {
  title: string | SafeString;
  description: string | SafeString;
  stepCompleted?: boolean;
  inlineLinkText?: string;
  inlineLinkOnClick?: () => Promise<void>;
  ctaType?: CtaType;
};

export default class OverviewStep extends Component<Signature> {
  @service declare appService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare senderEmailVerificationService: SenderEmailVerificationService;
  @service declare intercomEventService: $TSFixMe;
  @service declare entriConnectService: EntriConnectService;
  @service declare notificationsService: $TSFixMe;
  @tracked domainCanBeAutoConfigured = false;

  place = 'overview';
  translationPrefix = 'new-settings.channels.email.connect-email.email-setup.overview-step';

  get app() {
    return this.appService.app;
  }

  get isEmailVerified() {
    return this.args.emailAddress?.verified ?? false;
  }

  get emailForwardingEnabled() {
    return this.args.emailAddress?.forwardingEnabled ?? false;
  }

  get domainAuthenticated() {
    return this.args.domain?.isAuthenticated ?? false;
  }

  get domainAuthenticationAttempted() {
    return this.args.domain?.dkimSettings?.hasAuthenticationAttempt ?? false;
  }

  get domainAuthenticationPending() {
    return this.domainAuthenticationAttempted && !this.domainAuthenticated;
  }

  get outboundOnly() {
    return this.args.emailAddress?.outboundOnly ?? false;
  }

  get setupComplete() {
    if (this.outboundOnly) {
      return this.isEmailVerified && this.domainAuthenticated;
    } else {
      return this.emailForwardingEnabled && this.domainAuthenticated;
    }
  }

  get domainStepNumber() {
    if (this.domainAuthenticationAttempted && !this.domainAuthenticated) {
      return;
    }
    return 2;
  }

  get emailVerificationStepData(): {
    title: string | SafeString;
    description: string | SafeString;
    stepCompleted?: boolean;
    inlineLinkText?: string;
    inlineLinkOnClick?: () => Promise<void>;
  } {
    let isVerified = this.isEmailVerified;
    let email = this.args.emailAddress?.email;

    return {
      title: isVerified
        ? this.intl.t(`${this.translationPrefix}.verify-email.title-verified`, {
            email,
            htmlSafe: true,
          })
        : this.intl.t(`${this.translationPrefix}.verify-email.title-verifying`),
      description: isVerified
        ? this.intl.t(`${this.translationPrefix}.verify-email.description-verified`, {
            email,
            htmlSafe: true,
          })
        : this.intl.t(`${this.translationPrefix}.verify-email.description-verifying`, {
            email,
            htmlSafe: true,
          }),
      stepCompleted: isVerified,
      inlineLinkText: isVerified
        ? undefined
        : this.intl.t(`${this.translationPrefix}.verify-email.resend-verification-email`),
      inlineLinkOnClick: this.resendVerificationEmail.bind(this),
    };
  }

  get forwardEmailsTitle(): string | SafeString {
    return this.emailForwardingEnabled
      ? this.intl.t(`${this.translationPrefix}.forward-emails.title-forwarding-enabled`)
      : this.intl.t(`${this.translationPrefix}.forward-emails.title-set-up-forwarding`);
  }

  get forwardEmailsDescription(): string | SafeString {
    return this.emailForwardingEnabled
      ? this.intl.t(`${this.translationPrefix}.forward-emails.description-forwarding-enabled`, {
          email: this.args.emailAddress?.email,
          htmlSafe: true,
        })
      : this.intl.t(`${this.translationPrefix}.forward-emails.description-set-up-forwarding`);
  }

  get authenticateDomainStepData(): AuthenticateDomainStepData {
    let stepData = {
      stepCompleted: this.domainAuthenticated,
      ctaType: 'primary',
    } as AuthenticateDomainStepData;
    if (this.domainAuthenticated) {
      // step completed
      stepData.title = this.intl.t(
        `${this.translationPrefix}.authenticate-domain.title-authenticated`,
      );
      stepData.description = this.intl.t(
        `${this.translationPrefix}.authenticate-domain.description-authenticated`,
        {
          domainName: this.args.domain?.name,
          htmlSafe: true,
        },
      );
    } else if (this.domainAuthenticationAttempted) {
      stepData.title = this.intl.t(
        `${this.translationPrefix}.authenticate-domain.title-authentication-pending`,
      );
      stepData.description = this.intl.t(
        `${this.translationPrefix}.authenticate-domain.description-authentication-pending`,
      );
      stepData.inlineLinkText = this.intl.t(
        `${this.translationPrefix}.authenticate-domain.check-authentication-status`,
      );
      stepData.inlineLinkOnClick = this.verifyDomain.bind(this);
      stepData.ctaType = undefined; // hide CTA button if authentication pending
    } else {
      stepData.title = this.intl.t(
        `${this.translationPrefix}.authenticate-domain.title-authenticate`,
      );
      stepData.description = this.intl.t(
        `${this.translationPrefix}.authenticate-domain.description-authenticate`,
      );
    }
    return stepData;
  }

  async verifyDomain(): Promise<void> {
    if (!this.args.domain) {
      return;
    }
    try {
      await this.args.domain.dkimSettings.verify();
      this.notificationsService.notifyConfirmation(
        this.intl.t(
          'new-settings.channels.email.addresses.domain-sidebar.domain-verification-success',
        ),
      );
    } catch (error) {
      this.notificationsService.notifyWarning(
        this.intl.t(
          'new-settings.channels.email.addresses.domain-sidebar.domain-verification-failed',
        ),
      );
    }
  }

  async resendVerificationEmail() {
    await this.senderEmailVerificationService.resendVerificationEmail({
      senderEmailAddressSettingsId: this.args.emailAddress?.id?.toString() ?? '',
      appId: this.appService.app.id,
      adminId: this.appService.app.currentAdmin.id,
      emailSetup: this.place,
      allowForwarding: String(!this.outboundOnly),
    });
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'resend_verification_email',
      place: this.place,
      context: 'email-setup-sidebar',
    });
  }

  @action async onAuthenticateDomainManuallyClick() {
    this.args.changeStep('setup-domain-manually', this.place);
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'authenticate_domain_manually',
      place: this.place,
      context: 'email-setup-sidebar',
    });
    await this.args.domain?.dkimSettings?.deleteAuthenticationAttempt();
  }

  get dkimSettings() {
    return this.args.domain?.dkimSettings;
  }

  get customBounceSettings() {
    return this.args.domain?.bounceSettings;
  }

  get canUseAutomaticStep() {
    return this.domainCanBeAutoConfigured;
  }

  @action onForwardEmailsClick() {
    this.args.changeStep('setup-email-forwarding', this.place);
  }

  @action async onSetupDomainClick() {
    this.args.setPreviousStepForDomainSetup(this.place);
    await this.checkDomainAndLoadEntri();
    if (this.canUseAutomaticStep) {
      this.args.changeStep('setup-domain', this.place);
    } else {
      this.args.changeStep('setup-domain-manually', this.place);
    }
  }

  @action
  async checkDomainAndLoadEntri(): Promise<void> {
    this.domainCanBeAutoConfigured = await taskFor(this.entriConnectService.checkDomain).perform(
      Number(this.dkimSettings?.id) ?? 0,
      Number(this.customBounceSettings?.id) ?? 0,
    );
    if (this.domainCanBeAutoConfigured) {
      taskFor(this.entriConnectService.loadEntriLibrary).perform();
    }
  }

  @action onFinishSetupClick() {
    this.args.changeStep(null, this.place);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::DomainsAndAddresses::OverviewStep': typeof OverviewStep;
    'new-settings/channels/email/domains-and/addresses/overview-step': typeof OverviewStep;
  }
}
