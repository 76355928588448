/* import __COLOCATED_TEMPLATE__ from './editable-breadcrumb.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import Component from '@glimmer/component';
import type HelpCenterSite from 'embercom/models/help-center-site';
import { inject as service } from '@ember/service';

interface Args {
  site: HelpCenterSite;
}

interface Signature {
  Args: Args;
  Element: Element;
}

export default class EditableBreadcrumb extends Component<Signature> {
  @service declare appService: any;

  get app() {
    return this.appService.app;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Helpcenter::EditableBreadcrumb': typeof EditableBreadcrumb;
    'new-settings/helpcenter/editable-breadcrumb': typeof EditableBreadcrumb;
  }
}
