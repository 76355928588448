/* import __COLOCATED_TEMPLATE__ from './default-site-badge.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

interface Args {
  showTooltip: boolean;
  isDefault: boolean;
}

interface Signature {
  Args: Args;
  Element: Element;
}

export default class DefaultSiteBadge extends Component<Signature> {
  @service appService: any;
  @tracked isHovered = false;

  get app() {
    return this.appService.app;
  }

  get hoverTooltip() {
    return this.isHovered;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Helpcenter::DefaultSiteBadge': typeof DefaultSiteBadge;
    'new-settings/helpcenter/default-site-badge': typeof DefaultSiteBadge;
  }
}
