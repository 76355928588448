/* import __COLOCATED_TEMPLATE__ from './authentication-methods.hbs'; */
/* RESPONSIBLE TEAM: team-app-security */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import { AUTH_METHODS } from 'embercom/components/new-settings/workspace/security/general';
import { action } from '@ember/object';
import type Store from '@ember-data/store';
interface AuthenticationMethodsArgs {
  settings: any;
  samlAccount: any; // Replace 'any' with proper SAML account type
  showAllErrors: boolean;
  scimSettings: any; // Replace 'any' with proper SCIM settings type
  onProvisioningMethodUpdate: (method: string) => void;
  samlBaseUrl: string;
}

export default class AuthenticationMethodsComponent extends Component<AuthenticationMethodsArgs> {
  @service intl!: IntlService;
  @service declare appService: $TSFixMe;
  @service declare store: Store;

  get emailPasswordTooltip(): string {
    return this.emailPasswordEnabled
      ? this.intl.t('apps.app.settings.security.new-auth-method-ui.basic-auth-warning')
      : '';
  }

  get emailPasswordEnabled(): boolean {
    return this.args.settings.authorizedSignInMethods.includes(AUTH_METHODS.emailPassword);
  }

  get twoFAEnabled(): boolean {
    return this.args.settings.authorizedSignInMethods.includes(AUTH_METHODS.twoFa);
  }

  get googleSignInEnabled(): boolean {
    return this.args.settings.authorizedSignInMethods.includes(AUTH_METHODS.google);
  }

  get samlEnabled(): boolean {
    return this.args.settings.authorizedSignInMethods.includes(AUTH_METHODS.saml);
  }

  get canEnableSaml() {
    return this.appService.app.canUseSamlSso;
  }

  get isLastEnabledMethod() {
    let enabledMethods = [
      this.emailPasswordEnabled,
      this.twoFAEnabled,
      this.googleSignInEnabled,
      this.samlEnabled,
    ].filter(Boolean);

    return enabledMethods.length === 1;
  }

  isMethodEnabled(method: string): boolean {
    return this.args.settings.authorizedSignInMethods.includes(method);
  }

  @action
  toggleAuthMethod(method: string): void {
    if (this.isMethodEnabled(method) && this.isLastEnabledMethod) {
      return;
    }

    if (method === AUTH_METHODS.saml && !this.args.samlAccount) {
      let { id } = this.appService.app;
      this.args.settings.set(
        'samlAccount',
        this.store.createRecord('saml-account', { id, name: id }),
      );
    }
    let methods = new Set(this.args.settings.authorizedSignInMethods);
    methods.has(method) ? methods.delete(method) : methods.add(method);
    this.args.settings.set('authorizedSignInMethods', Array.from(methods));
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Workspace::Security::AuthenticationMethods': typeof AuthenticationMethodsComponent;
    'new-settings/workspace/security/authentication-methods': typeof AuthenticationMethodsComponent;
  }
}
