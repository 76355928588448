/* import __COLOCATED_TEMPLATE__ from './status-badge.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type HelpCenterSite from 'embercom/models/help-center-site';

interface Signature {
  Args: {
    isLive: boolean;
    text?: string;
    actAsLink?: boolean;
    openLinkInNewTab?: boolean;
  };
  Element: HTMLAnchorElement;
}

export default class StatusBadge extends Component<Signature> {
  @service appService: any;
  @service helpCenterService: any;

  get app() {
    return this.appService.app;
  }

  get site() {
    return this.helpCenterService.site;
  }

  get sites(): HelpCenterSite[] {
    return this.helpCenterService.sites;
  }

  get hasSingleSite() {
    return this.sites?.length === 1 && !!this.site;
  }

  get actAsLink() {
    if (this.args.actAsLink === undefined) {
      return true;
    }

    return this.args.actAsLink;
  }

  get openLinkInNewTab() {
    if (this.args.openLinkInNewTab === undefined) {
      return false;
    }

    return this.args.openLinkInNewTab;
  }

  get shouldShowText() {
    if (this.args.text === undefined) {
      return true;
    }

    return this.args.text !== '';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Helpcenter::StatusBadge': typeof StatusBadge;
    'new-settings/helpcenter/status-badge': typeof StatusBadge;
  }
}
