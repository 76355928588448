/* import __COLOCATED_TEMPLATE__ from './body.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */

import { action } from '@ember/object';
import Component from '@glimmer/component';
import type ExternalContent from 'embercom/models/external-content';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import type ContentImportSource from 'embercom/models/content-service/content-import-source';
import { tracked } from '@glimmer/tracking';
import { isLocaleSupported } from 'embercom/components/content-service/ai-content-library/helpers';

interface Args {
  externalContent?: ExternalContent;
  onUpdate: () => void;
}

export default class Body extends Component<Args> {
  @service declare store: Store;
  @service declare intercomEventService: any;
  @service declare appService: any;
  @service declare notificationsService: any;
  @tracked selectedContentPreviewType = 'readable';
  @tracked openSectionId: string | null = 'details';

  constructor(owner: unknown, args: Args) {
    super(owner, args);
  }

  get app() {
    return this.appService.app;
  }

  get sourceIsInUse(): boolean {
    return Boolean(this.source?.isInUse);
  }

  get isLanguageSupported() {
    if (!this.args.externalContent) {
      return false;
    }

    return isLocaleSupported(this.args.externalContent.languageCode);
  }

  get isUsedByFin(): boolean {
    return Boolean(
      this.isLanguageSupported && this.args.externalContent?.isPublished && this.sourceIsInUse,
    );
  }

  get toggleIsEnabled(): boolean {
    return this.isLanguageSupported && this.sourceIsInUse;
  }

  @action async toggleStatus(): Promise<void> {
    this.trackAnalyticsEvent('toggled', 'used_by_fin');

    if (!this.args.externalContent) {
      return;
    }

    try {
      await this.args.externalContent.toggleState();
      this.args.onUpdate();
    } catch (e) {
      this.notificationsService.notifyResponseError(e);
    }
  }

  @action async setLocale(locale: string): Promise<void> {
    try {
      this.trackAnalyticsEvent('clicked', 'locale');
      await this.args.externalContent?.updateLanguageCode(locale);
      this.args.onUpdate();
    } catch (e) {
      this.notificationsService.notifyResponseError(e);
    }
  }

  @action onOpenSectionChange(sectionId: string) {
    this.openSectionId = sectionId;
  }

  @action selectContentPreviewType(contentPreviewType: string): void {
    this.selectedContentPreviewType = contentPreviewType;
  }

  @action trackUrlAnalytics(): void {
    this.trackAnalyticsEvent('clicked', 'source_url');
  }

  private get source(): ContentImportSource | null {
    if (!this.args.externalContent?.sourceId) {
      return null;
    }
    return this.store.peekRecord(
      'content-service/content-import-source',
      this.args.externalContent?.sourceId,
    );
  }

  private trackAnalyticsEvent(action: string, object: string): void {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object,
      section: 'library_side_drawer',
      context: 'external_content_library',
      place: 'external_content',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::ExternalContent::View::Body': typeof Body;
  }
}
