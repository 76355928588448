/* import __COLOCATED_TEMPLATE__ from './prerequisites.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import { type Router } from '@ember/routing';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import type IntlService from 'ember-intl/services/intl';
import type HelpCenterSite from 'embercom/models/help-center-site';

type Prerequisite = {
  id: string;
  title: string;
  description: string;
  icon: InterfaceIconName;
  isPending: boolean;
  onClick: () => void;
};

interface Args {
  selectedHelpCenter?: HelpCenterSite;
  cookieForwardingTestFailed: boolean;
  messengerInstalled: boolean;
  customDomainSetup: boolean;
  idvEnabled: boolean;
  companiesUpdatesPrevented: boolean;
}

interface Signature {
  Args: Args;
  Element: Element;
}

export default class Prerequisites extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare router: Router;

  constructor(owner: $TSFixMe, args: Args) {
    super(owner, args);
  }

  get prerequisites(): Prerequisite[] {
    return [
      {
        id: 'messenger-installed',
        title: this.intl.t(
          'new-settings.helpdesk.tickets.tickets-portal.prerequisites.messenger-installed.title',
        ),
        description: this.intl.t(
          'new-settings.helpdesk.tickets.tickets-portal.prerequisites.messenger-installed.description',
        ),
        icon: 'messenger',
        isPending: !this.args.messengerInstalled,
        onClick: () =>
          this.router.transitionTo('apps.app.settings.channels.messenger.install', {
            queryParams: { section: 'web-messenger-setup' },
          }),
      },
      {
        id: 'help-center-custom-domain',
        title: this.intl.t(
          'new-settings.helpdesk.tickets.tickets-portal.prerequisites.help-center-custom-domain.title',
        ),
        description: this.intl.t(
          'new-settings.helpdesk.tickets.tickets-portal.prerequisites.help-center-custom-domain.description',
        ),
        icon: 'article',
        isPending: !this.args.customDomainSetup,
        onClick: () =>
          this.router.transitionTo(
            'apps.app.settings.helpcenter.workspace-helpcenter.settings',
            this.args.selectedHelpCenter?.id,
          ),
      },
      {
        id: 'identity-verification',
        title: this.intl.t(
          'new-settings.helpdesk.tickets.tickets-portal.prerequisites.idv-enabled.title',
        ),
        description: this.intl.t(
          'new-settings.helpdesk.tickets.tickets-portal.prerequisites.idv-enabled.description',
        ),
        icon: 'qual-identify',
        isPending: !this.args.idvEnabled,
        onClick: () =>
          this.router.transitionTo('apps.app.settings.channels.messenger.install', {
            queryParams: { section: 'web-idv-setup' },
          }),
      },
      {
        id: 'prevent-companies-updates',
        title: this.intl.t(
          'new-settings.helpdesk.tickets.tickets-portal.prerequisites.prevent-companies-updates.title',
        ),
        description: this.intl.t(
          'new-settings.helpdesk.tickets.tickets-portal.prerequisites.prevent-companies-updates.description',
        ),
        icon: 'all-companies',
        isPending: !this.args.companiesUpdatesPrevented,
        onClick: () =>
          this.router.transitionTo('apps.app.settings.data.people', {
            queryParams: { tab: 'attributes' },
          }),
      },
    ];
  }
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Helpdesk::Tickets::TicketsPortal::Prerequisites': typeof Prerequisites;
    'new-settings/helpdesk/tickets/tickets-portal/prerequisites': typeof Prerequisites;
  }
}
