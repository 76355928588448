/* import __COLOCATED_TEMPLATE__ from './use-support-content.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import type { FinSetupBehaviorSaveParams } from 'embercom/lib/operator/fin/types';
import type IntlService from 'embercom/services/intl';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import { EntityType } from 'embercom/models/data/entity-types';
import type { ChannelType } from 'embercom/lib/workflows/fin-workflow-preview';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
interface Args {
  accordion: $TSFixMe;
  ruleset: $TSFixMe;
  partialSave: (params: FinSetupBehaviorSaveParams) => void;
  channelType: ChannelType;
}

export default class UseSupportContent extends Component<Args> {
  @service declare appService: $TSFixMe;
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare knowledgeHubService: KnowledgeHubService;

  get subheader() {
    return this.contentsSummary
      .slice(0, 3)
      .map((content) => content.title)
      .join(', ');
  }

  get totalContents() {
    return this.contentsSummary.reduce((acc, content) => acc + content.count, 0);
  }

  get contentsSummary(): {
    title: string;
    icon: InterfaceIconName;
    type: string;
    count: number;
  }[] {
    let results = [];
    if (this.knowledgeHubService.usageSummary?.[EntityType.ArticleContent]?.agent) {
      results.push({
        title: this.intl.t('operator.fin.setup.setup-and-go-live.use-support-content.articles', {
          count: this.knowledgeHubService.usageSummary?.[EntityType.ArticleContent]?.agent || 0,
        }),
        count: this.knowledgeHubService.usageSummary?.[EntityType.ArticleContent]?.agent || 0,
        icon: 'knowledge' as InterfaceIconName,
        type: 'article',
      });
    }

    if (this.knowledgeHubService.usageSummary?.[EntityType.ContentSnippet]?.agent) {
      results.push({
        title: this.intl.t('operator.fin.setup.setup-and-go-live.use-support-content.snippets', {
          count: this.knowledgeHubService.usageSummary?.[EntityType.ContentSnippet]?.agent || 0,
        }),
        count: this.knowledgeHubService.usageSummary?.[EntityType.ContentSnippet]?.agent || 0,
        icon: 'globe' as InterfaceIconName,
        type: 'snippet',
      });
    }

    if (this.knowledgeHubService.usageSummary?.[EntityType.ExternalContent]?.all) {
      let pageCount =
        this.knowledgeHubService.usageSummary?.[EntityType.ExternalContent]?.agent || 0;
      let websiteCount =
        this.knowledgeHubService.usageSummary?.[EntityType.ExternalContent]?.sync_sources?.length ||
        0;
      let title =
        websiteCount === 1
          ? this.intl.t(
              'operator.fin.setup.setup-and-go-live.use-support-content.pages_single_website',
              { pageCount },
            )
          : this.intl.t(
              'operator.fin.setup.setup-and-go-live.use-support-content.pages_multi_website',
              { pageCount, websiteCount },
            );
      results.push({
        title,
        icon: 'note' as InterfaceIconName,
        count: this.knowledgeHubService.usageSummary?.[EntityType.ExternalContent]?.agent || 0,
        type: 'page',
      });
    }
    if (this.knowledgeHubService.usageSummary?.[EntityType.FileSourceContent]?.agent) {
      results.push({
        title: this.intl.t('operator.fin.setup.setup-and-go-live.use-support-content.pdfs', {
          count: this.knowledgeHubService.usageSummary?.[EntityType.FileSourceContent]?.agent || 0,
        }),
        icon: 'link' as InterfaceIconName,
        count: this.knowledgeHubService.usageSummary?.[EntityType.FileSourceContent]?.agent || 0,
        type: 'pdf',
      });
    }

    return results;
  }
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::Setup::Sections::UseSupportContent': typeof UseSupportContent;
  }
}
