/* import __COLOCATED_TEMPLATE__ from './phone-preview.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {
    ruleset: $TSFixMe;
    goToVoiceTesting: () => void;
  };
  Element: HTMLDivElement;
}

const PhonePreview = templateOnlyComponent<Signature>();
export default PhonePreview;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::Setup::PhonePreview': typeof PhonePreview;
    'operator/fin/setup/phone-preview': typeof PhonePreview;
  }
}
