/* import __COLOCATED_TEMPLATE__ from './who-will-talk-to-fin.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type Store from '@ember-data/store';
import type Profile from 'embercom/models/ai-agent/profile';
import type { FinSetupBehaviorSaveParams } from 'embercom/lib/operator/fin/types';
import { action } from '@ember/object';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    accordion: any;
    ruleset: $TSFixMe;
    partialSave: (params: $TSFixMe) => Promise<void>;
  };
}

export default class WhoWillTalkToFin extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare store: Store;

  get ruleset() {
    return this.args.ruleset;
  }

  get predicateGroup() {
    return this.ruleset.predicateGroup;
  }

  get hasComparator() {
    return this.predicateGroup.predicates.firstObject.type === 'or';
  }

  get behavior(): Profile {
    return this.ruleset.rulesetLinks.firstObject.object;
  }

  get finProbabilityDropdownValues() {
    let items = [];
    for (let i = 1; i < 10; i++) {
      items.push({
        value: i * 10,
        text: `${i * 10}%`,
      });
    }

    items.push({
      value: null,
      text: this.intl.t('operator.fin.setup.setup-and-go-live.audience.entire-audience'),
    });

    return items;
  }

  constructor(owner: unknown, args: Signature['Args']) {
    super(owner, args);
  }

  @action setProbabilityFinLaunches(value: number | null) {
    this.behavior.matchPercentage = value;
  }

  @action
  async updateAudienceRules() {
    let target_channels = this.behavior.targetChannels;

    if (!this.predicateGroup.isAndMode) {
      this.predicateGroup.switchLogicalMode('and');
    }

    let params: FinSetupBehaviorSaveParams = {
      audience_rules: {
        predicate_group: this.ruleset.predicateGroup,
        client_predicate_group: this.ruleset.clientPredicateGroup,
        role_predicate_group: this.ruleset.rolePredicateGroup,
        target_channels,
      },
    };

    if (this.behavior.matchPercentage !== undefined) {
      params = {
        ...params,
        match_percentage: this.behavior.matchPercentage,
      };
    }

    await this.args.partialSave(params);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::Setup::Sections::WhoWillTalkToFin': typeof WhoWillTalkToFin;
  }
}
