/* import __COLOCATED_TEMPLATE__ from './access-setup-domain.hbs'; */
/* RESPONSIBLE TEAM: team-channels */

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { taskFor } from 'ember-concurrency-ts';
import type IntlService from 'ember-intl/services/intl';
import { type Domain, type DomainEmailAddress } from 'embercom/services/domain-service';
import type EntriConnectService from 'embercom/services/entri-connect-service';
import type SenderEmailVerificationService from 'embercom/services/sender-email-verification-service';
import { type SafeString } from 'handlebars';

interface Args {
  changeStep: (step: string | null, place: string) => void;
  domain: Domain | null;
  emailAddress?: DomainEmailAddress | null;
  setPreviousStepForDomainSetup: (step: string | null) => void;
}

interface Signature {
  Args: Args;
}

interface DomainEmailAddressItem {
  value: string | number;
  text: string;
  component: string;
  componentAttrs?: {
    verified?: boolean;
    isSelected?: boolean;
    isAuthenticated?: any;
  };
  isDisabled?: boolean;
}

export default class AccessSetupDomain extends Component<Signature> {
  @service declare appService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare entriConnectService: EntriConnectService;
  @service declare senderEmailVerificationService: SenderEmailVerificationService;
  @service declare intercomEventService: $TSFixMe;

  @tracked selectedEmailIdToBeVerified?: string | null;
  @tracked domainCanBeAutoConfigured = false;
  @tracked emailVerificationSent = false;

  place = 'access-setup-domain';
  overviewTranslationPrefix = 'new-settings.channels.email.connect-email.email-setup.overview-step';

  TRANSLATION_KEYS: any = {
    initial: {
      title:
        'new-settings.channels.email.connect-email.email-setup.access-setup-domain-step.verify-email.title',
      description:
        'new-settings.channels.email.connect-email.email-setup.access-setup-domain-step.verify-email.description',
    },
    verified: {
      title: `${this.overviewTranslationPrefix}.verify-email.title-verified`,
      description: `${this.overviewTranslationPrefix}.verify-email.description-verified`,
    },
    verifying: {
      title: `${this.overviewTranslationPrefix}.verify-email.title-verifying`,
      description: `${this.overviewTranslationPrefix}.verify-email.description-verifying`,
    },
  };

  constructor(owner: any, args: Args) {
    super(owner, args);
    if (this.args.emailAddress) {
      this.selectedEmailIdToBeVerified = this.args.emailAddress.id.toString();
      this.emailVerificationSent = true;
    }
  }

  get app() {
    return this.appService.app;
  }

  get canUseAutomaticStep() {
    return this.domainCanBeAutoConfigured;
  }

  get dkimSettings() {
    return this.args.domain?.dkimSettings;
  }

  get customBounceSettings() {
    return this.args.domain?.bounceSettings;
  }

  get emailAddress() {
    if (this.args.emailAddress) {
      return this.args.emailAddress;
    }
    return this.args.domain?.addresses.find(
      (address: DomainEmailAddress) => address.id === this.selectedEmailIdToBeVerified,
    );
  }

  get isEmailVerified() {
    return this.emailAddress?.verified ?? false;
  }

  get domainAuthenticated() {
    return this.args.domain?.isAuthenticated ?? false;
  }

  get isInitialState() {
    return !this.isEmailVerified && !this.emailVerificationSent;
  }

  get componentTitle() {
    return this.getTranslation('title');
  }

  get componentDescription() {
    return this.getTranslation('description');
  }

  get domainEmailAddresses(): DomainEmailAddressItem[] {
    return (
      this.args.domain?.addresses.map((address: DomainEmailAddress) => ({
        value: address.id,
        text: address.email,
        component: 'new-settings/channels/email/advanced/email-dropdown-item',
        componentAttrs: {
          id: address.id,
          isSelected: this.selectedEmailIdToBeVerified === address.id,
        },
      })) ?? []
    );
  }

  get resendVerificationEmailText() {
    return this.emailVerificationSent && !this.isEmailVerified
      ? this.intl.t(`${this.overviewTranslationPrefix}.verify-email.resend-verification-email`)
      : undefined;
  }

  get authenticateDomainTitle(): string | SafeString {
    return this.domainAuthenticated
      ? this.intl.t(`${this.overviewTranslationPrefix}.authenticate-domain.title-authenticated`)
      : this.intl.t(`${this.overviewTranslationPrefix}.authenticate-domain.title-authenticate`);
  }

  get authenticateDomainDescription(): string | SafeString {
    return this.domainAuthenticated
      ? this.intl.t(
          `${this.overviewTranslationPrefix}.authenticate-domain.description-authenticated`,
          {
            domainName: this.args.domain?.name,
            htmlSafe: true,
          },
        )
      : this.intl.t(
          `${this.overviewTranslationPrefix}.authenticate-domain.description-authenticate`,
        );
  }

  getTranslation(type: 'title' | 'description') {
    if (this.isInitialState) {
      return this.intl.t(this.TRANSLATION_KEYS.initial[type]);
    }

    let status = this.isEmailVerified ? 'verified' : 'verifying';
    let translationOptions =
      type === 'description' ? { email: this.emailAddress?.email, htmlSafe: true } : undefined;

    return this.intl.t(this.TRANSLATION_KEYS[status][type], translationOptions);
  }

  @action
  async sendInitialVerificationEmail() {
    this.sendVerificationEmail('send_initial_verification_email');
  }

  @action
  async resendVerificationEmail() {
    this.sendVerificationEmail('resend_verification_email');
  }

  @action
  async sendVerificationEmail(object: string) {
    this.emailVerificationSent = true;
    await this.senderEmailVerificationService.resendVerificationEmail({
      senderEmailAddressSettingsId: this.selectedEmailIdToBeVerified ?? '',
      appId: this.appService.app.id,
      adminId: this.appService.app.currentAdmin.id,
      emailSetup: this.place,
    });
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object,
      place: this.place,
      context: 'email-setup-sidebar',
    });
  }

  @action
  async onSelectEmailToBeVerified(selectedItem: string): Promise<void> {
    this.selectedEmailIdToBeVerified = selectedItem;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'selected',
      object: 'email_to_be_verified',
      place: this.place,
      context: 'email-setup-sidebar',
      selectedEmail: this.emailAddress?.email,
    });
  }

  @action
  async checkDomainAndLoadEntri(): Promise<void> {
    this.domainCanBeAutoConfigured = await taskFor(this.entriConnectService.checkDomain).perform(
      Number(this.dkimSettings?.id) ?? 0,
      Number(this.customBounceSettings?.id) ?? 0,
    );
    if (this.domainCanBeAutoConfigured) {
      taskFor(this.entriConnectService.loadEntriLibrary).perform();
    }
  }

  @action
  async authenticatedDomain() {
    this.args.setPreviousStepForDomainSetup(this.place);
    await this.checkDomainAndLoadEntri();
    if (this.canUseAutomaticStep) {
      this.args.changeStep('setup-domain', this.place);
    } else {
      this.args.changeStep('setup-domain-manually', this.place);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::DomainsAndAddresses::AccessSetupDomain': typeof AccessSetupDomain;
    'new-settings/channels/email/domains-and-addresses/access-setup-domain': typeof AccessSetupDomain;
  }
}
