/* import __COLOCATED_TEMPLATE__ from './let-fin-introduce-itself.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import type { FinSetupBehaviorSaveParams } from 'embercom/lib/operator/fin/types';
import type Localization from 'embercom/models/operator/bot-intro/localization';
import type IntlService from 'embercom/services/intl';
import type Profile from 'embercom/models/ai-agent/profile';

interface Args {
  accordion: $TSFixMe;
  ruleset: $TSFixMe;
  previewConfiguration: $TSFixMe;
  partialSave: (params: FinSetupBehaviorSaveParams) => void;
}

export default class LetFinIntroduceItself extends Component<Args> {
  @service declare contentEditorService: $TSFixMe;
  @service declare appService: $TSFixMe;
  @service declare store: Store;
  @service declare notificationsService: $TSFixMe;
  @service declare intl: IntlService;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
  }

  get ruleset() {
    return this.args.ruleset;
  }

  get behavior(): Profile {
    return this.ruleset.rulesetLinks.firstObject.object;
  }

  get localizations() {
    return this.behavior.botIntroLocalizations;
  }

  get url() {
    return 'https://www.intercom.com/legal/terms-and-policies/additional-product-terms';
  }

  get shouldHideBanner() {
    return this.ruleset.createdAt >= new Date('2025-03-27');
  }

  get enabledBotIntros() {
    return this.localizations.reduce(
      (enabledLocales: string[], cur: { enabled: boolean; locale: string }) => {
        if (cur.enabled) {
          enabledLocales.push(cur.locale.toUpperCase());
        }
        return enabledLocales;
      },
      [],
    );
  }

  get summaryText() {
    let enabledBotIntros = this.enabledBotIntros;
    let summary = enabledBotIntros.length
      ? this.intl.t('operator.fin.setup.setup-and-go-live.fin-introduction.summary.enabled', {
          localeList: enabledBotIntros.join(', '),
        })
      : this.intl.t('operator.fin.setup.setup-and-go-live.fin-introduction.summary.disabled');
    return summary;
  }

  get invalidBotIntroLocales() {
    let enableBotIntros = this.localizations.filter((botIntro: $TSFixMe) => botIntro.enabled);
    if (!enableBotIntros.length) {
      return [];
    }

    let invalidLocales: string[] = [];

    enableBotIntros.forEach((botIntro: $TSFixMe) => {
      if (!botIntro.parts.length) {
        invalidLocales.push(botIntro.name);
        return;
      }

      let hasInvalidParts = botIntro.parts.any((part: $TSFixMe) => {
        return part.validations?.isInvalid;
      });

      if (hasInvalidParts) {
        invalidLocales.push(botIntro.name);
      }
    });

    return invalidLocales;
  }

  get hasInvalidBotIntro() {
    return this.invalidBotIntroLocales.length > 0;
  }

  get invalidBotIntroError() {
    return this.intl.t('operator.fin.setup.setup-and-go-live.empty-message-part-error', {
      languages: this.invalidBotIntroLocales.join(', '),
    });
  }

  @action
  async updateBotIntroLocalizations() {
    let bot_intro_localizations = this.localizations.serialize().map((l: Localization) => {
      return {
        locale: l.locale,
        enabled: l.enabled,
        parts: l.parts,
      };
    });

    this.args.partialSave({ bot_intro_localizations });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::Setup::Sections::LetFinIntroduceItself': typeof LetFinIntroduceItself;
  }
}
