/* import __COLOCATED_TEMPLATE__ from './start-from-scratch-selector.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import {
  objectTypes,
  objectIcons,
  humanReadableObjectNames,
  objectNames,
} from 'embercom/models/data/matching-system/matching-constants';
import {
  outboundSupportedTemplateObjectTypes,
  operatorSupportedTemplateObjectTypes,
  paywallFeatures,
  contentObjectFeatures,
  contentObjectsInBeta,
  contentObjectsWithNewTag,
} from 'embercom/models/data/outbound/template-constants';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { OUTBOUND_INDEX_ROUTES } from 'embercom/models/outbound/content-wrapper';

export default class StartFromScratchSelector extends Component {
  @service router;
  @service appService;
  @service intl;

  contentObjectsInBeta = contentObjectsInBeta;
  contentObjectsWithNewTag = contentObjectsWithNewTag;

  get objectTypes() {
    return objectTypes;
  }

  get objectIcons() {
    return objectIcons;
  }

  get objectPaywallFeatures() {
    return paywallFeatures;
  }

  get objectNames() {
    return objectNames;
  }

  get humanReadableObjectNames() {
    return {
      ...humanReadableObjectNames,
      [objectTypes.customBot]: this.intl.t(
        'outbound.new-content-modal.start-from-scratch.custom-bot',
      ),
      [objectTypes.tour]: this.intl.t('outbound.new-content-modal.start-from-scratch.product-tour'),
      [objectTypes.sms]: this.intl.t('outbound.new-content-modal.start-from-scratch.sms'),
    };
  }

  get filteredOutboundSupportedTemplateObjectTypes() {
    let supportedTemplateObjects = outboundSupportedTemplateObjectTypes;
    if (this.args.popularIsSelected) {
      let popularTypes = [
        objectTypes.chat,
        objectTypes.banner,
        objectTypes.tooltipGroup,
        objectTypes.email,
        objectTypes.tour,
        objectTypes.post,
      ];
      supportedTemplateObjects = supportedTemplateObjects.filter((objectType) => {
        return (
          objectType !== objectTypes.legacyMessageEmail &&
          this._canUseObjectType(objectType) &&
          popularTypes.includes(objectType)
        );
      });
    } else {
      supportedTemplateObjects = supportedTemplateObjects.filter(
        (objectType) =>
          objectType !== objectTypes.legacyMessageEmail && this._canUseObjectType(objectType),
      );
    }

    return supportedTemplateObjects;
  }

  get supportedTemplateObjects() {
    return operatorSupportedTemplateObjectTypes
      .concat(outboundSupportedTemplateObjectTypes)
      .concat([objectTypes.series]);
  }

  get templateObjectTypes() {
    if (!this.supportedTemplateObjects.includes(this.args.selectedContentType)) {
      return this.filteredOutboundSupportedTemplateObjectTypes;
    }

    return [this.args.selectedContentType];
  }

  get paywallOverrides() {
    return {
      [this.objectTypes.sms]:
        !this.appService.app.canUseSmsBeta && !this.appService.app?.canUseFeature('sms'),
    };
  }

  @action
  createContent(contentType) {
    this.args.onCreateContent(parseInt(contentType, 10));
  }

  @action
  trialConfirmationAction(objectType) {
    switch (objectNames[objectType]) {
      case 'tour':
        return this.router.transitionTo('apps.app.outbound.tour', {
          queryParams: { startTour: true },
        });
      case 'survey':
        return this.router.transitionTo('apps.app.outbound.survey', {
          queryParams: { onboardingVideoDisplayed: true },
        });
      default: {
        let route = OUTBOUND_INDEX_ROUTES[objectNames[objectType]] || 'apps.app.outbound';
        return this.router.transitionTo(route);
      }
    }
  }

  _canUseObjectType(objectType) {
    if (!contentObjectFeatures.hasOwnProperty(objectType)) {
      return true;
    }

    return contentObjectFeatures[objectType].any((feature) =>
      this.appService.app.canUseFeature(feature),
    );
  }
}
