/* import __COLOCATED_TEMPLATE__ from './wrapper.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
import type IntlService from 'ember-intl/services/intl';
import { type TabItem } from 'embercom/components/new-settings/common/standard-base';
import type HelpCenterSite from 'embercom/models/help-center-site';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import { type TicketSystemState } from 'embercom/objects/inbox/conversation';

interface Args {
  selectedTab: string;
  selectedHelpCenter: HelpCenterSite | null;
  changeTab: (tab: string) => void;
  saveHelpCenterSettings: () => void;
  canAccessTicketsPortal: boolean;
  createNewTicketType: () => void;
  onTicketTypeSideDrawerClose: () => void;
  showTicketTypeSideDrawer: boolean;
  systemStatesOptions: any;
  createStateEditorOpen: boolean;
  editorStateType: TicketSystemState;
  onEditorClose: () => void;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
  Blocks: {
    default: [];
  };
}

export default class Header extends Component<Signature> {
  @service declare appService: any;
  @service declare router: RouterService;
  @service declare intl: IntlService;
  @service declare intercomEventService: any;

  get tabs(): Array<TabItem & { canAccess: boolean }> {
    return [
      {
        label: 'new-settings.helpdesk.tickets.tabs.ticket-types',
        value: 'ticket-types',
        canAccess: true,
      },
      {
        label: 'new-settings.helpdesk.tickets.tabs.ticket-states',
        value: 'ticket-states',
        canAccess: true,
      },
      {
        label: 'new-settings.helpdesk.tickets.tabs.tickets-portal',
        value: 'tickets-portal',
        canAccess: this.appService.app.canSeeIASettingsGA && this.args.canAccessTicketsPortal,
      },
    ];
  }

  get controlTabs() {
    return this.tabs.filter((tab) => tab.canAccess);
  }

  get titleToken() {
    return this.intl.t('new-settings.helpdesk.tickets.title');
  }

  get saveHelpCenterButtonDisabled() {
    return (
      !(
        !!this.args.selectedHelpCenter?.hasDirtyAttributes ||
        !!this.args.selectedHelpCenter?.ticketsPortalRuleset?.hasDirtyAttributes
      ) || !!this.args.selectedHelpCenter?.isSaving
    );
  }

  @action
  showLearnTicketTypesArticle() {
    safeWindowOpen('https://www.intercom.com/help/en/collections/6662402-getting-started');
  }

  @action
  showLearnTicketStatesArticle() {
    window.Intercom('showArticle', 8687982);
  }

  @action
  setSelectedTab(value: string) {
    this.args.changeTab(value);
  }

  @action showCategoryIntroduction() {
    this.router.transitionTo('apps.app.settings.tickets-onboarding', this.appService.app.id);
  }

  @action startSurvey() {
    let ticketsV3SurveyId = 34262514;
    window.Intercom('startSurvey', ticketsV3SurveyId);
  }

  @action startTicketStatesSurvey() {
    let surveyId = 40938061;
    window.Intercom('startSurvey', surveyId);
  }

  @action
  async saveHelpCenterSettings() {
    this.args.saveHelpCenterSettings();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Helpdesk::Tickets::Wrapper': typeof Header;
  }
}
