/* import __COLOCATED_TEMPLATE__ from './failed.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {
    notification: {
      data: {
        externalContentUrl: string;
        sourceUrl: string;
      };
    };
  };
}

const Failed = templateOnlyComponent<Signature>();
export default Failed;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Notifications::ContentImport::Failed': typeof Failed;
  }
}
