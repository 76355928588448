/* import __COLOCATED_TEMPLATE__ from './go-to-help-center.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import Component from '@glimmer/component';
import type HelpCenterSite from 'embercom/models/help-center-site';

interface Args {
  site: HelpCenterSite;
  selectedLocale?: string;
}

interface Signature {
  Args: Args;
  Element: Element;
}

export default class GoToHelpCenter extends Component<Signature> {
  get url() {
    let currentSelectedLocale = this.args.selectedLocale || this.args.site.locale;
    return `${this.args.site.url}/${currentSelectedLocale}`;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Helpcenter::GoToHelpCenter': typeof GoToHelpCenter;
    'new-settings/helpcenter/go-to-help-center': typeof GoToHelpCenter;
  }
}
