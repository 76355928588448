/* import __COLOCATED_TEMPLATE__ from './personality.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import type Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import { taskFor } from 'ember-concurrency-ts';
import { task } from 'ember-concurrency-decorators';
import type { TaskGenerator } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import type Settings from 'embercom/models/ai-agent/tone-of-voice-settings';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import type IntlService from 'ember-intl/services/intl';
import { action } from '@ember/object';
import { defer } from 'rsvp';
import { startSurvey } from 'embercom/lib/intercom-widget-helper';
import type Router from '@ember/routing/router-service';
import type IntercomConfirmService from 'embercom/services/intercom-confirm-service';
import type Guideline from 'embercom/models/ai-agent/guidelines';

const SURVEY_ID = 42775008;
const SETTINGS_CHANGED_MESSAGE = 'preview-settings-updated';

export type Tone = 'neutral' | 'plainspoken' | 'professional' | 'friendly' | 'playful';
export type AnswerLength = 'succinct' | 'standard' | 'comprehensive';

export const TONE_OF_VOICE_SECTION_TITLES: Record<Tone, string> = {
  neutral: 'ai-agent.settings.tone-of-voice.tones.neutral.section-title',
  plainspoken: 'ai-agent.settings.tone-of-voice.tones.plainspoken.section-title',
  professional: 'ai-agent.settings.tone-of-voice.tones.professional.section-title',
  friendly: 'ai-agent.settings.tone-of-voice.tones.friendly.section-title',
  playful: 'ai-agent.settings.tone-of-voice.tones.playful.section-title',
} as const;

export const ANSWER_LENGTH_SECTION_TITLES: Record<AnswerLength, string> = {
  succinct: 'ai-agent.settings.answer-length.values.succinct.section-title',
  standard: 'ai-agent.settings.answer-length.values.standard.section-title',
  comprehensive: 'ai-agent.settings.answer-length.values.comprehensive.section-title',
} as const;

type ToneOption = {
  value: Tone;
  icon: InterfaceIconName;
  label: string;
  description: string;
};
type AnswerLengthOption = {
  value: AnswerLength;
  icon: InterfaceIconName;
  label: string;
  description: string | null;
  tooltipDisabled: boolean;
};

export interface Arguments {
  personalityCustomizationSettings: Settings;
  reloadSavedCustomizationSettings?: () => void;
  isStandalone?: boolean;
  closeAccordion?: () => void;
  personalityOnly?: boolean;
  guidelines?: Guideline[];
  isInSettings?: boolean;
}
export default class Personality extends Component<Arguments> {
  @service declare intl: IntlService & { languageNameFromCode: (code: string) => string };
  @service declare store: Store;
  @service declare appService: $TSFixMe;
  @service intercomEventService: $TSFixMe;
  @service notificationsService: $TSFixMe;
  @service permissionsService: $TSFixMe;
  @service declare router: Router;
  @service declare intercomConfirmService: IntercomConfirmService;

  @tracked operatorIdentity: $TSFixMe = null; // app/models/operator-identity
  @tracked identityPreviewImgSrc = '';
  @tracked personalityCustomizationSettings?: Settings;
  savedPersonalityCustomizationSettings?: {
    aiToneOfVoice: string;
    aiAnswerLength: string;
    aiLanguageStyle: string;
  };
  @tracked rerenderIdentityEditor = false;
  @tracked openSectionId = '';

  defaultAnalyticsMetadata = { place: 'overview_personality', section: 'ai_agent' };

  constructor(owner: unknown, args: Arguments) {
    super(owner, args);
    this.personalityCustomizationSettings = this.args.personalityCustomizationSettings;
    this.copySavedSettings();
    this.router.on('routeWillChange', this.warnIfUnsavedChangesOnRouteTransition);
    taskFor(this.loadOperatorIdentity).perform();
  }

  willDestroy() {
    super.willDestroy();
    this._rollBackAllPersonalitySettings();
    try {
      this.router.off('routeWillChange', this.warnIfUnsavedChangesOnRouteTransition);
    } catch (e) {
      // ignore if this has already been removed to hide the console error
    }
  }

  get personalityTabHasUnsavedChanges() {
    return (
      this.personalityCustomizationSettings?.hasDirtyAttributes ||
      this.operatorIdentity?.hasDirtyAttributes
    );
  }

  @action
  async warnIfUnsavedChangesOnRouteTransition(transition: any) {
    if (
      this.personalityTabHasUnsavedChanges &&
      !transition.isAborted &&
      transition.to?.name !== transition.from?.name
    ) {
      transition.abort();

      let confirmNotSaved = {
        title: this.intl.t('operator.fin.setup.personality.unsaved-changes-modal.title'),
        confirmButtonText: this.intl.t(
          'operator.fin.setup.personality.unsaved-changes-modal.confirm',
        ),
        cancelText: this.intl.t('operator.fin.setup.personality.unsaved-changes-modal.cancel'),
        body: this.intl.t('operator.fin.setup.personality.unsaved-changes-modal.body'),
      };
      let confirmed = await this.intercomConfirmService.confirm(confirmNotSaved);
      if (!confirmed) {
        // do not remove - we have to do this to prevent the route from changing
        this.router.off('routeWillChange', this.warnIfUnsavedChangesOnRouteTransition);
        transition.abort();
        this.router.on('routeWillChange', this.warnIfUnsavedChangesOnRouteTransition);
      } else {
        this.router.off('routeWillChange', this.warnIfUnsavedChangesOnRouteTransition);
        transition.retry();
      }
    }
  }

  get app() {
    return this.appService.app;
  }

  get tones(): ToneOption[] {
    return [
      {
        value: 'friendly',
        icon: 'wave',
        label: this.intl.t('ai-agent.settings.tone-of-voice.tones.friendly.name'),
        description: this.intl.t('ai-agent.settings.tone-of-voice.tones.friendly.description'),
      },
      {
        value: 'neutral',
        icon: 'fin',
        label: this.intl.t('ai-agent.settings.tone-of-voice.tones.neutral.name'),
        description: this.intl.t('ai-agent.settings.tone-of-voice.tones.neutral.description'),
      },
      {
        value: 'plainspoken',
        icon: 'book',
        label: this.intl.t('ai-agent.settings.tone-of-voice.tones.plainspoken.name'),
        description: this.intl.t('ai-agent.settings.tone-of-voice.tones.plainspoken.description'),
      },
      {
        value: 'professional',
        icon: 'newspaper',
        label: this.intl.t('ai-agent.settings.tone-of-voice.tones.professional.name'),
        description: this.intl.t('ai-agent.settings.tone-of-voice.tones.professional.description'),
      },
      {
        value: 'playful',
        icon: 'lwr-happy',
        label: this.intl.t('ai-agent.settings.tone-of-voice.tones.playful.name'),
        description: this.intl.t('ai-agent.settings.tone-of-voice.tones.playful.description'),
      },
    ];
  }

  get answerLengths(): AnswerLengthOption[] {
    return [
      {
        value: 'succinct',
        icon: 'short-text',
        label: this.intl.t('ai-agent.settings.answer-length.values.succinct.name'),
        description: this.intl.t('ai-agent.settings.answer-length.values.succinct.description'),
        tooltipDisabled: false,
      },
      {
        value: 'standard',
        icon: 'long-text',
        label: this.intl.t('ai-agent.settings.answer-length.values.standard.name'),
        description: null,
        tooltipDisabled: true,
      },
      {
        value: 'comprehensive',
        icon: 'extra-long-text',
        label: this.intl.t('ai-agent.settings.answer-length.values.comprehensive.name'),
        description: this.intl.t(
          'ai-agent.settings.answer-length.values.comprehensive.description',
        ),
        tooltipDisabled: false,
      },
    ];
  }

  @action
  startSurvey() {
    startSurvey(SURVEY_ID);
  }

  @action
  setIdentityPreviewImgSrc(url: string) {
    this.identityPreviewImgSrc = url;
  }

  @action
  setTone(tone: string) {
    if (this.personalityCustomizationSettings) {
      this.personalityCustomizationSettings.aiToneOfVoice = tone;
      this.updatePreviewPersonalitySettings();
    }
  }

  @action
  onToneHovered(tone: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'hovered',
      object: 'tone_of_voice_option',
      value: tone,
      ...this.defaultAnalyticsMetadata,
    });
  }

  @action
  onAnswerLengthHovered(answerLength: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'hovered',
      object: 'answer_length_option',
      value: answerLength,
      ...this.defaultAnalyticsMetadata,
    });
  }

  @action
  setAnswerLength(length: string) {
    if (this.personalityCustomizationSettings) {
      this.personalityCustomizationSettings.aiAnswerLength = length;
      this.updatePreviewPersonalitySettings();
    }
  }

  updatePreviewPersonalitySettings() {
    this.sendMessageToMessengerIFrame(SETTINGS_CHANGED_MESSAGE);
  }

  reloadPreview() {
    this.sendMessageToMessengerIFrame(SETTINGS_CHANGED_MESSAGE);
  }

  @task({ restartable: true })
  *loadOperatorIdentity(): TaskGenerator<void> {
    try {
      this.operatorIdentity = yield this.store.findRecord('operator-identity', '1');
      // operator identity can be modified, but not saved, in settings
      // and it looks strange to have a partially edited record when we visit this page
      this.operatorIdentity.rollbackAttributes();
      this.identityPreviewImgSrc = this.operatorIdentity.avatar.image_urls.square_128;
    } catch (e) {
      this.notificationsService.notifyError(this.intl.t('operator.settings.error'));
    }
  }

  @action
  async cancel() {
    this._rollBackAllPersonalitySettings();
  }

  @task({ restartable: true })
  *save() {
    try {
      yield this.permissionsService.checkPermission('can_create_and_edit_bots');
    } catch (e) {
      return;
    }
    try {
      if (this.appService.app.canUseFeature('bot_identity')) {
        yield this.operatorIdentity.save();
      }

      if (this.personalityCustomizationSettings) {
        this.trackAnalyticsEvent('saved', 'main_personality_settings');
        yield this.personalityCustomizationSettings.save();
        if (this.args.reloadSavedCustomizationSettings) {
          this.args.reloadSavedCustomizationSettings();
        }
        this.copySavedSettings();
      }

      this.reloadPreview();

      this.notificationsService.notifyConfirmation(
        this.intl.t('operator.settings.saved-successfully'),
      );
      this.args.closeAccordion?.();
    } catch (error) {
      this.notificationsService.notifyError(this.intl.t('operator.settings.error'));
    }
  }

  _rollBackAllPersonalitySettings() {
    if (this.operatorIdentity) {
      this.operatorIdentity.rollbackAttributes();
      if (!this.args.personalityOnly) {
        // when we only have the personality, we don't touch the avatar
        this.identityPreviewImgSrc = this.operatorIdentity.avatar.image_urls.square_128;
      }
    }

    if (this.personalityCustomizationSettings) {
      this.personalityCustomizationSettings.rollbackAttributes();
    }
  }

  _readFileAsDataURL(file: File): Promise<unknown> {
    let deferred = defer();
    let reader = new FileReader();
    reader.onload = (e) => {
      if (e.target && typeof e.target.result === 'string') {
        deferred.resolve(e.target.result);
      }
    };
    reader.readAsDataURL(file);
    return deferred.promise;
  }

  private trackAnalyticsEvent(action: string, object: string, metaData: object = {}): void {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object,
      ...this.defaultAnalyticsMetadata,
      ...(this.personalityCustomizationSettings?.aiToneOfVoice !==
        this.savedPersonalityCustomizationSettings?.aiToneOfVoice && {
        ai_tone_of_voice: this.personalityCustomizationSettings?.aiToneOfVoice,
      }),
      ...(this.personalityCustomizationSettings?.aiAnswerLength !==
        this.savedPersonalityCustomizationSettings?.aiAnswerLength && {
        ai_answer_length: this.personalityCustomizationSettings?.aiAnswerLength,
      }),
      ...(this.personalityCustomizationSettings?.aiLanguageStyle !==
        this.savedPersonalityCustomizationSettings?.aiLanguageStyle && {
        ai_language_style: this.personalityCustomizationSettings?.aiLanguageStyle,
      }),
      ...metaData,
    });
  }

  private sendMessageToMessengerIFrame(messageType: string) {
    let message: { [key: string]: any } = {};

    message.type = messageType;

    if (messageType === SETTINGS_CHANGED_MESSAGE) {
      if (this.personalityCustomizationSettings) {
        message.previewPersonalitySettings = {
          ai_tone_of_voice: this.personalityCustomizationSettings.aiToneOfVoice,
          ai_answer_length: this.personalityCustomizationSettings.aiAnswerLength,
          ai_language_style: this.personalityCustomizationSettings.aiLanguageStyle,
        };
      }
    }
    if (this.args.guidelines) {
      message.previewGuidelines = this.args.guidelines
        .filter((guideline) => !guideline.isDeleted)
        .map((guideline) => {
          return {
            text: guideline.prompt,
            category: guideline.category,
          };
        });
    }

    let iframeId = '#hosted-messenger-fin-demo-preview';

    if (this.appService.app.canUseFinGuidanceReusablePreview) {
      iframeId = '#hosted-messenger-unified-preview';
    }

    let iframe = window.document.querySelector(iframeId) as HTMLIFrameElement;

    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(JSON.stringify(message));
    }
  }

  private copySavedSettings() {
    let { aiToneOfVoice, aiAnswerLength, aiLanguageStyle } =
      this.args.personalityCustomizationSettings ?? {};
    this.savedPersonalityCustomizationSettings = {
      aiToneOfVoice,
      aiAnswerLength,
      aiLanguageStyle,
    };
  }

  get settingsUrl() {
    return this.router.urlFor('apps.app.settings.ai-automation.fin-ai-agent');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::Setup::Personality': typeof Personality;
  }
}
