/* import __COLOCATED_TEMPLATE__ from './sms.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { responseProcessingBehaviours } from 'embercom/models/data/sms/constants';
import type IntlService from 'ember-intl/services/intl';

interface Signature {
  Args: {
    smsOutboundSettings: any;
    smsKeywordResponses: any;
    smsPhoneNumbers: any;
    customerTwilioCredentials: any;
  };
}

export default class SMS extends Component<Signature> {
  @service declare appService: $TSFixMe;
  @service declare intl: IntlService;

  @tracked openSectionId = 'two-way-sms';

  get isPaywallActive() {
    return !this.appService.app.canUseSmsBeta && !this.appService.app?.canUseFeature('sms');
  }

  get twoWaySmsEnabled() {
    return (
      this.args.smsOutboundSettings &&
      this.args.smsOutboundSettings.responseProcessingBehaviour ===
        responseProcessingBehaviours.deflectToInbox
    );
  }

  get twoWaySmsState() {
    return this.args.smsOutboundSettings &&
      this.args.smsOutboundSettings.responseProcessingBehaviour ===
        responseProcessingBehaviours.deflectToInbox
      ? this.intl.t('new-settings.channels.sms.state.on')
      : this.intl.t('new-settings.channels.sms.state.off');
  }

  get isAlphanumericSenderInfoDisabled() {
    // See: https://github.com/intercom/intercom/issues/293852
    return this.args.smsPhoneNumbers.some((number: any) => number.countryCode === 'AU');
  }

  @action
  onOpenSectionChange(sectionId: any) {
    this.openSectionId = sectionId;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Sms': typeof SMS;
  }
}
