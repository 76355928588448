/* import __COLOCATED_TEMPLATE__ from './tickets.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type HelpCenterSite from 'embercom/models/help-center-site';
import { tracked } from '@glimmer/tracking';
import type IntlService from 'ember-intl/services/intl';
import { TicketSystemState } from 'embercom/objects/inbox/conversation';

interface Args {
  model: any;
  tab: string;
  changeTab: (tab: string) => void;
}

export default class Tickets extends Component<Args> {
  @service declare appService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare intl: IntlService;

  @tracked selectedHelpCenter: HelpCenterSite | null = null;
  @tracked showTicketTypeSideDrawer = false;
  @tracked createStateEditorOpen = false;
  @tracked editorStateType = TicketSystemState.Submitted;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    if (!this.canSeeIASettingsGA && this.selectedTab === 'tickets-portal') {
      args.changeTab('ticket-types');
    }
  }

  @action
  changeTab(newTab: string) {
    if (newTab === 'ticket-states') {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'viewed',
        object: 'ticket_states',
        place: 'ticket_settings_page',
      });
    }
    this.args.changeTab(newTab);
  }

  get selectedTab() {
    return this.args.tab;
  }

  get canSeeIASettingsGA() {
    return this.appService.app.canSeeIASettingsGA;
  }

  get systemStatesOptions() {
    return [
      {
        items: [
          {
            component: 'settings/ticket-states/custom-state-dropdown-option',
            systemState: TicketSystemState.Submitted,
            onSelectItem: () => this.openCreateStateDropdown(TicketSystemState.Submitted),
          },
          {
            component: 'settings/ticket-states/custom-state-dropdown-option',
            systemState: TicketSystemState.InProgress,
            onSelectItem: () => this.openCreateStateDropdown(TicketSystemState.InProgress),
          },
          {
            component: 'settings/ticket-states/custom-state-dropdown-option',
            systemState: TicketSystemState.WaitingOnCustomer,
            onSelectItem: () => this.openCreateStateDropdown(TicketSystemState.WaitingOnCustomer),
          },
          {
            component: 'settings/ticket-states/custom-state-dropdown-option',
            systemState: TicketSystemState.Resolved,
            onSelectItem: () => this.openCreateStateDropdown(TicketSystemState.Resolved),
          },
        ],
      },
    ];
  }

  @action
  openCreateStateDropdown(systemState: TicketSystemState) {
    this.editorStateType = systemState;
    this.createStateEditorOpen = true;
  }

  @action
  onSwitchHelpCenter(helpCenter: HelpCenterSite) {
    this.selectedHelpCenter = helpCenter;
  }

  @action
  onEditorClose() {
    this.createStateEditorOpen = false;
  }

  @action
  async saveHelpCenterSettings() {
    try {
      await this.selectedHelpCenter?.save();
      this.notificationsService.notifyConfirmation(
        this.intl.t('new-settings.helpdesk.tickets.tickets-portal.settings.portal-update-success'),
      );
    } catch (error) {
      this.notificationsService.notifyError(
        this.intl.t('new-settings.helpdesk.tickets.tickets-portal.settings.portal-update-error'),
      );
    }
  }

  @action
  createNewTicketType(fromBanner = false) {
    this.showTicketTypeSideDrawer = true;
    if (fromBanner === true) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: `create_ticket_type_cta`,
        section: 'tickets_discovery_banner',
        place: 'settings.tickets',
      });
    } else {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        context: 'create-ticket-type',
        object: `create-ticket-type-cta`,
        section: 'settings.helpdesk.tickets.wrapper',
        place: 'settings.helpdesk.tickets',
      });
    }
  }

  @action onTicketTypeSideDrawerClose() {
    this.showTicketTypeSideDrawer = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Helpdesk::Tickets': typeof Tickets;
  }
}
