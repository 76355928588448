/* import __COLOCATED_TEMPLATE__ from './last-updated-by-cell.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import templateOnlyComponent from '@ember/component/template-only';
import type Admin from 'embercom/models/admin';

export interface Args {
  content: {
    lastUpdatedBy: Admin;
    lastUpdatedById: number;
  };
}

const LastUpdatedByCell = templateOnlyComponent<Args>();
export default LastUpdatedByCell;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Outbound::List::LastUpdatedByCell': typeof LastUpdatedByCell;
    'outbound/list/last-updated-by-cell': typeof LastUpdatedByCell;
  }
}
