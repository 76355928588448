/* import __COLOCATED_TEMPLATE__ from './brand-email-dropdown-empty-list.hbs'; */
/* RESPONSIBLE TEAM: team-purchase */
import templateOnlyComponent from '@ember/component/template-only';

export interface Args {}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

const BrandEmailDropdownEmptyList = templateOnlyComponent<Signature>();
export default BrandEmailDropdownEmptyList;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    BrandEmailDropdownEmptyList: typeof BrandEmailDropdownEmptyList;
    'brand-email-dropdown-empty-list': typeof BrandEmailDropdownEmptyList;
  }
}
