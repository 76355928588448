/* import __COLOCATED_TEMPLATE__ from './conversation-tickets.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import type IntlService from 'ember-intl/services/intl';

interface Args {
  model: any;
}

interface Signature {
  Args: Args;
  Element: never;
}

export default class ConversationTickets extends Component<Signature> {
  @service session!: Session;
  @service intl!: IntlService;

  @tracked showDescriptorDetailsModal = false;

  get canEditConditions() {
    return !this.session.workspace.isStandaloneApp;
  }

  get conversationAttributeDescriptors() {
    return this.args.model.descriptors.filter(
      (descriptor: any) => descriptor.isTicketDescriptor === false,
    );
  }

  get learnButtonUrls() {
    let result = [
      {
        text: this.intl.t(
          'new-settings.data.conversation-tickets.learn-menu-items.organise-conversations',
        ),
        onSelectItem: this.showOrganizeConversationsArticle,
        icon: 'article',
      },
      {
        text: this.intl.t(
          'new-settings.data.conversation-tickets.learn-menu-items.using-conversation-data',
        ),
        onSelectItem: this.showUsingConversationDataArticle,
        icon: 'article',
      },
    ];
    if (this.canEditConditions) {
      result.push({
        text: this.intl.t(
          'new-settings.data.conversation-tickets.learn-menu-items.using-conditional-attributes',
        ),
        onSelectItem: this.showConditionalAttributesArticle,
        icon: 'article',
      });
    }

    return result;
  }

  showOrganizeConversationsArticle() {
    window.Intercom('showArticle', 7126365); // https://www.intercom.com/help/en/articles/7126365-when-should-i-use-conversation-topics-attributes-and-tags?location=conversation
  }

  showUsingConversationDataArticle() {
    window.Intercom('showArticle', 6546210); // https://www.intercom.com/help/en/articles/6546210-using-conversation-data-for-inbox-workflows?location=conversation
  }

  showConditionalAttributesArticle() {
    window.Intercom('showArticle', 6546210); // https://www.intercom.com/help/en/articles/9971165-conditional-attributes?location=conversation
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Data::ConversationTickets': typeof ConversationTickets;
  }
}
