/* import __COLOCATED_TEMPLATE__ from './warning-stamp.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type BannerDismissalService from 'embercom/services/banner-dismissal-service';
import { task } from 'ember-concurrency-decorators';
import { modifier } from 'ember-modifier';
import { perform } from 'ember-concurrency-ts';

interface Args {
  text: string;
  bannerId: string;
}

export default class WarningStamp extends Component<Args> {
  @service declare bannerDismissalService: BannerDismissalService;

  @tracked isBannerDismissed = true;

  onLoad = modifier(
    () => {
      perform(this.checkBannerDismissal);
    },
    { eager: false },
  );

  get bannerId() {
    return this.args.bannerId;
  }

  @task({ drop: true })
  *checkBannerDismissal() {
    let hasDismissed: boolean = yield this.bannerDismissalService.hasDismissed(this.bannerId);
    this.isBannerDismissed = hasDismissed;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::Common::WarningStamp': typeof WarningStamp;
  }
}
