/* import __COLOCATED_TEMPLATE__ from './header-with-status-stamp.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import Component from '@glimmer/component';
import type HelpCenterSite from 'embercom/models/help-center-site';
import { inject as service } from '@ember/service';

interface Args {
  site: HelpCenterSite;
  isOnConfigurePage: boolean;
  title: string;
}

interface Signature {
  Args: Args;
  Element: Element;
}

export default class HeaderWithStatusStamp extends Component<Signature> {
  @service declare appService: any;

  get app() {
    return this.appService.app;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Helpcenter::HeaderWithStatusStamp': typeof HeaderWithStatusStamp;
    'new-settings/helpcenter/header-with-status-stamp': typeof HeaderWithStatusStamp;
  }
}
