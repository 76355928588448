/* import __COLOCATED_TEMPLATE__ from './via-email-channel.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import type { FinSetupBehaviorSaveParams } from 'embercom/lib/operator/fin/types';
import type IntlService from 'embercom/services/intl';
import type { ChannelType } from 'embercom/lib/workflows/fin-workflow-preview';
import type DomainService from 'embercom/services/domain-service';
import type { InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import type PALETTE from '@intercom/pulse/lib/palette';

interface Args {
  accordion: $TSFixMe;
  ruleset: $TSFixMe;
  partialSave: (params: FinSetupBehaviorSaveParams) => void;
  channelType: ChannelType;
}

export default class ViaEmailChannel extends Component<Args> {
  @service declare appService: $TSFixMe;
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare domainService: DomainService;

  get subheader() {
    let allConnected =
      this.isForwardingEnabled && this.isAuthenticationEnabled && this.isReplyAddressInboundEnabled;
    return allConnected
      ? this.intl.t('operator.fin.setup.setup-and-go-live.via-email-channel.subheader-connected')
      : '';
  }

  get defaultEmailAddress() {
    return this.domainService.domains
      .flatMap((domain) => domain.addresses)
      .find((address) => address.isDefaultBrandNotificationEmail);
  }

  get domainFromDefaultEmailAddress() {
    return this.domainService.domains.find((domain) =>
      domain.addresses.any((address) => address.isDefaultBrandNotificationEmail),
    );
  }

  get isForwardingEnabled() {
    return this.defaultEmailAddress?.forwardingEnabled ?? false;
  }

  get isAuthenticationEnabled() {
    return this.domainFromDefaultEmailAddress?.isAuthenticated ?? false;
  }

  get isReplyAddressInboundEnabled(): boolean {
    return !(
      !this.appService.app.admin_reply_default_address_id &&
      this.domainService.verifiedAndAuthenticatedCustomEmailAddresses.length === 0
    );
  }

  get forwardingState() {
    if (this.isForwardingEnabled) {
      return 'done';
    }

    return this.defaultEmailAddress?.hasForwardingVerificationAttemp ? 'warning' : 'not-done';
  }

  get authenticationState() {
    if (this.isAuthenticationEnabled) {
      return 'done';
    }

    return this.domainFromDefaultEmailAddress?.hasAuthenticationAttempt ? 'warning' : 'not-done';
  }

  get replyAddressInboundState() {
    return this.isReplyAddressInboundEnabled ? 'done' : 'not-done';
  }

  get showWarning() {
    return this.forwardingState === 'warning' || this.authenticationState === 'warning';
  }

  iconPerState: Record<string, InterfaceIconName> = {
    done: 'rounded-check',
    warning: 'alert-circle',
    'not-done': 'rounded-check',
  };

  iconColorPerState: Record<string, keyof typeof PALETTE> = {
    done: 'success-fill',
    warning: 'notice-container',
    'not-done': 'neutral-border-emphasis',
  };

  get steps(): {
    titleKey: string;
    linkKey: string;
    link: string;
    icon: InterfaceIconName;
    iconColor: keyof typeof PALETTE;
    state: string;
  }[] {
    return [
      {
        titleKey: 'operator.fin.setup.setup-and-go-live.via-email-channel.step-1',
        linkKey: 'operator.fin.setup.setup-and-go-live.via-email-channel.step-1-link',
        link: 'https://www.intercom.com/help/en/articles/6522819-automatically-forward-emails-to-the-inbox',
        icon: this.iconPerState[this.forwardingState],
        iconColor: this.iconColorPerState[this.forwardingState],
        state: this.forwardingState,
      },
      {
        titleKey: 'operator.fin.setup.setup-and-go-live.via-email-channel.step-2',
        linkKey: 'operator.fin.setup.setup-and-go-live.via-email-channel.step-2-link',
        link: 'https://www.intercom.com/help/en/articles/9744849-connect-your-email-support-channel#h_b761c867b8',
        state: this.authenticationState,
        icon: this.iconPerState[this.authenticationState],
        iconColor: this.iconColorPerState[this.authenticationState],
      },
      {
        titleKey: 'operator.fin.setup.setup-and-go-live.via-email-channel.step-3',
        linkKey: 'operator.fin.setup.setup-and-go-live.via-email-channel.step-3-link',
        link: 'https://www.intercom.com/help/en/articles/6288581-send-replies-from-the-address-inbound-emails-are-sent-to',
        state: this.replyAddressInboundState,
        icon: this.iconPerState[this.replyAddressInboundState],
        iconColor: this.iconColorPerState[this.replyAddressInboundState],
      },
    ];
  }
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::Setup::Sections::ViaEmailChannel': typeof ViaEmailChannel;
  }
}
