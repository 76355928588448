/* import __COLOCATED_TEMPLATE__ from './ai-disclosure-banner.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type BannerDismissalService from 'embercom/services/banner-dismissal-service';
import { task } from 'ember-concurrency-decorators';
import { modifier } from 'ember-modifier';
import { perform } from 'ember-concurrency-ts';
import type Profile from 'embercom/models/ai-agent/profile';

interface Args {
  bannerId: string;
}

export default class AiDisclosureBanner extends Component<Args> {
  @service declare bannerDismissalService: BannerDismissalService;
  @service declare intercomEventService: $TSFixMe;
  @service declare contentEditorService: $TSFixMe;

  @tracked isBannerDismissed = true;

  onLoad = modifier(
    () => {
      perform(this.checkBannerDismissal);
    },
    { eager: false },
  );

  get behavior(): Profile | undefined {
    return this.contentEditorService.activeObject;
  }

  get bannerId() {
    return this.args.bannerId;
  }

  get url() {
    return 'https://www.intercom.com/legal/terms-and-policies/additional-product-terms';
  }

  @task({ drop: true })
  *checkBannerDismissal() {
    let hasDismissed: boolean = yield this.bannerDismissalService.hasDismissed(this.bannerId);
    this.isBannerDismissed = hasDismissed;
  }

  @action
  dismissBanner() {
    this.isBannerDismissed = true;
    this.bannerDismissalService.dismiss(this.bannerId);
    this.intercomEventService.trackAnalyticsEvent({
      action: 'dismissed',
      object: 'got_it_button',
      section: 'operator',
      context: this.bannerId,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::BotIntro::AiDisclosureBanner': typeof AiDisclosureBanner;
  }
}
