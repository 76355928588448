/* import __COLOCATED_TEMPLATE__ from './sidebar.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type ApplicationInstance from '@ember/application/instance';
import { tracked } from '@glimmer/tracking';
import {
  deleteRequest,
  postRequest,
  putRequest,
  type ResponseError,
} from 'embercom/lib/inbox/requests';
import { get } from 'embercom/lib/ajax';
import type NotificationsService from 'embercom/services/notifications-service';
import type IntlService from 'embercom/services/intl';
import { action } from '@ember/object';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import { dropTask } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import type { Brand, BrandWireFormat } from './types/brand';
import type { HelpCenter } from './types/help-center';
import type { SenderEmailAddressSettings } from './types/sender-email-address-settings';
import { ref } from 'ember-ref-bucket';
import { getDomainName } from 'embercom/lib/email';
import { modifier } from 'ember-modifier';
import type ClipboardService from 'embercom/services/clipboard-service';

interface Args {
  type: 'new' | 'edit';
  isOpen: boolean;
  onClose: (brand: Brand | undefined) => void;
  helpCenters: HelpCenter[];
  onNewHelpCenter: (helpCenter: HelpCenter) => void;
  senderEmailAddressSettings?: SenderEmailAddressSettings[]; // only applicable for edit-brand sidebar
  onNewSenderEmailAddressSettings: (senderEmailAddressSettings: SenderEmailAddressSettings) => void;
  brand?: Brand; // only applicable for edit-brand sidebar
  domains: { name: string; isAuthenticated: boolean }[];
  onBrandDeletion?: () => void;
}

interface Signature {
  Args: Args;
  Element: never;
}

interface BrandRequestParams {
  app_id: string;
  filename?: string;
  file_data?: string;
  help_center_id?: number;
  sender_email_address_settings_id?: number;
  notification_email_address?: string;
  name?: string;
}

type DependentMessengerStyle = {
  id: string;
  name: string;
  platform: string;
};

type DependentEmailAddress = {
  id: string;
  email: string;
};

export type DependencyData = {
  brand_id: string;
  dependent_messenger_styles?: DependentMessengerStyle[];
  dependent_email_addresses?: DependentEmailAddress[];
};

export default class Sidebar extends Component<Signature> {
  @service declare notificationsService: InstanceType<typeof NotificationsService>;
  @service declare appService: any;
  @service declare intl: IntlService;
  @service declare intercomEventService: $TSFixMe;
  @service declare clipboardService: ClipboardService;

  @tracked name?: string;
  @tracked filename?: string;
  @tracked fileData?: string;
  @tracked helpCenterId?: number;
  @tracked senderEmailAddressSettingsId?: number;
  @tracked logoUrl?: string; // this is only for display purposes, it is not sent as part of any payload

  @tracked isNewAddressModalOpen = false;
  @tracked isNewHelpCenterModalOpen = false;

  @tracked showDependencyWarningModal = false;
  @tracked dependencyData: DependencyData;

  @ref('logoInput') declare logoInput: HTMLInputElement;

  clipboard?: any;

  copyBrandIdContainer = modifier(
    () => {
      this.onRender();
    },
    { eager: false },
  );

  constructor(owner: ApplicationInstance, args: Args) {
    super(owner, args);
    // The `&& this.args.brand` here is just to make typescript happy. If this
    // is an edit sidebar, then `this.args.brand` is always passed.
    if (this.isEditSidebar && this.args.brand) {
      this.name = this.args.brand.name;
      this.helpCenterId = this.args.brand.helpCenterId || undefined;
      this.senderEmailAddressSettingsId = this.args.brand.senderEmailAddressSettingsId || undefined;
      this.logoUrl = this.args.brand.logoUrl;
    }
    this.dependencyData = { brand_id: this.args.brand?.id?.toString() || '' };
  }

  get isNewSidebar() {
    return this.args.type === 'new';
  }

  get isEditSidebar() {
    return this.args.type === 'edit';
  }

  get shouldShowDeleteButton() {
    return this.args.brand && !this.args.brand.isDefault;
  }

  @action
  handleClose(brand: Brand | undefined, isBrandChanged: boolean) {
    this.args.onClose(brand);
    if (!isBrandChanged) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: `cancel_updating_brand`,
        place: 'brands',
        metadata: {
          brand_id: this.args.brand?.id,
          is_default_brand: this.args.brand?.isDefault,
          brand_type: this.args.brand?.isDefault ? 'default' : 'non-default',
        },
      });
    }
  }

  get helpCenterItems() {
    return this.args.helpCenters.map((helpCenter) => ({
      value: helpCenter.id,
      text: helpCenter.name,
      website_turned_on: helpCenter.website_turned_on,
    }));
  }

  @action
  onSelectHelpCenter(helpCenterId: number) {
    this.helpCenterId = helpCenterId;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'selected',
      object: `help_center`,
      place: 'brands',
      metadata: {
        brand_id: this.args.brand?.id,
        help_center_id: helpCenterId,
        is_default_brand: this.args.brand?.isDefault,
        brand_type: this.args.brand?.isDefault ? 'default' : 'non-default',
        is_help_center_live: this.helpCenterItems.find((item) => item.value === helpCenterId)
          ?.website_turned_on,
      },
    });
  }

  @action
  handleClickNewHelpCenter() {
    this.isNewHelpCenterModalOpen = true;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: `new_help_center_button`,
      place: 'brands',
      metadata: {
        brand_id: this.args.brand?.id,
        is_default_brand: this.args.brand?.isDefault,
        brand_type: this.args.brand?.isDefault ? 'default' : 'non-default',
      },
    });
  }

  @action
  handleCloseNewHelpCenterModal() {
    this.isNewHelpCenterModalOpen = false;
  }

  @action
  handleCreateNewHelpCenter(helpCenter: HelpCenter) {
    this.args.onNewHelpCenter(helpCenter);
    this.helpCenterId = helpCenter.id;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'created',
      object: `help_center`,
      place: 'brands',
      metadata: {
        brand_id: this.args.brand?.id,
        help_center_id: helpCenter.id,
        is_default_brand: this.args.brand?.isDefault,
        brand_type: this.args.brand?.isDefault ? 'default' : 'non-default',
        is_help_center_live: false,
      },
    });
  }

  isEmailDomainAuthenticated(email: string) {
    let emailDomain = getDomainName(email);
    return this.args.domains.find((domain) => domain.name === emailDomain)?.isAuthenticated;
  }

  isItemDisabled(senderEmailAddressSetting: SenderEmailAddressSettings) {
    return (
      !this.isEmailDomainAuthenticated(senderEmailAddressSetting.email) ||
      !senderEmailAddressSetting.verified
    );
  }

  get senderEmailAddressSettingsItems() {
    let senderEmailAddressSettings = this.args.senderEmailAddressSettings;

    return senderEmailAddressSettings && senderEmailAddressSettings?.length
      ? senderEmailAddressSettings.map((senderEmailAddressSetting) => ({
          value: senderEmailAddressSetting.id,
          text: senderEmailAddressSetting.email,
          isDisabled: this.isItemDisabled(senderEmailAddressSetting),
          component: 'new-settings/workspace/brands/brand-email-dropdown-item',
          componentAttrs: {
            verified: senderEmailAddressSetting.verified,
            isSelected: this.senderEmailAddressSettingsId === senderEmailAddressSetting.id,
            isAuthenticated: this.isEmailDomainAuthenticated(senderEmailAddressSetting.email),
          },
        }))
      : [
          {
            value: 0,
            text: '',
            component: 'new-settings/workspace/brands/brand-email-dropdown-empty-list',
            isDisabled: true,
          },
        ];
  }

  @action
  onSelectSenderEmailAddressSetting(senderEmailAddressSettingsId: number) {
    this.senderEmailAddressSettingsId = senderEmailAddressSettingsId;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'selected',
      object: `default_address`,
      place: 'brands',
      metadata: {
        brand_id: this.args.brand?.id,
        sender_email_address_settings_id: this.senderEmailAddressSettingsId,
        is_default_brand: this.args.brand?.isDefault,
        brand_type: this.args.brand?.isDefault ? 'default' : 'non-default',
      },
    });
  }

  @action
  handleClickNewAddress() {
    this.isNewAddressModalOpen = true;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: `new_address_button`,
      place: 'brands',
      metadata: {
        brand_id: this.args.brand?.id,
        sender_email_address_settings_id: this.senderEmailAddressSettingsId,
        is_default_brand: this.args.brand?.isDefault,
        brand_type: this.args.brand?.isDefault ? 'default' : 'non-default',
      },
    });
  }

  @action
  handleCloseNewAddressModal() {
    this.isNewAddressModalOpen = false;
  }

  @action
  handleCloseDependencyWarningModal() {
    this.showDependencyWarningModal = false;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: `cancel_delete_brand_button`,
      place: 'brands',
      metadata: {
        brand_id: this.args.brand?.id,
        is_default_brand: this.args.brand?.isDefault,
        brand_type: this.args.brand?.isDefault ? 'default' : 'non-default',
      },
    });
  }

  @action
  handleCreateNewAddress(senderEmailAddressSettings: SenderEmailAddressSettings) {
    this.args.onNewSenderEmailAddressSettings(senderEmailAddressSettings);
    this.senderEmailAddressSettingsId = senderEmailAddressSettings.id;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'created',
      object: `default_address`,
      place: 'brands',
      brand_id: this.args.brand?.id,
      metadata: {
        sender_email_address_settings_id: this.senderEmailAddressSettingsId,
        is_default_brand: this.args.brand?.isDefault,
        brand_type: this.args.brand?.isDefault ? 'default' : 'non-default',
      },
    });
  }

  @action
  handleClickMessengerStyles() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: `messenger_styles_button`,
      place: 'brands',
      metadata: {
        brand_id: this.args.brand?.id,
        is_default_brand: this.args.brand?.isDefault,
        brand_type: this.args.brand?.isDefault ? 'default' : 'non-default',
      },
    });
    safeWindowOpen(
      '/a/apps/_/settings/channels/messenger/web?section=general&tab=styling',
      '_blank',
    );
  }

  get isReady() {
    return this.name && this.helpCenterId !== undefined;
  }

  get isNameTooLong() {
    return this.name && this.name.length > 255;
  }

  get isNameMissing() {
    return !this.name || !this.name.trim();
  }

  @dropTask
  *saveTask(): TaskGenerator<void> {
    if (this.isNameTooLong) {
      return;
    }

    let wireFormat: BrandWireFormat;
    try {
      let params: BrandRequestParams = {
        app_id: this.appService.app.id,
        filename: this.filename,
        file_data: this.fileData,
        help_center_id: this.helpCenterId,
        sender_email_address_settings_id: this.senderEmailAddressSettingsId,
      };

      if (!this.args.brand?.isDefault) {
        params['name'] = this.name;
      }
      let response;
      let object;
      if (this.isNewSidebar) {
        response = yield postRequest(`/ember/brands`, params);
        object = 'create_brand_button';
      } else {
        response = yield putRequest(`/ember/brands/${this.args.brand?.id}`, params);
        object = 'update_brand_button';
      }
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        place: 'brands',
        object,
        app_id: this.appService.app.id,
        filename: this.filename,
        file_data: this.fileData,
        help_center_id: this.helpCenterId,
        sender_email_address_settings_id: this.senderEmailAddressSettingsId,
        brand_id: object === 'update_brand_button' ? this.args.brand?.id : undefined,
        is_default_brand: this.args.brand?.isDefault,
        brand_type: this.args.brand?.isDefault ? 'default' : 'non-default',
      });
      wireFormat = yield response.json() as BrandWireFormat;
      this.notificationsService.notifyConfirmation(
        this.intl.t('new-settings.workspace.brands.brand-saved-successfully'),
      );
    } catch (error) {
      console.error('saveTask() error', error);
      let message =
        (yield this.getErrorMessage(error)) ||
        this.intl.t('new-settings.workspace.brands.failed-to-save-brand');
      this.notificationsService.notifyResponseError(error, {
        default: message,
      });
      return;
    }
    let brand = {
      id: wireFormat.id,
      name: wireFormat.name,
      logoId: wireFormat.logo_id,
      logoUrl: wireFormat.logo_url,
      helpCenterId: wireFormat.help_center_id,
      senderEmailAddressSettingsId: wireFormat.sender_email_address_settings_id,
      isDefault: wireFormat.is_default,
    } as Brand;
    this.handleClose(brand, true);
  }

  private async getErrorMessage(error: ResponseError) {
    // If `error` doesn't conform to this shape, just fall back to a default
    // error message (as determined by the callsite).
    try {
      let response: any = await error.response.json();
      if (response?.errors?.length) {
        return response.errors[0].message;
      }
    } catch {
      return null;
    }
  }

  @action
  handleSave() {
    try {
      taskFor(this.saveTask).perform();
    } catch (error) {
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('new-settings.workspace.brands.failed-to-save-brand'),
      });
    }
  }

  @action
  handleLearnMoreClick() {
    window.Intercom('showArticle', 9744851);
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: `brands_learn_more_button`,
      place: 'brands',
      metadata: {
        brand_id: this.args.brand?.id,
        is_default_brand: this.args.brand?.isDefault,
        brand_type: this.args.brand?.isDefault ? 'default' : 'non-default',
      },
    });
  }

  get isSaving() {
    return taskFor(this.saveTask).isRunning;
  }

  get saveDisabled(): boolean {
    let canSave = this.isReady && !this.isSaving && !this.isNameTooLong && !this.isNameMissing;
    return !canSave;
  }

  get saveTooltipText(): string {
    if (this.isSaving) {
      return this.intl.t('new-settings.workspace.brands.saving');
    }

    if (this.saveDisabled) {
      if (this.isNameTooLong) {
        return this.intl.t('new-settings.workspace.brands.brand-name-too-long');
      }

      if (this.isNameMissing) {
        return this.intl.t('new-settings.workspace.brands.brand-name-missing-error');
      }

      if (!this.helpCenterId) {
        return this.intl.t('new-settings.workspace.brands.help-center-missing-error');
      }
    }

    return this.intl.t('new-settings.workspace.brands.save');
  }

  @dropTask
  *processLogo() {
    yield new Promise((resolve) => {
      let reader = new FileReader();
      let file = this.logoInput.files![0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.logoUrl = reader.result as string;
        this.filename = file.name;
        this.fileData = this.logoUrl.split(',')[1];
        resolve(undefined);
        this.intercomEventService.trackAnalyticsEvent({
          action: 'uploaded',
          object: `brands_logo`,
          place: 'brands',
          metadata: {
            brand_id: this.args.brand?.id,
            is_default_brand: this.args.brand?.isDefault,
            brand_type: this.args.brand?.isDefault ? 'default' : 'non-default',
          },
        });
      };
    });
  }

  @action
  handleLogoInput() {
    if (this.logoInput.files && this.logoInput.files.length === 0) {
      return;
    }

    taskFor(this.processLogo).perform();
  }

  @action
  openFilePicker() {
    this.logoInput.click();
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: `brands_logo_upload_button`,
      place: 'brands',
      metadata: {
        brand_id: this.args.brand?.id,
        is_default_brand: this.args.brand?.isDefault,
        brand_type: this.args.brand?.isDefault ? 'default' : 'non-default',
      },
    });
  }

  get brand(): Brand {
    return this.args.brand!;
  }

  @action
  handleDependentObjectCheck() {
    try {
      taskFor(this.fetchDependentObjects).perform();
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: `delete_brand_button`,
        place: 'brands',
        metadata: {
          brand_id: this.args.brand?.id,
          is_default_brand: this.args.brand?.isDefault,
          brand_type: 'non-default',
          style_count: this.dependencyData.dependent_messenger_styles?.length,
          address_count: this.dependencyData.dependent_email_addresses?.length,
        },
      });
    } catch (error) {
      console.error('Failed to check brand dependencies:', error);
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('new-settings.workspace.brands.failed-to-fetch-dependent-objects'),
      });
    }
  }

  get isFetchingDependentObjects() {
    return taskFor(this.fetchDependentObjects).isRunning;
  }

  @dropTask
  *fetchDependentObjects(): TaskGenerator<void> {
    try {
      this.showDependencyWarningModal = true;
      this.dependencyData = yield get(
        `/ember/brands/${this.args.brand?.id}/dependent_objects?app_id=${this.appService.app.id}`,
      ) as DependencyData;
    } catch (error) {
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('new-settings.workspace.brands.failed-to-fetch-dependent-objects'),
      });
      this.showDependencyWarningModal = false;
    }
  }

  @action
  handleDelete() {
    try {
      taskFor(this.deleteBrand).perform();
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: `confirm_delete_brand_button`,
        place: 'brands',
        metadata: {
          brand_id: this.args.brand?.id,
          is_default_brand: this.args.brand?.isDefault,
          brand_type: 'non-default',
          style_count: this.dependencyData.dependent_messenger_styles?.length,
          address_count: this.dependencyData.dependent_email_addresses?.length,
        },
      });
    } catch (error) {
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('new-settings.workspace.brands.failed-to-delete-brand'),
      });
    }
  }

  get isDeleting() {
    return taskFor(this.deleteBrand).isRunning;
  }

  @dropTask
  *deleteBrand(): TaskGenerator<void> {
    try {
      yield deleteRequest(`/ember/brands/${this.args.brand?.id}?app_id=${this.appService.app.id}`);
      this.showDependencyWarningModal = false;
      this.notificationsService.notifyConfirmation(
        this.intl.t('new-settings.workspace.brands.brand-deleted-successfully'),
      );
      this.args.onBrandDeletion?.();
    } catch (error) {
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('new-settings.workspace.brands.failed-to-delete-brand'),
      });
    }
  }

  onRender() {
    let copyBrandIdButton = document.querySelector('#brand-id-input-wrapper .form__input-suffix');
    if (copyBrandIdButton) {
      copyBrandIdButton.classList.add('cursor-pointer');
      if (!this.clipboard) {
        this.clipboard = this.clipboardService.createClipboard(
          copyBrandIdButton,
          this.args.brand?.id.toString() ?? '',
          () => {
            this.notificationsService.notifyConfirmation(
              this.intl.t('new-settings.workspace.brands.brand-id.copy-success'),
            );
          },
          () => {
            this.notificationsService.notifyWarning(
              this.intl.t('new-settings.workspace.brands.brand-id.copy-error'),
            );
          },
        );
      }
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Workspace::Brands::Sidebar': typeof Sidebar;
  }
}
