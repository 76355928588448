/* import __COLOCATED_TEMPLATE__ from './preview-toolbar-circle.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import templateOnlyComponent from '@ember/component/template-only';

const PreviewToolbarCircle = templateOnlyComponent();
export default PreviewToolbarCircle;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::DomainsAndAddresses::AddEmailAddress::PreviewToolbarCircle': typeof PreviewToolbarCircle;
    'new-settings/channels/email/domains-and-addresses/add-email-address/preview-toolbar-circle': typeof PreviewToolbarCircle;
  }
}
