/* import __COLOCATED_TEMPLATE__ from './getting-started-container.hbs'; */
/* RESPONSIBLE TEAM: team-self-serve */

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { assetUrl } from '@intercom/pulse/helpers/asset-url';
import { type Step } from 'embercom/objects/onboarding/checklist/step';
import type GreatGuidanceService from 'embercom/services/great-guidance-service';
import type IntlService from 'embercom/services/intl';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import { showNewMessageInIntercomWidget } from 'embercom/lib/intercom-widget-helper';

interface Args {}

interface Signature {
  Args: Args;
  Element: never;
}

export default class GettingStartedContainer extends Component<Signature> {
  @service declare intercomEventService: any;
  @service declare realTimeEventService: any;
  @service declare appService: $TSFixMe;
  @service declare onboardingHomeService: $TSFixMe;
  @service declare customerService: $TSFixMe;
  @service declare greatGuidanceService: GreatGuidanceService;
  @service declare intl: IntlService;

  @tracked resetModalOpen = false;
  @tracked resetSurveyModalOpen = false;
  @tracked leadSurveyCompletedByOtherAdmin = false;
  @tracked fadeIn = true;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: 'getting_started',
      context: 'getting_started_homepage',
      section: 'onboarding.getting-started',
      place: 'onboarding.getting-started',
    });

    this.addNexusEventListeners();
  }

  willDestroy() {
    super.willDestroy();
    this.removeNexusEventListeners();
  }

  addNexusEventListeners() {
    this.realTimeEventService.subscribeTopics(['mark-step-as-completed']);
    this.realTimeEventService.on('MarkStepAsCompleted', this, 'markStepCompleted');
  }

  removeNexusEventListeners() {
    this.realTimeEventService.unsubscribeTopics(['mark-step-as-completed']);
    this.realTimeEventService.off('MarkStepAsCompleted', this, 'markStepCompleted');
  }

  get totalSteps() {
    return this.steps.length;
  }

  get completedSteps() {
    return this.steps.filter((step: any) => step.completed).length;
  }

  get currentAssetUrl() {
    return assetUrl(
      `/assets/images/onboarding/getting-started/${this.greatGuidanceService.currentStepId}.png`,
    );
  }

  get blogUrl() {
    return 'https://www.intercom.com/blog/';
  }

  get helpCenterUrl() {
    if (this.appService.app.isStandaloneApp) {
      return 'https://fin.ai/help/';
    }
    return 'https://www.intercom.com/help/';
  }

  get showProfilingSurvey() {
    return this.appService.app.canSeeProfilingSurvey && !this.leadSurveyCompletedByOtherAdmin;
  }

  get steps(): any[] {
    return this.greatGuidanceService.steps;
  }

  get nextStep(): Step | undefined {
    if (this.greatGuidanceService.nextStepId) {
      let nextStep = this.steps.find(
        (step) => step.identifier === this.greatGuidanceService.nextStepId,
      );
      if (nextStep && nextStep.state === 'available') {
        return this.steps.find((step) => step.identifier === this.greatGuidanceService.nextStepId);
      }
    }

    return this.steps.find((step) => step.state === 'available');
  }

  markStepCompleted(event: any) {
    let step = this.steps.find((step: any) => step.identifier === event.step_identifier);
    if (step) {
      step.state = 'completed';
      this.greatGuidanceService.nextStepId = undefined;
      this.greatGuidanceService.currentStepId = this.greatGuidanceService.steps.find(
        (step: any) => step.state === 'available',
      )?.identifier;
      this.intercomEventService.trackAnalyticsEvent({
        action: 'completed',
        context: 'getting_started_homepage',
        object: `step-completed`,
        step_identifier: `${step.identifier}`,
        section: 'onboarding.getting-started',
        place: 'onboarding.getting-started',
      });
    }
  }

  @action
  async onSurveyCompletion() {
    await this.onboardingHomeService.resetGuide();
    await this.greatGuidanceService.fetchSteps(this.appService.app.id);
    this.greatGuidanceService.nextStepId = undefined;
    this.greatGuidanceService.currentStepId = this.nextStep?.identifier;
  }

  @action
  updateLeadSurveyCompletionStatus(completedAdminId: number) {
    this.leadSurveyCompletedByOtherAdmin =
      parseInt(this.appService.app.currentAdmin.id, 10) !== completedAdminId;
  }

  @action onOpenSectionChange(sectionId: string) {
    this.fadeIn = false;
    if (this.greatGuidanceService.currentStepId === sectionId) {
      this.greatGuidanceService.currentStepId = undefined;
    } else {
      setTimeout(() => {
        this.fadeIn = true;
        this.greatGuidanceService.currentStepId = sectionId;
      }, 300);
      this.intercomEventService.trackAnalyticsEvent({
        action: 'opened',
        context: 'getting_started_homepage',
        object: `${sectionId}-step`,
        section: 'onboarding.getting-started',
        place: 'onboarding.getting-started',
      });
    }
  }

  @action
  triggerAnalyticsForGuideCard(card_id: string, article_id: number) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'getting_started_guide_card',
      section: 'onboarding.getting-started',
      place: 'onboarding.getting-started',
      context: `getting_started_homepage.guide-cards.${card_id}`,
      article_id,
    });
  }

  @action toggleResetModal() {
    this.resetModalOpen = !this.resetModalOpen;
  }

  @action
  toggleResetSurveyModal() {
    this.resetSurveyModalOpen = !this.resetSurveyModalOpen;
  }

  get isZendeskPlatform() {
    return this.appService.app.hasStandalonePlatform('zendesk');
  }

  get isSalesforcePlatform() {
    return this.appService.app.hasStandalonePlatform('salesforce');
  }

  get platform() {
    if (this.isZendeskPlatform) {
      return this.intl.t('onboarding.getting-started.platforms.zendesk.name');
    } else if (this.isSalesforcePlatform) {
      return this.intl.t('onboarding.getting-started.platforms.salesforce.name');
    }
    return '';
  }

  @action
  openChatToFinSupport() {
    showNewMessageInIntercomWidget();
  }

  @action
  openLinkInNewTab(url: string) {
    safeWindowOpen(url, '_blank');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::GettingStarted::GettingStartedContainer': typeof GettingStartedContainer;
  }
}
