/* import __COLOCATED_TEMPLATE__ from './card.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import { action } from '@ember/object';
import { type Router } from '@ember/routing';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type HelpCenterSite from 'embercom/models/help-center-site';

interface Args {
  helpCenter: HelpCenterSite;
}

interface Signature {
  Args: Args;
  Element: Element;
}

export default class Card extends Component<Signature> {
  @service declare appService: $TSFixMe;
  @service declare router: Router;

  get styles() {
    let styles = '';
    if (this.heroBackgroudGradient) {
      styles += this.heroBackgroudGradient;
    } else {
      styles += this.heroBackgroundColor;
    }
    return styles;
  }

  get heroBackgroudGradient() {
    let steps = this.args.helpCenter.customizationOptions?.header?.backgroundGradient?.steps;
    if (steps?.length) {
      return `background-image: linear-gradient(to bottom right, ${steps.map(({ color }) => color)})`;
    }
    return undefined;
  }

  get heroBackgroundColor() {
    return `background: ${this.args.helpCenter.customizationOptions?.header?.backgroundColor};`;
  }

  @action
  onClick() {
    this.router.transitionTo(
      'apps.app.settings.helpcenter.workspace-helpcenter',
      this.appService.app.id,
      this.args.helpCenter.id,
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Helpcenter::AllHelpCenters::Card': typeof Card;
    'new-settings/helpcenter/all-help-centers/card': typeof Card;
  }
}
