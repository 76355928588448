/* import __COLOCATED_TEMPLATE__ from './snippet-editor.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { type TaskGenerator } from 'ember-concurrency';

import type ContentSnippet from 'embercom/models/content-service/content-snippet';
import type IntlService from 'ember-intl/services/intl';
import { FIN_SUPPORTED_LANGUAGES, type Language } from 'embercom/lib/ai-content-library/constants';
import type Store from '@ember-data/store';

interface Args {
  onSave?: (snippet: ContentSnippet) => void;
  onCancel?: () => void;
  snippet: ContentSnippet;
}

interface DropDownOption {
  text: string;
  value: string;
}

export default class SnippetEditor extends Component<Args> {
  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.snippet = this.args.snippet;
  }
  @service declare store: Store;
  @service declare intercomEventService: any;
  @service declare notificationsService: any;
  @service declare appService: any;
  @service declare intl: IntlService;
  @tracked snippet: ContentSnippet;

  @action saveContentSnippet(): void {
    taskFor(this.saveSnippet).perform();
  }

  get isSaving(): boolean {
    return taskFor(this.saveSnippet).isRunning;
  }

  get languages(): Array<DropDownOption> {
    return FIN_SUPPORTED_LANGUAGES.map((language: Language) => {
      return {
        text: language.name,
        value: language.locale,
      };
    });
  }

  @action focusEditor(): void {
    let composer: HTMLElement | null = document.querySelector(
      '#fin-snippet-content-editor .ProseMirror',
    );
    if (composer && composer !== document.activeElement) {
      composer?.focus();
    }
  }

  @action
  closeEditor(): void {
    this.snippet?.rollbackAttributes();
    this.args.onCancel?.();
  }

  @action
  setLocale(newLocale: string): void {
    this.snippet.locale = newLocale;
  }

  @task *saveSnippet(): TaskGenerator<void> {
    try {
      yield this.snippet.save();
      if (this.args.onSave) {
        this.args.onSave(this.snippet);
      }
      this.notificationsService.notifyConfirmation(
        this.intl.t('ai-content-library.content-snippet.side-drawer.create-success'),
      );
    } catch (e) {
      this.notificationsService.notifyResponseError(e, {
        default: this.intl.t('ai-content-library.content-snippet.side-drawer.create-error'),
      });
    }
  }

  get disableSaveButton() {
    return !this.args.snippet.locale;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::AnswerDebugger::SnippetEditor': typeof SnippetEditor;
    'operator/fin/answer-debugger/snippet-editor': typeof SnippetEditor;
  }
}
