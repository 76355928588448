/* import __COLOCATED_TEMPLATE__ from './roll-out-percentage.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { action } from '@ember/object';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    accordion: any;
    ruleset: $TSFixMe;
    partialSave: () => Promise<void>;
  };
}

export default class RollOutPercentage extends Component<Signature> {
  @service declare intl: IntlService;

  @tracked probability: number | null;

  constructor(owner: unknown, args: Signature['Args']) {
    super(owner, args);
    this.probability = this.savedValue;
  }

  get savingDisabled() {
    return this.isInvalid || this.args.ruleset.isSaving;
  }

  get isInvalid() {
    return !this.probability || this.probability < 1 || this.probability > 100;
  }

  get errorMessage() {
    return this.isInvalid
      ? this.intl.t(
          'operator.fin.setup.tabs.setup-and-go-live.phone.roll-out-percentage.error-invalid-percentage',
        )
      : undefined;
  }

  get behavior() {
    return this.args.ruleset.rulesetLinks.firstObject.object;
  }

  get savedValue() {
    return this.behavior?.matchPercentage ?? 100;
  }

  @action
  resetPredicate() {
    this.probability = this.savedValue;
  }

  @action
  setProbability(value: string) {
    this.probability = parseInt(value, 10);
  }

  @action
  async updateOfficeHours() {
    if (!this.probability || this.probability < 1 || this.probability > 100) {
      return;
    }

    this.behavior.matchPercentage =
      !this.probability || this.probability === 100 ? null : this.probability;

    await this.args.partialSave();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::Setup::Sections::WhoWillTalkToFin::RollOutPercentage': typeof RollOutPercentage;
  }
}
