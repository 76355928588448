/* import __COLOCATED_TEMPLATE__ from './addresses-table.hbs'; */
/* RESPONSIBLE TEAM: team-channels */

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { taskFor } from 'ember-concurrency-ts';
import { tracked } from '@glimmer/tracking';
import type IntlService from 'ember-intl/services/intl';
import type CustomEmailAddress from 'embercom/models/custom-email-address';
import type BrandService from 'embercom/services/brand-service';
import type { Domain } from 'embercom/services/domain-service';
import type EntriConnectService from 'embercom/services/entri-connect-service';
import type SenderEmailVerificationService from 'embercom/services/sender-email-verification-service';
import type SenderEmailAddressSettings from 'embercom/models/sender-email-address-settings';
import { dropTask } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import { type DomainEmailAddress } from 'embercom/services/domain-service';
import type Admin from 'embercom/models/admin';

interface Args {
  domains: Domain[];
  changeStep: (step: string | null, origin: string) => void;
  setEmail: (email: string, options?: { reloadDomains?: boolean }) => Promise<void>;
  setDomainName: (domainName: string) => void;
  domain: Domain | null;
  email: string | null;
}

interface AliasEmailBlocker {
  message: string;
  admins: Array<Admin>;
}

interface Signature {
  Args: Args;
}

export interface TableColumn {
  label: string;
  valuePath: string;
  width: string;
  tooltip?: string;
}

export type reasonData = {
  brands?: {
    brand_name: string;
  };
  proactive_support?: {
    total: string;
  };
};

export default class AddressesTable extends Component<Signature> {
  @service declare intercomEventService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare brandService: BrandService;
  @service declare appService: $TSFixMe;
  @service declare entriConnectService: EntriConnectService;
  @service declare senderEmailVerificationService: SenderEmailVerificationService;
  @tracked domainCanBeAutoConfigured = false;
  @tracked reasonsEntityCantBeDeleted: reasonData[] = [];
  @tracked dependencyCheckStatus = false;
  @tracked brand = '';
  @tracked showAliasEmailAddressModal = false;
  @tracked admins = [];
  @tracked selectedEmailForDeletion?: DomainEmailAddress;

  place = 'addresses-table';

  get emailAddress(): string {
    return this.args.email ?? '';
  }

  get selectedEmailAddressForDeletion(): string {
    return this.selectedEmailForDeletion?.email ?? '';
  }

  get isDeleteEmailModalOpen(): boolean {
    return !!this.selectedEmailForDeletion;
  }

  @action
  setSelectedEmailForDeletion(email: DomainEmailAddress | undefined) {
    this.selectedEmailForDeletion = email;
  }

  @action
  updateDependencyCheckStatus(status: boolean) {
    this.dependencyCheckStatus = status;
  }

  @action
  async deleteEmailAddress(): Promise<void> {
    this.trackEvent('clicked', 'delete_email_address');
    let email = this.selectedEmailForDeletion;
    this.setSelectedEmailForDeletion(undefined);
    taskFor(this.deleteTask).perform(email!);
  }

  @action
  toggleCustomDeletionModal() {
    this.showAliasEmailAddressModal = !this.showAliasEmailAddressModal;
  }

  @dropTask
  *deleteTask(email: DomainEmailAddress): TaskGenerator<void> {
    this.updateDependencyCheckStatus(false);
    let settings = email.senderEmailAddressSettings;
    try {
      // Reload domains is needed only while creating a new email address
      yield this.args.setEmail(email.email, { reloadDomains: false });

      if (settings) {
        yield settings.destroyRecord();
        this._pruneAddresses(settings);

        if (this.reasonsEntityCantBeDeleted.length === 0) {
          this._handleEmailDeletionSuccess();
          // refresh company email addresses
          // This is because the backend deletes the company email address if present
          yield this.appService.app.refreshCompanyEmailAddresses();
        }
      }
    } catch (error) {
      let response = error.jqXHR.responseJSON;
      if (response.statusCode === 428) {
        let aliasEmailBlocker = response.reasons?.find(
          (reason: { alias_email_blocker?: AliasEmailBlocker }) => reason.alias_email_blocker,
        )?.alias_email_blocker;

        if (aliasEmailBlocker?.message === 'Matching alias email found') {
          this.toggleCustomDeletionModal();
          this.admins = aliasEmailBlocker.admins ?? [];
          this.brand = email.brandName;
        } else {
          this.trackEvent('address_not_deletable', 'delete_email_address');
          this.reasonsEntityCantBeDeleted = response.reasons ?? [];
        }
      } else {
        this.trackEvent('error', 'delete_email_address');
        console.error('Error deleting the record:', error);
        this.updateDependencyCheckStatus(true);
      }
      // Rollback the deletion of the email address
      yield settings?.rollbackAttributes?.();
    }
  }

  _handleEmailDeletionSuccess() {
    this.notificationsService.notifyConfirmation(
      this.intl.t('new-settings.channels.email.addresses.sidebar.deleted-email-successfully'),
    );
    this.trackEvent('success', 'delete_email_address');
    this.args.changeStep(null, this.place);
  }

  _pruneAddresses(settings: SenderEmailAddressSettings | undefined) {
    if (!settings) {
      return;
    }
    // seems to be a bug in our version of ember-data where we end up with both the
    // saved & unsaved records here, need to look at whether we can fix this with a local ID?
    // https://github.com/emberjs/data/issues/7354
    let unsaved = settings.senderEmailAddresses.filter((address: $TSFixMe) => address.isNew);
    settings.senderEmailAddresses.removeObjects(unsaved);
  }

  get showBrands() {
    return this.brandService.isAppOnRightMultibrandPlan;
  }

  get columns(): TableColumn[] {
    let columns = [
      {
        label: this.intl.t(
          'new-settings.channels.email.connect-email.addresses-table.columns.email',
        ),
        valuePath: 'email',
        width: '40%',
      },
    ];
    if (this.showBrands) {
      columns.push({
        label: this.intl.t(
          'new-settings.channels.email.connect-email.addresses-table.columns.brand',
        ),
        valuePath: 'brandName',
        width: '25%',
      });
    }
    columns.push({
      label: this.intl.t(
        'new-settings.channels.email.connect-email.addresses-table.columns.automatic-forwarding',
      ),
      valuePath: 'inboundStatus',
      width: '20%',
      tooltip: this.intl.t(
        'new-settings.channels.email.connect-email.addresses-table.columns.automatic-forwarding-tooltip',
      ),
    } as TableColumn);
    columns.push({
      label: this.intl.t(
        'new-settings.channels.email.connect-email.addresses-table.columns.outbound',
      ),
      valuePath: 'outboundStatus',
      width: '10%',
      tooltip: this.intl.t(
        'new-settings.channels.email.connect-email.addresses-table.columns.outbound-tooltip',
      ),
    } as TableColumn);
    columns.push({ label: '', valuePath: 'edit', width: '5%' });
    columns.push({ label: '', valuePath: 'delete', width: '5%' });
    return columns;
  }

  get emptyRowData() {
    return [{ email: '', brandName: '', outboundStatus: '' }];
  }

  get hasNoVerifiedAddresses() {
    return this.args.domain?.addresses?.every((address) => !address.verified);
  }

  get canUseAutomaticStep() {
    return this.domainCanBeAutoConfigured;
  }

  get dkimSettings() {
    return this.args.domain?.dkimSettings;
  }

  get customBounceSettings() {
    return this.args.domain?.bounceSettings;
  }

  hasNoSenderEmailAddresses(senderEmailAddresses: CustomEmailAddress[]) {
    return senderEmailAddresses?.length === 0;
  }

  @action
  async checkDomainAndLoadEntri(): Promise<void> {
    this.domainCanBeAutoConfigured = await taskFor(this.entriConnectService.checkDomain).perform(
      Number(this.dkimSettings?.id) ?? 0,
      Number(this.customBounceSettings?.id) ?? 0,
    );
    if (this.domainCanBeAutoConfigured) {
      taskFor(this.entriConnectService.loadEntriLibrary).perform();
    }
  }

  @action
  async navigateToSetupDomain(domainName: string) {
    this.args.setDomainName(domainName);
    if (this.args.domain) {
      if (this.hasNoVerifiedAddresses && !this.args.domain.dkimSettings?.hasVerifiedAdminEmails) {
        this.args.changeStep('access-setup-domain', this.place);
      } else {
        await this.checkDomainAndLoadEntri();
        if (this.canUseAutomaticStep) {
          this.args.changeStep('setup-domain', this.place);
        } else {
          this.args.changeStep('setup-domain-manually', this.place);
        }
      }
    }
  }

  @action
  async openSetupAutomaticForwarding(emailAddress: string) {
    this.trackEvent('clicked', 'verify_automatic_forwarding_link');
    await this.args.setEmail(emailAddress);
    this.args.changeStep('setup-email-forwarding', this.place);
  }

  @action
  async openSetupSidebar(emailAddress: string) {
    await this.args.setEmail(emailAddress, { reloadDomains: false });
    this.args.changeStep('edit-address', this.place);
  }

  @action
  async onVerifyEmailClick(emailAddressId: string) {
    await this.senderEmailVerificationService.resendVerificationEmail({
      senderEmailAddressSettingsId: emailAddressId,
      appId: this.appService.app.id,
      adminId: this.appService.app.currentAdmin.id,
    });
    this.trackEvent('clicked', 'send_verification_email');
  }

  @action
  async verifyDomain(domain: Domain) {
    try {
      await domain.dkimSettings?.verify();
    } catch (error) {
      this.notificationsService.notifyWarning(
        this.intl.t(
          'new-settings.channels.email.addresses.domain-sidebar.domain-verification-failed',
        ),
      );
    }
  }

  @action
  async finishSetup(emailAddress: string) {
    this.args.changeStep('overview', this.place);
    this.args.setEmail(emailAddress, { reloadDomains: false });
    this.trackEvent('clicked', 'finish_setup');
  }

  @action
  trackEvent(action: string, object: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object,
      place: this.place,
      context: 'email-setup',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::DomainsAndAddresses::AddressesTable': typeof AddressesTable;
  }
}
