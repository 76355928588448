/* import __COLOCATED_TEMPLATE__ from './what-can-fin-answer.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent-2 */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type { FinSetupBehaviorSaveParams } from 'embercom/lib/operator/fin/types';
import type Store from '@ember-data/store';
import type IntlService from 'embercom/services/intl';
import type { ChannelType } from 'embercom/lib/workflows/fin-workflow-preview';
import type Profile from 'embercom/models/ai-agent/profile';
import type PermittedTheme from 'embercom/models/ai-agent/permitted-theme';

interface Args {
  accordion: $TSFixMe;
  ruleset: $TSFixMe;
  partialSave: (params: FinSetupBehaviorSaveParams) => void;
  channelType: ChannelType;
}

type RolloutMode = 'theme_based' | 'all';

export default class WhatCanFinAnswer extends Component<Args> {
  @service declare attributeService: $TSFixMe;
  @service declare appService: $TSFixMe;
  @service declare store: Store;
  @service declare intl: IntlService;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
  }

  get profile(): Profile {
    return this.args.ruleset.rulesetLinks.firstObject.object;
  }

  get rolloutMode(): RolloutMode {
    return this.profile.themeBasedRolloutEnabled ? 'theme_based' : 'all';
  }

  get permittedThemesAsString(): string {
    return this.permittedThemes.map((theme) => `'${theme.name}'`).join(', ');
  }

  get permittedThemes(): Array<PermittedTheme> {
    return this.profile.permittedThemes || [];
  }

  get themeTableColumns() {
    let columns = [
      {
        label: this.intl.t(
          'operator.fin.setup.setup-and-go-live.what-can-fin-answer.table.name-column-heading',
        ),
        valuePath: 'label',
        width: '30%',
      },
      {
        label: this.intl.t(
          'operator.fin.setup.setup-and-go-live.what-can-fin-answer.table.description-column-heading',
        ),
        valuePath: 'description',
        tooltip: this.intl.t(
          'operator.fin.setup.setup-and-go-live.what-can-fin-answer.table.description-tooltip',
        ),
      },
      { label: '', valuePath: 'actionButtons', width: '0' },
    ];

    return columns;
  }

  get savingForbiddenReason(): string | undefined {
    let themeBasedRolloutDisabled = this.profile.themeBasedRolloutEnabled === false;
    if (themeBasedRolloutDisabled) {
      return undefined;
    }

    let invalidThemes = this.permittedThemes.filter(
      (theme) => theme.name === '' || theme.description === '',
    );
    if (invalidThemes.length > 0) {
      return this.intl.t(
        'operator.fin.setup.setup-and-go-live.what-can-fin-answer.no-empty-fields',
      );
    }
    if (this.permittedThemes.length === 0) {
      return this.intl.t(
        'operator.fin.setup.setup-and-go-live.what-can-fin-answer.at-least-one-field',
      );
    }
    return undefined;
  }

  @action setRolloutMode(value: RolloutMode) {
    this.profile.themeBasedRolloutEnabled = value === 'theme_based';
  }

  @action removeListOption(index: number) {
    this.permittedThemes.removeAt(index);
  }

  @action addListOption() {
    // @ts-ignore
    let newTheme = this.profile.store.createFragment('ai-agent/permitted-theme', {
      name: '',
      description: '',
    });
    this.permittedThemes.pushObject(newTheme);
  }

  @action updateThemeName(index: number, event: InputEvent) {
    let theme = this.permittedThemes.objectAt(index);
    if (theme) {
      theme.name = (event.target as HTMLInputElement).value;
    }
  }

  @action updateThemeDescription(index: number, event: InputEvent) {
    let theme = this.permittedThemes.objectAt(index);
    if (theme) {
      theme.description = (event.target as HTMLInputElement).value;
    }
  }

  @action
  async updateEnabledThemes() {
    this.args.partialSave({
      theme_based_rollout: {
        enabled: this.profile.themeBasedRolloutEnabled,
        permitted_themes: this.permittedThemes.map((theme) => ({
          name: theme.name,
          description: theme.description,
        })),
      },
    });
  }

  @action reorder(newState: Array<PermittedTheme>) {
    this.profile.permittedThemes = newState;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::Setup::Sections::WhatCanFinAnswer': typeof WhatCanFinAnswer;
  }
}
