/* import __COLOCATED_TEMPLATE__ from './preferences.hbs'; */
/* RESPONSIBLE TEAM: team-app-security */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type IntlService from 'ember-intl/services/intl';

interface Args {}

interface Signature {
  Args: Args;
  Element: never;
}

export default class Preferences extends Component<Signature> {
  @service declare intl: IntlService;

  get title() {
    return this.intl.t('new-settings.submenu.personal.preferences');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Personal::Preferences': typeof Preferences;
  }
}
