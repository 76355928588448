/* import __COLOCATED_TEMPLATE__ from './empty-state.hbs'; */
/* RESPONSIBLE TEAM: team-channels */

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

interface Signature {
  step: string | null;
  changeStep: (step: string | null, place: string) => void;
}

export default class EmptyStateComponent extends Component<Signature> {
  @service declare intercomEventService: any;

  @action
  trackReadSetupGuide() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'read_setup_guide_link',
      place: 'empty-state',
      context: 'domains-and-addresses-tab',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::DomainsAndAddresses::EmptyState': typeof EmptyStateComponent;
  }
}
