/* import __COLOCATED_TEMPLATE__ from './change-password-fields.hbs'; */
/* RESPONSIBLE TEAM: team-app-security */

import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import type IntlService from 'ember-intl/services/intl';
import type Admin from 'embercom/models/admin';

interface Args {}

interface Signature {
  Args: Args;
  Element: never;
}

export default class EmailAddressInputField extends Component<Signature> {
  @service declare notificationsService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare appService: $TSFixMe;

  @tracked currentPassword = '';
  @tracked newPassword = '';
  @tracked confirmNewPassword = '';

  get admin() {
    return this.appService.app.currentAdmin as Admin;
  }

  get newPasswordsMatch(): boolean {
    return this.newPassword === this.confirmNewPassword;
  }

  get allPasswordFieldsComplete(): boolean {
    return Boolean(
      this.currentPassword.length && this.newPassword.length && this.confirmNewPassword.length,
    );
  }

  get isUpdatingPassword(): boolean {
    return taskFor(this.updateUserPassword).isRunning;
  }

  get isNewPasswordDisabled(): boolean {
    return this.currentPassword.length < 3;
  }

  get isConfirmNewPasswordDisabled(): boolean {
    return this.currentPassword.length < 3 || this.newPassword.length < 3;
  }

  @dropTask
  *updateUserPassword() {
    if (!this.allPasswordFieldsComplete) {
      this.notificationsService.notifyError(
        this.intl.t('apps.app.account.password.complete-all-fields'),
      );
    } else if (!this.newPasswordsMatch) {
      this.notificationsService.notifyError(
        this.intl.t('apps.app.account.password.confirmation-not-match'),
      );
    } else {
      try {
        yield this.admin.updatePersonalSettings({
          id: this.admin.id,
          app_id: this.appService.app.id,
          current_password: this.currentPassword,
          password: this.newPassword,
          password_confirmation: this.confirmNewPassword,
        });
        this.notificationsService.notifyConfirmation(
          this.intl.t('apps.app.account.password.successfully-changed'),
        );
        this.currentPassword = '';
        this.newPassword = '';
        this.confirmNewPassword = '';
      } catch (err) {
        this._showErrorForResponse(err);
      }
    }
  }

  _showErrorForResponse(err: any) {
    if (err.jqXHR.status === 401) {
      this.notificationsService.notifyError(
        this.intl.t('apps.app.account.password.incorrect-password'),
      );
    } else if (err.jqXHR.status === 422) {
      this.notificationsService.notifyError(
        this.intl.t('apps.app.account.password.errors-in-updates', {
          responseTextErrors: JSON.parse(err.jqXHR.responseText).errors,
        }),
      );
    } else {
      this.notificationsService.notifyError(
        this.intl.t('apps.app.account.password.password-not-updated'),
      );
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Personal::Preferences::ChangePasswordFields': typeof EmailAddressInputField;
    'new-settings/personal/preferences/change-password-fields': typeof EmailAddressInputField;
  }
}
