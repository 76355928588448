/* import __COLOCATED_TEMPLATE__ from './contents-card.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */

import { type RelevantContent } from 'embercom/lib/content-service/fin-answers-api';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { EntityType } from 'embercom/models/data/entity-types';
import type IntlService from 'ember-intl/services/intl';
import type Session from 'embercom/services/session';
import type Store from '@ember-data/store';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { type TaskGenerator } from 'ember-concurrency';
import {
  humanReadableObjectNames,
  objectNames,
  objectIcons,
} from 'embercom/models/data/matching-system/matching-constants';
import { capitalize } from '@ember/string';
import { tracked } from '@glimmer/tracking';
import type ContentSnippet from 'embercom/models/content-service/content-snippet';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    content: RelevantContent;
    snippet?: ContentSnippet;
    index?: number;
    isStandalone?: boolean;
  };
}

export default class ContentsCard extends Component<Signature> {
  @service declare session: Session;
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare intercomEventService: any;

  @tracked isExpanded = false;
  @tracked hasBeenEdited = false;
  @tracked isEditing = false;
  @tracked snippet?: ContentSnippet = this.args.snippet;

  get isArticleContent(): boolean {
    return this.args.content.entity_type === EntityType.ArticleContent;
  }

  get isExternalContent(): boolean {
    return this.args.content.entity_type === EntityType.ExternalContent;
  }

  get isContentSnippet(): boolean {
    return this.args.content.entity_type === EntityType.ContentSnippet;
  }

  get displayCounter(): number | undefined {
    return this.args.index !== undefined ? this.args.index + 1 : undefined;
  }

  get contentIcon(): InterfaceIconName {
    return objectIcons[this.args.content.entity_type];
  }

  get contentTypeHumanReadableName(): string {
    return capitalize(humanReadableObjectNames[this.args.content.entity_type]);
  }

  get contentTypeStringCode(): string {
    return objectNames[this.args.content.entity_type];
  }

  get canToggleContentCard(): boolean | null {
    let contentCard = document.querySelector(`[data-relevant-content-card="${this.args.index}"]`);
    return contentCard && contentCard?.clientHeight > 50;
  }

  get cardActionLabel(): string {
    return this.isExpanded
      ? this.intl.t('operator.fin.answer-debugger.content-card.collapse')
      : this.intl.t('operator.fin.answer-debugger.content-card.expand');
  }

  get lastUpdated(): Date | string | undefined {
    if (this.snippet) {
      return this.snippet.updatedAt;
    } else {
      return this.args.content.updated_at;
    }
  }

  get updatedAtLabel(): string | undefined {
    let lastUpdated = this.lastUpdated;
    if (!lastUpdated) {
      return;
    }
    let formattedUpdatedAt = this.intl.formatRelative(lastUpdated, {
      style: 'narrow',
    });
    if (this.isExternalContent) {
      return this.intl.t('operator.fin.answer-debugger.content-card.last-synced', {
        updatedAt: formattedUpdatedAt,
      });
    } else {
      return this.intl.t('operator.fin.answer-debugger.content-card.last-edited', {
        updatedAt: formattedUpdatedAt,
      });
    }
  }

  get contentLinkTrackingObject(): string {
    switch (this.args.content.entity_type) {
      case EntityType.ArticleContent:
        return 'articles_view_link';
      case EntityType.ExternalContent:
        return 'external_content_view_link';
      case EntityType.ContentSnippet:
        return 'content_snippet_view_link';
      default:
        return 'content_view_link';
    }
  }

  get isLoadingEditor(): boolean {
    return taskFor(this.getContentSnippet).isRunning;
  }

  @action onSnippetSaved(): void {
    this.snippet?.reload();
    this.isEditing = false;
    this.hasBeenEdited = true;
    this.trackAnalyticsEvent({ action: 'clicked', object: 'save_button' });
  }

  @action onSnippetCancelled(): void {
    this.isEditing = false;
    this.trackAnalyticsEvent({ action: 'clicked', object: 'cancel_button' });
  }

  @action openSnippetEditor(): void {
    if (this.snippet === undefined) {
      taskFor(this.getContentSnippet).perform();
    }
    this.isEditing = true;
    this.trackAnalyticsEvent({ action: 'clicked', object: 'content_snippet_edit_btn' });
  }

  @task *getContentSnippet(): TaskGenerator<void> {
    this.snippet = yield this.store.findRecord(
      'content-service/content-snippet',
      this.args.content.entity_id,
    );
  }

  @action
  toggleContentCard(): void {
    this.isExpanded = !this.isExpanded;
  }

  @action
  trackAnalyticsEvent(data: TrackAnalyticsEventParms): void {
    let { action, object, metadata } = data;
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object,
      section: 'operator',
      context: 'inbox',
      place: 'contents_card',
      ...metadata,
    });
  }
}

interface TrackAnalyticsEventParms {
  action: string;
  object: string;
  metadata?: any;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::AnswerDebugger::ContentsCard': typeof ContentsCard;
  }
}
