/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
import templateOnlyComponent from '@ember/component/template-only';
import { type NavItem } from './submenu-items';

interface Args {
  item: NavItem;
  itemTitle: $TSFixMe;
}

interface Signature {
  Args: Args;
  Blocks: {
    titleIcon?: [];
  };
}

const Item = templateOnlyComponent<Signature>();
export default Item;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Navigation::Item': typeof Item;
  }
}
