/* import __COLOCATED_TEMPLATE__ from './away-status-reason-row.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from 'tracked-built-ins';
import { action } from '@ember/object';
import type AwayStatusReason from 'embercom/models/away-status-reason';
// @ts-ignore
import intermoji from '@intercom/intermoji';
// @ts-ignore
import keycodes from '@intercom/pulse/lib/keynames';
import type IntlService from 'ember-intl/services/intl';
// @ts-ignore
import type NotificationsService from 'embercom/services/notifications-service';
import { ResponseError } from 'embercom/lib/inbox/requests';
import type AdminAwayService from 'embercom/services/admin-away-service';

interface Args {
  index: number;
  awayStatusReason: AwayStatusReason;
  isArchivePossible?: boolean;
  isSortable?: boolean;
  afterUnarchive?: () => void;
  newAddedItemOrder?: number;
  on404Error?: (type: string) => void;
}

interface Signature {
  Element: never;
  Args: Args;
}

export default class AwayStatusReasonRow extends Component<Signature> {
  @service declare session: any;
  @service declare store: any;
  @service declare intl: IntlService;
  // @ts-ignore
  @service declare notificationsService: NotificationsService;
  @service declare adminAwayService: AdminAwayService;

  @tracked isEditing: boolean = this.isNewRecord;
  @tracked isArchiveModalVisible = false;
  @tracked isReasonDuplicateModalVisible = false;
  @tracked inputTouched = false;

  get isDraggingDisabled() {
    return !this.args.isSortable || this.isEditing || this.args.awayStatusReason.deleted;
  }

  get isNewRecord() {
    return this.args.awayStatusReason.isNew as unknown as boolean;
  }

  get isSaving() {
    return this.args.awayStatusReason.isSaving as unknown as boolean;
  }

  get emojiUri() {
    if (this.args.awayStatusReason) {
      return intermoji.twemojiSVGUri(this.args.awayStatusReason.emoji);
    }
  }

  get isDuplicateOfArchived() {
    return this.args.awayStatusReason.duplicateOf?.get('deleted');
  }

  get isValid() {
    // we only check FE validations here, as the BE validations are checked on save
    // and are not blocking the save button
    if (this.args.awayStatusReason.validations.isValid) {
      return true;
    }

    // if the reason is a duplicate of an archived one, we don't want to block saving
    // as there's a modal that asks the user if they want to restore the duplicate
    if (this.isDuplicateOfArchived) {
      return true;
    }

    return false;
  }

  get validationErrorTooltip() {
    return this.args.awayStatusReason.errors.firstObject?.message;
  }

  get hasValidationErrorVisible() {
    return this.inputTouched && !this.isValid;
  }

  @action onKeyUp(event: KeyboardEvent) {
    this.inputTouched = true;

    if (event.keyCode === keycodes.enter) {
      this.save();
    } else {
      this.validate();
    }
  }

  @action selectEmoji(emoji: string) {
    let unicodeEmoji = intermoji.unicodeFromIdentifier(emoji);
    let twemojiCompatibleEmoji = intermoji.getSupportedTwemoji(unicodeEmoji);
    this.args.awayStatusReason.set('emoji', twemojiCompatibleEmoji);
  }

  @action async validate() {
    this.args.awayStatusReason.errors.clear();

    let { validations } = await this.args.awayStatusReason.validations.validate();
    if (!validations.isValid) {
      // add error messages to the model so it's easier to display them in the UI
      validations.errors.forEach((error: any) => {
        this.args.awayStatusReason.errors.add(
          error.attribute,
          this._validationErrorMessage(error.type),
        );
      });
    }
  }

  @action async restoreDuplicate() {
    this.isReasonDuplicateModalVisible = false;
    let duplicate = this.store.peekRecord(
      'away-status-reason',
      this.args.awayStatusReason.duplicateOf.get('id'),
    );
    await this.toggleArchive(duplicate, false);
    this.cancelEditMode();
  }

  @action async save() {
    if (this.isDuplicateOfArchived) {
      this.isReasonDuplicateModalVisible = true;
      return;
    }

    try {
      await this.args.awayStatusReason.save();
      await this.adminAwayService.fetchAwayStatusReasons(true);
      this.isEditing = false;
      this.notificationsService.notifyConfirmation(
        this.intl.t(this._translationKey('notifications.save-success')),
      );
    } catch (e) {
      if (e.jqXHR) {
        if (e.jqXHR.status === 404) {
          this.args.on404Error?.('save');
          return;
        }

        let errors = e.jqXHR.responseJSON.error_codes;
        Object.entries(errors).forEach(([key, value]) => {
          this.args.awayStatusReason.errors.add(key, this._validationErrorMessage(value as string));
        });
      }
      this.notificationsService.notifyError(
        this.intl.t(this._translationKey('notifications.save-failure')),
      );
    }
  }

  @action cancelEditMode() {
    this.isEditing = false;
    this.inputTouched = false;
    this.args.awayStatusReason.rollbackAttributes();
  }

  @action async archive() {
    await this.toggleArchive(this.args.awayStatusReason, true);
  }

  @action async unarchive() {
    await this.toggleArchive(this.args.awayStatusReason, false, this.args.afterUnarchive);
  }

  @action async toggleArchive(
    targetReason: AwayStatusReason,
    targetState: boolean,
    callback?: () => void,
  ) {
    let action = targetState ? 'archive' : 'unarchive';
    try {
      await targetReason.toggleArchived(targetState);
      await this.adminAwayService.fetchAwayStatusReasons(true);

      this.store.pushPayload('away-status-reason', {
        away_status_reason: {
          id: targetReason.id,
          deleted: targetState,
          order: this.args.newAddedItemOrder,
        },
      });
      this.notificationsService.notifyConfirmation(
        this.intl.t(this._translationKey(`notifications.${action}-success`)),
      );
      callback?.();
    } catch (e) {
      if (e instanceof ResponseError) {
        let response = await e.response.json();
        if (response.error_codes) {
          let error_codes = Object.values(response.error_codes);
          if (error_codes.includes('already_archived') || error_codes.includes('not_archived')) {
            this.args.on404Error?.(action);
            return;
          }
        }
      }
      this.notificationsService.notifyError(
        this.intl.t(this._translationKey(`notifications.${action}-failure`)),
      );
    }
  }

  @action async tryRemoving() {
    if (this.isNewRecord) {
      this.args.awayStatusReason.deleteRecord();
    } else {
      // here we need to check for dependencies and ask if the user really wants to archive given
      this.isArchiveModalVisible = true;
    }
  }

  _translationKey(key: string) {
    return `new-settings.helpdesk.assignments.other.away-reasons.${key}`;
  }

  _validationErrorMessage(key: string) {
    let translationKey = this._translationKey(`errors.${key}`);
    return this.intl.exists(translationKey)
      ? this.intl.t(translationKey, { label: this.args.awayStatusReason.label })
      : this.intl.t(this._translationKey('errors.generic-invalid'));
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Helpdesk::AwayStatusReasons::AwayStatusReasonRow': typeof AwayStatusReasonRow;
  }
}
