/* import __COLOCATED_TEMPLATE__ from './configuration.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */

import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type ContentImportSource from 'embercom/models/content-service/content-import-source';
import type ContentImportService from 'embercom/services/content-import-service';
import { task } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { post } from 'embercom/lib/ajax';
import isValidUrl from 'embercom/lib/url-validator';
import { EntityType } from 'embercom/models/data/entity-types';
import { TriggerSources } from 'embercom/objects/content-service/content-imports/constants/trigger-sources';
import { action } from '@ember/object';

interface Args {
  sources: Array<ContentImportSource>;
  resetSearch: () => void | Promise<void>;
}

// nolaneo – disabling for now as we will shorly have actions in this file
// eslint-disable-next-line ember/no-empty-glimmer-component-classes
export default class SourceConfiguration extends Component<Args> {
  @service declare contentImportService: ContentImportService;
  @service declare appService: any;
  @service declare notificationsService: any;
  @service declare intercomEventService: any;

  @tracked url = '';
  @tracked submissionErrorTranslationKey: string | undefined = undefined;

  get isUrlInvalid(): boolean {
    return !!this.url.length && !isValidUrl(this.url);
  }

  get isAddingNewSource(): boolean {
    return taskFor(this.addNewSource).isRunning;
  }

  @task
  *addNewSource(): TaskGenerator<void> {
    this.trackAnalyticsEvent('clicked', 'import_source_button');

    if (!this.isUrlInvalid) {
      let params = {
        app_id: this.appService.app.id,
        url: this.url,
        trigger_source: TriggerSources.AdminInitiated,
        content_type: EntityType.ExternalContent,
        admin_id: this.appService.app.currentAdmin.id,
      };

      try {
        this.submissionErrorTranslationKey = undefined;
        yield post(`/ember/content_service/content_import_runs`, params);
        this.contentImportService.subscribeToContentImportRunStatusUpdates();
        yield this.contentImportService.fetchContentImportSources();
        this.url = '';
      } catch (e) {
        this.submissionErrorTranslationKey =
          this.contentImportService.submissionErrorTranslationKey(e);
      }
    }
  }

  @action trackInputAnalytics() {
    this.trackAnalyticsEvent('inputed', 'url_source_input');
  }

  private trackAnalyticsEvent(action: string, object: string): void {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object,
      context: 'side_drawer',
      place: 'external_content',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::ExternalContent::Sources::Configuration': typeof SourceConfiguration;
  }
}
