/* import __COLOCATED_TEMPLATE__ from './setup-and-go-live-phone.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type { ChannelType } from 'embercom/lib/workflows/fin-workflow-preview';
import type Profile from 'embercom/models/ai-agent/profile';
import type Store from '@ember-data/store';
import type RouterService from '@ember/routing/router-service';
import type finTrialService from 'embercom/services/fin-trial-service';
import { action } from '@ember/object';

interface Arguments {
  hasContentReadyForFin: boolean;
  reloadSetupRuleset: (setupType: ChannelType) => void;
  phoneSetupRuleset: $TSFixMe;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Arguments;
}

export default class SetupAndGoLivePhone extends Component<Signature> {
  @service declare appService: any;
  @service declare store: Store;
  @service declare contentEditorService: $TSFixMe;
  @service declare router: RouterService;
  @service declare finTrialService: finTrialService;

  @tracked ruleset: $TSFixMe;

  constructor(owner: unknown, args: any) {
    super(owner, args);

    if (this.appService.app.finBlockedInTestApp) {
      return;
    }

    let currentRuleset = this.contentEditorService.ruleset;
    this.contentEditorService.unregister(currentRuleset);

    this.updateRuleset(this.args.phoneSetupRuleset);
    if (this.contentEditorService.ruleset?.id !== this.ruleset.id) {
      this.contentEditorService.register({ ruleset: this.ruleset });
    }
  }

  willDestroy(): void {
    super.willDestroy();
  }

  get isFinSetupLive() {
    return this.contentEditorService.ruleset.isLive;
  }

  get canSetFinLive() {
    return (
      !this.isFinSetupLive &&
      this.args.hasContentReadyForFin &&
      this.appService.app.canUseExternalAi &&
      this.behavior.targetChannels.length
    );
  }

  get behavior(): Profile {
    return this.ruleset.rulesetLinks.firstObject.object;
  }

  get parentAppUrl(): string {
    return this.router.urlFor(
      'apps.app.automation.fin-ai-agent.setup',
      this.appService.app.parent_app_id,
    );
  }

  @action
  goToVoiceTesting() {
    this.router.transitionTo({ queryParams: { tab: 'voice-playground' } });
  }

  updateRuleset = (ruleset: $TSFixMe) => {
    let peekRuleset = this.store.peekRecord('matching-system/ruleset', ruleset.id);
    if (!peekRuleset) {
      this.store.pushPayload({ 'matching-system/ruleset': ruleset });
      peekRuleset = this.store.peekRecord('matching-system/ruleset', ruleset.id);
    }
    this.ruleset = peekRuleset;
    this.args.reloadSetupRuleset('phone');
  };
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::Setup::SetupAndGoLivePhone': typeof SetupAndGoLivePhone;
  }
}
