/* import __COLOCATED_TEMPLATE__ from './general.hbs'; */
/* RESPONSIBLE TEAM: team-app-security */

// eslint-disable-next-line @intercom/intercom/max-file-length
import type Store from '@ember-data/store';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { dropTask } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import type IntlService from 'ember-intl/services/intl';
import {
  AUTH_OPTIONS,
  SAML_AUTH_SUB_OPTIONS,
  SAML_PROVISIONING_METHODS,
} from 'embercom/controllers/apps/app/settings/workspace/security';
import { isValidEmail } from 'embercom/lib/email';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import { getSamlVerificationErrorMessage, shouldShowLinkToSamlArticle } from 'embercom/lib/saml';
import { TEAMMATE_APP_URL } from 'embercom/lib/teammate-app';
import type AttachmentSettings from 'embercom/models/attachment-settings';
import { buildDefaultScimEndpointUrl } from 'embercom/models/scim-setting';
import { tracked } from 'tracked-built-ins';
import { isEqual } from 'underscore';
import { hasFeature } from 'embercom/helpers/has-feature';

interface SettingsGeneralArgs {
  attachmentSettings: AttachmentSettings;
  changeTabAndOpenSection: (tab: string, section: string) => void;
  selectedSection: string;
  pingDomains: $TSFixMe;
  settings: $TSFixMe;
  initialProvisioningMethod: string;
  selectedProvisioningMethod: string;
}

interface Signature {
  Element: HTMLDivElement;
  Args: SettingsGeneralArgs;
}

export const AUTH_METHODS = {
  emailPassword: 'email_password',
  twoFa: 'email_password_otp',
  google: 'google_oauth2',
  saml: 'saml',
};

export default class General extends Component<Signature> {
  @service declare appService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare intercomEventService: $TSFixMe;
  @service declare securityAppSettingsService: $TSFixMe;
  @service declare modalService: $TSFixMe;

  @tracked isSaveTaskRunning = false;
  @tracked showEnforceSamlModal = false;
  @tracked showSamlVerificationModal = false;
  @tracked showAllErrors = false;
  @tracked initialProvisioningMethod = this.args.initialProvisioningMethod;
  @tracked selectedProvisioningMethod = this.args.selectedProvisioningMethod;
  @tracked authMethodSelected = this.getStoredSelectedAuthMethod;
  @tracked samlSubOptionSelected = this._getDefaultSubOptionSelected();
  @tracked samlSubMethodEnabled = this.samlSubMethodSelected !== SAML_AUTH_SUB_OPTIONS.none;
  @tracked showEnforceSsoModal = false;
  constructor(owner: unknown, args: SettingsGeneralArgs) {
    super(owner, args);
    window.addEventListener('message', this.samlVerificationCompletedHandler);
  }

  willDestroy(): void {
    window.removeEventListener('message', this.samlVerificationCompletedHandler);
    super.willDestroy();
  }

  get auths() {
    return this.args.settings.authorizedSignInMethods;
  }

  get attachmentSettings() {
    return (
      this.args.attachmentSettings ||
      this.store.peekRecord('attachment-settings', this.appService.app.id)
    );
  }

  get canAttachUploadsInline() {
    return this.attachmentSettings.attachUploadsInline;
  }

  get canSaveAttachmentSettings() {
    let modelCanBeSaved =
      !this.attachmentSettings.allowedAttachmentFiletypesEnabled ||
      this.attachmentSettings.allowedAttachmentFiletypesAgreedToRisk;
    let taskIdle = !taskFor(this.saveTask).isRunning;

    return (
      taskIdle &&
      this.attachmentSettings.get('hasDirtyAttributes') &&
      modelCanBeSaved &&
      this.attachmentSettings.isFiletypeListValid
    );
  }

  get webIdentityVerified() {
    return this.appService.app.identityVerified;
  }

  get iosSdkApp() {
    return this.appService.app.iosSdkApps.firstObject;
  }

  get iosIdentityVerified() {
    return this.iosSdkApp.identityVerified;
  }

  get androidSdkApp() {
    return this.appService.app.androidSdkApps.firstObject;
  }

  get androidIdentityVerified() {
    return this.androidSdkApp.identityVerified;
  }

  get noDomainsHaveErrors() {
    return this.args.pingDomains.isEvery('errorCode', null);
  }

  get samlAccount() {
    return this.args.settings.samlAccount;
  }

  get twoFAAuth() {
    return [AUTH_METHODS.twoFa, AUTH_METHODS.google];
  }

  get twoFASelected() {
    return this.twoFAAllowed && !this.emailPassAllowed && !this.samlAllowed;
  }

  set twoFASelected(value) {
    if (value) {
      this.updateAuthWith(this.twoFAAuth);
    } else {
      this.updateAuthWith(this.allAuth);
    }
  }

  get googleAuth() {
    return [AUTH_METHODS.google];
  }

  get allAuth() {
    return [AUTH_METHODS.emailPassword, AUTH_METHODS.twoFa, AUTH_METHODS.google];
  }

  get samlAuth() {
    return [AUTH_METHODS.saml];
  }

  get canEnforceSSO() {
    return this.args.settings.canEnableEnforceSso;
  }

  get canEnforceSaml() {
    return this.args.settings.canEnforceSaml;
  }

  get shouldEnableUnauthenticatedUserMerge() {
    return this.webIdentityVerified || this.iosIdentityVerified || this.androidIdentityVerified;
  }

  get isInvalidSecurityEmail() {
    if (
      this.args.settings.securityContactEmail === '' ||
      this.args.settings.securityContactEmail === null
    ) {
      return false;
    }
    let result = !isValidEmail(this.args.settings.securityContactEmail);
    return result;
  }

  get isSaveDataSecurityDisabled() {
    return this.isInvalidSecurityEmail || this.isDataSecuritySettingsSaving || !this.isDirty;
  }

  get isScimSettingsDirty() {
    return this.scimEnabled && this.args.settings.scimSettings.hasDirtyAttributes;
  }

  get hasChangedProvisioningMethod() {
    return this.initialProvisioningMethod !== this.selectedProvisioningMethod;
  }

  get scimEnabled() {
    return this.selectedProvisioningMethod === SAML_PROVISIONING_METHODS.scim;
  }

  get isDirty() {
    return (
      this.args.settings.hasDirtyAttributes ||
      this.samlAccount?.hasDirtyAttributes ||
      this.isScimSettingsDirty ||
      this.hasChangedProvisioningMethod
    );
  }

  get samlAllowed() {
    return this.auths.includes(AUTH_METHODS.saml);
  }

  get hasChangedSamlSettings() {
    let authChanged = 'authorizedSignInMethods' in this.args.settings.changedAttributes();
    return this.samlAllowed && (authChanged || this.samlAccount.hasDirtyAttributes);
  }

  get isSamlVerifyInProgress(): boolean {
    return taskFor(this.startSamlVerification).isRunning;
  }

  get isDataSecuritySettingsSaving(): boolean {
    return taskFor(this.saveDataSecurityChanges).isRunning;
  }

  get canEnableSaml() {
    return this.appService.app.canUseSamlSso;
  }

  get twoFAAllowed() {
    return this.auths.includes(AUTH_METHODS.twoFa);
  }

  get googleSsoAllowed() {
    return this.auths.includes(AUTH_METHODS.google);
  }

  get emailPassAllowed() {
    return this.auths.includes(AUTH_METHODS.emailPassword);
  }

  get googleAuthSelected() {
    return (
      !this.twoFAAllowed && !this.emailPassAllowed && this.googleSsoAllowed && !this.samlAllowed
    );
  }

  get enablingGoogleAuthOnly() {
    let changedAttributes = this.args.settings.changedAttributes();
    return this.googleAuthSelected && changedAttributes.authorizedSignInMethods;
  }

  get authOptions() {
    return AUTH_OPTIONS;
  }

  get getStoredSelectedAuthMethod() {
    if (this.googleAuthSelected) {
      return AUTH_OPTIONS.google;
    } else if (this.samlAllowed) {
      return AUTH_OPTIONS.saml;
    }
    return AUTH_OPTIONS.all;
  }

  get samlSubMethodSelected() {
    return this.getSamlSubMethod(this.auths);
  }

  get allAuthSelected() {
    return this.twoFAAllowed && this.emailPassAllowed && !this.samlAllowed && this.googleSsoAllowed;
  }

  get canLogSamlEvents() {
    return this.appService.app.canLogSamlEventData;
  }

  get shouldShowSecurityContactWarningIcon() {
    if (
      this.args.settings.hasDirtyAttributes &&
      this.args.settings.changedAttributes().securityContactEmail
    ) {
      return this.args.settings.changedAttributes().securityContactEmail[0] === '';
    }
    return this.args.settings.securityContactEmail === '';
  }

  get subOptionItems() {
    return [
      {
        text: this.intl.t('settings.security.saml-settings.default-label'),
        value: SAML_AUTH_SUB_OPTIONS.all,
      },
      {
        text: this.intl.t('settings.security.saml-settings.two-factor'),
        value: SAML_AUTH_SUB_OPTIONS.googleAnd2fa,
      },
      {
        text: this.intl.t('settings.security.saml-settings.google-sign-on'),
        value: SAML_AUTH_SUB_OPTIONS.google,
      },
    ];
  }

  _getDefaultSubOptionSelected() {
    return this.samlSubMethodSelected === SAML_AUTH_SUB_OPTIONS.none
      ? SAML_AUTH_SUB_OPTIONS.all
      : this.samlSubMethodSelected;
  }

  getSamlSubMethod(auths: string[]): string {
    if (auths.includes(AUTH_METHODS.emailPassword)) {
      return SAML_AUTH_SUB_OPTIONS.all;
    } else if (auths.includes(AUTH_METHODS.twoFa)) {
      return SAML_AUTH_SUB_OPTIONS.googleAnd2fa;
    } else if (auths.includes(AUTH_METHODS.google)) {
      return SAML_AUTH_SUB_OPTIONS.google;
    }
    return SAML_AUTH_SUB_OPTIONS.none;
  }

  updateAuthorizedSignInMethods(authorizedMethods: string[], samlSubMethod: string) {
    if (isEqual(authorizedMethods, this.samlAuth)) {
      switch (samlSubMethod) {
        case SAML_AUTH_SUB_OPTIONS.all:
          authorizedMethods = authorizedMethods.concat(this.allAuth);
          break;
        case SAML_AUTH_SUB_OPTIONS.googleAnd2fa:
          authorizedMethods = authorizedMethods.concat(this.twoFAAuth);
          break;
        case SAML_AUTH_SUB_OPTIONS.google:
          authorizedMethods = authorizedMethods.concat(this.googleAuth);
      }
    }

    this.args.settings.set('authorizedSignInMethods', authorizedMethods);
  }

  @action
  updateSamlSubOption(selectedOption: string) {
    this.samlSubOptionSelected = selectedOption;
    this.samlSubMethodEnabled = true;
    this.updateSamlSubMethod(this.samlSubOptionSelected);
  }

  @action
  updateSamlSubMethodEnabled(event: Event) {
    if (event.target instanceof HTMLInputElement && event.target.checked) {
      this.updateSamlSubMethod(this.samlSubOptionSelected);
    } else {
      this.updateSamlSubMethod(SAML_AUTH_SUB_OPTIONS.none);
    }
  }

  @action
  samlVerificationCompletedHandler(event: MessageEvent) {
    let expectedEventType = 'saml-verification-completed';
    let expectedOrigin = window.location.origin;
    let eventData;
    try {
      eventData = JSON.parse(event.data);
    } catch {
      eventData = null;
    }

    if (this.canLogSamlEvents) {
      console.info(event.data);
    }

    if (!eventData || eventData.type !== expectedEventType || event.origin !== expectedOrigin) {
      return;
    }

    if (eventData.success === true) {
      this.intercomEventService?.trackAnalyticsEvent({
        action: 'verified',
        object: 'verify_saml',
      });
      taskFor(this.saveDataSecurityChanges).perform(true);
    } else {
      let { error_code: errorCode, message } = eventData;
      let errorMessage = getSamlVerificationErrorMessage(errorCode, message);
      let showLearnMoreButton = shouldShowLinkToSamlArticle(errorCode);

      if (showLearnMoreButton) {
        this.notificationsService.notifyErrorWithButton(
          errorMessage,
          {
            label: this.intl.t('new-settings.workspace.security.learn-more'),
            action: () =>
              safeWindowOpen(
                'https://www.intercom.com/help/en/articles/3974587-Integrate-with-an-identity-provider-and-log-in-with-SAML-SSO',
              ),
          },
          0,
        );
      } else {
        this.notificationsService.notifyError(errorMessage, 0);
      }
    }
  }

  @action
  updateAuthWith(methods: string[]) {
    let samlSubMethod = SAML_AUTH_SUB_OPTIONS.none;
    if (isEqual(methods, this.samlAuth)) {
      samlSubMethod = this.getSamlSubMethod(this.appService.app.authorized_sign_in_methods);

      if (!this.samlAccount) {
        let { id } = this.appService.app;
        this.args.settings.set(
          'samlAccount',
          this.store.createRecord('saml-account', { id, name: id }),
        );
      }
    }

    this.updateAuthorizedSignInMethods(methods, samlSubMethod);
  }

  @action
  enforceSSO() {
    this.setAuths('googleAuth');
  }

  @action
  setAuths(auth: any) {
    let allAuth: Record<string, string[]> = {
      googleAuth: this.googleAuth,
      allAuth: this.allAuth,
      samlAuth: this.samlAuth,
    };
    this.updateAuthorizedSignInMethods(allAuth[auth], this.samlSubMethodSelected);
  }

  @action
  openGoogleSsoWarningModal() {
    this.modalService.openModal('settings/modals/enforce-sso-warning', undefined, {
      enforceSSO: this.enforceSSO,
      canEnforceSSO: this.canEnforceSSO,
    });
  }

  @action
  updateSamlSubMethod(method: string) {
    this.updateAuthorizedSignInMethods(this.samlAuth, method);
  }

  @action
  updateProvisioningMethod(selectedProvisioningMethod: string) {
    this.selectedProvisioningMethod = selectedProvisioningMethod;

    if (this.scimEnabled && !this.args.settings.scimSettings) {
      this.args.settings.set(
        'scimSettings',
        this.store.createRecord('scim-setting', {
          immuneAdminIds: [],
          scimEndpointUrl: buildDefaultScimEndpointUrl(this.appService.app.id),
        }),
      );
    }
  }

  @action
  onOpenSectionChange(sectionId: string) {
    this.args.changeTabAndOpenSection('general', sectionId);
    let accordion = window.document.querySelector(`[data-intercom-target="${sectionId}"]`);
    if (sectionId && accordion) {
      setTimeout(() => accordion?.scrollIntoView({ behavior: 'smooth' }), 300);
    }
  }

  @action
  toggleUserAttachmentSettingsEnabled() {
    let newValue = !this.attachmentSettings.userConversationAttachmentsEnabled;
    this.attachmentSettings.set('userConversationAttachmentsEnabled', newValue);
    this.attachmentSettings.set('userConversationCameraEnabled', newValue);
    this.attachmentSettings.set('userConversationMediaEnabled', newValue);
    this.attachmentSettings.set('userConversationGifsEnabled', newValue);
    this.attachmentSettings.set('userConversationFilesEnabled', newValue);

    if (!newValue) {
      this.attachmentSettings.set('allowedAttachmentFiletypesEnabled', false);
      this.attachmentSettings.set('allowedAttachmentFiletypesAgreedToRisk', false);
    }
  }

  @action
  save() {
    if (hasFeature('new-auth-method-ui', this.appService) && this.enablingGoogleAuthOnly) {
      this.showEnforceSsoModal = true;
    } else {
      taskFor(this.saveDataSecurityChanges).perform();
    }
  }

  @dropTask
  *saveDataSecurityChanges(runJustSave = false): any {
    if (this.hasChangedSamlSettings && !runJustSave) {
      this.args.settings.set('samlAccount', this.samlAccount);

      if (!this.scimEnabled) {
        this.args.settings.set('scimSettings', null);
      }

      if (this.auths === this.samlAuth && !this.canEnforceSaml) {
        this.showEnforceSamlModal = true;
      } else if (this.samlAccount.isValid) {
        this.showSamlVerificationModal = true;
      } else {
        this.showAllErrors = true;
        this.notificationsService.notifyError(
          this.intl.t('apps.app.settings.security.security-settings-not-saved'),
        );
      }
    } else {
      if (!runJustSave) {
        this.args.settings.set('samlAccount', null);
        if (!(this.samlAllowed && this.scimEnabled)) {
          this.args.settings.set('scimSettings', null);
        }
      }
      try {
        yield this.args.settings.save();
        this.securityAppSettingsService.set(
          'settings.notificationEmailsWithContentEnabled',
          this.args.settings.notificationEmailsWithContentEnabled,
        );
        this.showSamlVerificationModal = false;
        this.initialProvisioningMethod = this.selectedProvisioningMethod;
        this.notificationsService.notifyConfirmation(
          this.intl.t('apps.app.settings.security.security-settings-updated'),
        );
      } catch (err) {
        let notificationText;
        if (err.jqXHR?.responseJSON?.errors[0]) {
          notificationText = err.jqXHR.responseJSON.errors[0];
        } else {
          notificationText = this.intl.t('apps.app.settings.security.settings-not-updated');
        }
        this.notificationsService.notifyError(notificationText);
      }
    }
  }

  @dropTask
  *startSamlVerification(): any {
    this.notificationsService.clear();
    try {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'verify_saml',
        jit_enabled: this.samlAccount.jitEnabled,
      });
      yield this.samlAccount.save();
      safeWindowOpen(`${TEAMMATE_APP_URL}/saml/${this.samlAccount.name}/verify`);
    } catch (error) {
      this.notificationsService.notifyError(
        this.intl.t('apps.app.settings.security.error-starting'),
      );
    }
  }

  @dropTask
  *saveTask(): any {
    try {
      this.isSaveTaskRunning = true;
      yield this.attachmentSettings.save();

      this.notificationsService.notifyConfirmation(
        this.intl.t('apps.app.settings.attachment-settings.save-successful'),
      );
      this.isSaveTaskRunning = false;
    } catch (error) {
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('apps.app.settings.attachment-settings.trusted-domains-error-message'),
      });
    }
  }

  redirectToSignOut() {
    window.location.href = '/admins/sign_out';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Workspace::Security::General': typeof General;
    'new-settings/workspace/security/general': typeof General;
  }
}
