/* import __COLOCATED_TEMPLATE__ from './email-dropdown-empty-list.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import templateOnlyComponent from '@ember/component/template-only';

export interface Args {}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

const EmailDropdownEmptyList = templateOnlyComponent<Signature>();
export default EmailDropdownEmptyList;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    EmailDropdownEmptyList: typeof EmailDropdownEmptyList;
    'email-dropdown-empty-list': typeof EmailDropdownEmptyList;
  }
}
