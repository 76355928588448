/* import __COLOCATED_TEMPLATE__ from './setup-domain-manually.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import type IntlService from 'ember-intl/services/intl';
import { type CtaType } from 'embercom/components/new-settings/channels/email/domains-and-addresses/step';
import type { Domain, DomainEmailAddress } from 'embercom/services/domain-service';
interface Args {
  emailAddress: DomainEmailAddress | undefined;
  changeStep: (step: string | null, place: string) => void;
  domain: Domain | null;
  reloadModels: () => TaskGenerator<void>;
  previousStepForDomainSetup: string | null;
}

interface Signature {
  Args: Args;
}

interface SetupDomainStep {
  name: string;
  title: string;
  description: string;
  stepCompleted: boolean;
  ctaType?: CtaType;
  ctaLabel?: string;
  ctaLink: string;
}

export default class SetupDomainManually extends Component<Signature> {
  @service declare intercomEventService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare notificationsService: $TSFixMe;
  @service declare appService: $TSFixMe;
  @tracked isVerifying = false;
  @tracked showBanner = false;

  place = 'setup-domain-manually';

  get app() {
    return this.appService.app;
  }

  @action
  async dismissBanner() {
    this.showBanner = false;
  }

  get setupDomainSteps(): SetupDomainStep[] {
    return [
      {
        name: 'find-your-domain-records',
        title: this.intl.t(
          'new-settings.channels.email.connect-email.email-setup.setup-domain-manually-step.find-your-domain-records.title',
        ),
        description: this.intl.t(
          'new-settings.channels.email.connect-email.email-setup.setup-domain-manually-step.find-your-domain-records.description',
        ),
        stepCompleted: false,
        ctaLink:
          'https://www.intercom.com/help/en/articles/182-sending-email-from-your-own-address#verify-your-domain',
      },
      {
        name: 'copy-intercom-domain-records',
        title: this.intl.t(
          'new-settings.channels.email.connect-email.email-setup.setup-domain-manually-step.copy-intercom-domain-records.title',
        ),
        description: this.intl.t(
          'new-settings.channels.email.connect-email.email-setup.setup-domain-manually-step.copy-intercom-domain-records.description',
        ),
        stepCompleted: false,
        ctaType: undefined,
        ctaLabel: undefined,
        ctaLink: '',
      },
    ];
  }

  get domainSettingsInstructionsText() {
    let fullDomain = this.args.domain?.dkimSettings.domainForCustomerDnsRecord;
    let cnameHostValue = fullDomain.split('.').slice(0, -2).join('.');

    fullDomain = this.args.domain?.bounceSettings.host;
    let cnameHostValue2 = fullDomain.split('.').slice(0, -2).join('.');
    return [
      this.intl.t('new-settings.channels.email.addresses.domain-sidebar.instructions-text', {
        domainName: this.args.domain?.bounceSettings.rootDomain,
        htmlSafe: true,
      }),
      this.intl.t('new-settings.channels.email.addresses.domain-sidebar.cname-host-value-record', {
        host: cnameHostValue,
        value: `${this.args.domain?.dkimSettings.domainForIntercomDnsRecord}.`,
        htmlSafe: true,
      }),
      this.intl.t('new-settings.channels.email.addresses.domain-sidebar.cname-host-value-record', {
        host: cnameHostValue2,
        value: `${this.args.domain?.bounceSettings.value}.`,
        htmlSafe: true,
      }),
      this.intl.t('new-settings.channels.email.addresses.domain-sidebar.txt-host-value-record', {
        host: '_dmarc',
        value: `v=DMARC1; p=none`,
        htmlSafe: false,
      }),
      this.intl.t('new-settings.channels.email.addresses.domain-sidebar.can-you-help'),
    ].join('\n');
  }

  @action
  async verifyDomain() {
    if (!this.args.domain) {
      return;
    }
    this.trackEvent({ action: 'clicked', object: 'check_domain_authentication' });
    try {
      this.isVerifying = true;
      await this.args.domain.dkimSettings.verify();

      this.trackEvent({ action: 'success', object: 'check_domain_authentication' });
      this.notificationsService.notifyConfirmation(
        this.intl.t(
          'new-settings.channels.email.addresses.domain-sidebar.domain-verification-success',
        ),
      );
      if (this.args.emailAddress && this.args.previousStepForDomainSetup === 'overview') {
        this.args.changeStep('overview', this.place);
      } else {
        this.args.changeStep(null, this.place);
      }
      taskFor(this.args.reloadModels).perform();
      this.app.reload(); // reload the app in case the domain authentication caused a change on the reply email address
    } catch (error) {
      this.showBanner = true;
      this.trackEvent({ action: 'failed', object: 'check_domain_authentication' });
    } finally {
      this.isVerifying = false;
    }
  }

  @action
  copyAllRecordsTrackEvent() {
    this.trackEvent({ action: 'copied', object: 'all_intercom_domain_records' });
  }

  trackEvent({ action, object }: { action: string; object: string }) {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object,
      place: this.place,
      context: 'email-setup-sidebar',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::DomainsAndAddresses::SetupDomainManually': typeof SetupDomainManually;
    'new-settings/channels/email/domains-and-addresses/setup-domain-manually': typeof SetupDomainManually;
  }
}
