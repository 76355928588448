/* import __COLOCATED_TEMPLATE__ from './data-security-section.hbs'; */
/* RESPONSIBLE TEAM: team-app-security */
import templateOnlyComponent from '@ember/component/template-only';

interface DataSecuritySectionArgs {
  settings: any;
  onSave: () => void;
  isSaveDisabled: boolean;
  isSaving: boolean;
  shouldEnableUnauthenticatedUserMerge: boolean;
}

interface Signature {
  Element: HTMLDivElement;
  Args: DataSecuritySectionArgs;
}

const DataSecuritySectionComponent = templateOnlyComponent<Signature>();
export default DataSecuritySectionComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Workspace::Security::DataSecuritySection': typeof DataSecuritySectionComponent;
    'new-settings/workspace/security/data-security-section': typeof DataSecuritySectionComponent;
  }
}
