/* import __COLOCATED_TEMPLATE__ from './which-channels.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { action } from '@ember/object';
// @ts-ignore
import { inject as service } from '@ember/service';
import { CHANNEL_EMAIL, CHANNEL_PHONE_CALL } from 'embercom/lib/operator/custom-bots/constants';
import type { FinSetupBehaviorSaveParams } from 'embercom/lib/operator/fin/types';
import type Store from '@ember-data/store';
import type IntlService from 'embercom/services/intl';
import type Profile from 'embercom/models/ai-agent/profile';
import type { ChannelType } from 'embercom/lib/workflows/fin-workflow-preview';

interface Args {
  accordion: $TSFixMe;
  ruleset: $TSFixMe;
  partialSave: (params: FinSetupBehaviorSaveParams) => void;
  channelType: ChannelType;
}

export default class WhichChannels extends Component<Args> {
  @service declare attributeService: $TSFixMe;
  @service declare appService: $TSFixMe;
  @service declare store: Store;
  @service declare intl: IntlService;

  ignoredAttributeTypesForPreview = ['message', 'conversation', 'office_hours', 'team_capacity'];

  constructor(owner: unknown, args: Args) {
    super(owner, args);
  }

  get shouldSeeFinProbabilitySummaryBlock() {
    return (
      this.appService.app.canUseFeature('ai-agent-percentage-based-rollout') &&
      this.behavior.matchPercentage &&
      this.behavior.matchPercentage < 100
    );
  }

  get ruleset() {
    return this.args.ruleset;
  }

  get rulesetHasPredicates() {
    if (!this.ruleset.predicateGroup.predicates) {
      return false;
    }

    return !!this.ruleset.predicateGroup.predicates.length;
  }

  get rulesetPredicatesCount() {
    if (!this.ruleset.predicateGroup.predicates) {
      return 0;
    }

    return (
      this.ruleset.predicateGroup.predicates?.firstObject?.predicates?.length ||
      this.ruleset.predicateGroup.predicates.length
    );
  }

  get excludedChannels() {
    return [CHANNEL_EMAIL, CHANNEL_PHONE_CALL];
  }

  get behavior(): Profile {
    return this.ruleset.rulesetLinks.firstObject.object;
  }

  get targetChannels(): string {
    let targetChannels = this.behavior.targetChannels.map((channel: string) => {
      return this.intl.t('operator.workflows.channels.type', { channel });
    });

    if (targetChannels.length > 3) {
      return this.intl.t('operator.workflows.channels.multiple-channels', {
        channelOne: targetChannels[0],
        remainingChannelCount: targetChannels.length - 1,
      });
    }
    return this.intl.formatList(targetChannels, {
      type: 'conjunction',
    });
  }

  @action
  async updateAudienceRuleAndTargetChannels() {
    let target_channels = this.behavior.targetChannels;

    let params: FinSetupBehaviorSaveParams = {
      audience_rules: {
        predicate_group: this.ruleset.predicateGroup,
        client_predicate_group: this.ruleset.clientPredicateGroup,
        role_predicate_group: this.ruleset.rolePredicateGroup,
        target_channels,
      },
    };

    if (
      this.appService.app.canUseFeature('ai-agent-percentage-based-rollout') &&
      this.behavior.matchPercentage !== undefined
    ) {
      params = {
        ...params,
        match_percentage: this.behavior.matchPercentage,
      };
    }

    this.args.partialSave(params);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::Setup::Sections::WhichChannels': typeof WhichChannels;
  }
}
