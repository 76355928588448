/* import __COLOCATED_TEMPLATE__ from './workspace-phone-number.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import { action } from '@ember/object';
import type IntlService from 'ember-intl/services/intl';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    accordion: any;
    ruleset: $TSFixMe;
    partialSave: () => Promise<void>;
  };
}

interface ListOption {
  listOptionId: string;
  label: string;
  archived: boolean;
}

const WORKSPACE_PHONE_NUMBER_ATTRIBUTE = 'conversation.custom_attribute.workspace_phone_number';

export default class WorkspacePhoneNumber extends Component<Signature> {
  @service declare store: Store;
  @service declare intl: IntlService;
  @tracked phoneNumbers: Array<{ value: string; text: string }> = [];
  @tracked workspacePhoneNumber: string | null = null;

  constructor(owner: unknown, args: Signature['Args']) {
    super(owner, args);
    this.loadPhoneNumbers();
  }

  get savingDisabled() {
    return this.hasNoPhoneNumbers || !this.workspacePhoneNumber || this.args.ruleset.isSaving;
  }

  get errorMessage() {
    if (this.hasNoPhoneNumbers) {
      return this.intl.t(
        'operator.fin.setup.tabs.setup-and-go-live.phone.workspace-phone-number.error-no-phone-numbers',
      );
    }

    if (!this.workspacePhoneNumber) {
      return this.intl.t(
        'operator.fin.setup.tabs.setup-and-go-live.phone.workspace-phone-number.error-no-phone-number-selected',
      );
    }

    return undefined;
  }

  get predicateGroup() {
    return this.args.ruleset.predicateGroup;
  }

  get predicates() {
    return this.predicateGroup.basePredicates;
  }

  get workspacePhoneNumberPredicate() {
    return this.predicates.find((p: any) => p.attribute === WORKSPACE_PHONE_NUMBER_ATTRIBUTE);
  }

  get savedValue() {
    return this.workspacePhoneNumberPredicate?.value;
  }

  get workspacePhoneNumberLabel() {
    let label = this.phoneNumbers.find(
      (phoneNumber) => phoneNumber.value === this.savedValue,
    )?.text;

    if (!label) {
      return this.savedValue;
    }

    return this.intl.t(
      'operator.fin.setup.tabs.setup-and-go-live.phone.workspace-phone-number.phone-number-label',
      {
        label,
        phoneNumber: this.savedValue,
      },
    );
  }

  get hasNoPhoneNumbers() {
    return !this.phoneNumbers?.length;
  }

  async loadPhoneNumbers() {
    let phoneDescriptor = this.store.peekRecord(
      'conversation-attributes/descriptor',
      'workspace_phone_number',
    );

    if (phoneDescriptor) {
      this.phoneNumbers = phoneDescriptor.listOptions
        .filter((option: ListOption) => !option.archived)
        .map((option: ListOption) => ({
          value: option.listOptionId,
          text: option.label,
        }));
    }

    this.workspacePhoneNumber = this.savedValue;
  }

  @action
  resetPredicate() {
    this.workspacePhoneNumber = this.savedValue;
  }

  @action
  updatePhoneNumber(value: string) {
    this.workspacePhoneNumber = value;
  }

  @action
  async updateWorkspacePhoneNumber() {
    let workspacePhoneNumberPredicate = this.workspacePhoneNumberPredicate;

    if (workspacePhoneNumberPredicate) {
      workspacePhoneNumberPredicate.changeValue(this.workspacePhoneNumber);
    } else {
      workspacePhoneNumberPredicate = {
        attribute: WORKSPACE_PHONE_NUMBER_ATTRIBUTE,
        comparison: 'eq',
        type: 'conversation_attribute_list',
        value: this.workspacePhoneNumber,
      };

      this.predicateGroup.add(workspacePhoneNumberPredicate);
    }

    await this.args.partialSave();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::Setup::Sections::WhoWillTalkToFin::WorkspacePhoneNumber': typeof WorkspacePhoneNumber;
  }
}
