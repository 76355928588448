/* import __COLOCATED_TEMPLATE__ from './edit-email-address.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
// @ts-ignore
import type Store from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';
import { dropTask } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import type Brand from 'embercom/models/brand';
import type BrandService from 'embercom/services/brand-service';
import { type DomainEmailAddress } from 'embercom/services/domain-service';
import type SenderEmailAddressSettings from 'embercom/models/sender-email-address-settings';
import type SenderEmailVerificationService from 'embercom/services/sender-email-verification-service';

interface Args {
  emailAddress: DomainEmailAddress | undefined;
  changeStep: (step: string | null, place: string) => void;
  brands: Brand[];
}

interface Signature {
  Args: Args;
}

export default class EditEmailAddressComponent extends Component<Signature> {
  @service declare intercomEventService: any;
  @service declare appService: any;
  @service declare notificationsService: any;
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare brandService: BrandService;
  @service declare senderEmailVerificationService: SenderEmailVerificationService;
  @tracked emailAddress: DomainEmailAddress | undefined;
  @tracked showConfirmUntoggleSendingModal = false;
  @tracked selectedBrandId: string;
  @tracked initialBrandId: number | null | undefined;

  place = 'edit-email-address';
  // eslint-disable-next-line @intercom/intercom/no-bare-strings
  unverifiedEmailStatus = 'Unverified email';

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.emailAddress = args.emailAddress;

    this.initialBrandId = args.emailAddress?.brandId;
    this.selectedBrandId = this.initialBrandId?.toString() ?? '0';
  }

  willDestroy() {
    super.willDestroy();
    //if the user has clicked "Add" without entering a name
    this.senderEmailAddresses.forEach((sea: any) => {
      if (sea.name === '') {
        sea.deleteRecord();
      }
    });
  }

  get emailVerified() {
    return this.emailAddress?.verified ?? false;
  }

  get emailForwardingEnabled() {
    return this.args.emailAddress?.forwardingEnabled || false;
  }

  get senderEmailAddressesSettings() {
    return this.emailAddress?.senderEmailAddressSettings;
  }

  get sendingEnabled() {
    return this.emailVerified && this.senderEmailAddresses.toArray().length > 0;
  }

  get senderEmailAddresses() {
    if (this.senderEmailAddressesSettings) {
      return this.senderEmailAddressesSettings.senderEmailAddresses;
    }
    return [];
  }

  get showBrands() {
    return this.brandService.isAppOnRightMultibrandPlan;
  }

  get selectedBrandName() {
    let brand = this.args.brands.find((brand) => brand.id === this.selectedBrandId);
    return brand?.name ?? this.appService.app.name;
  }

  @action
  addNewName() {
    this.trackEvent('clicked', 'add_display_name');
    this._addNewAddress('');
  }

  _addNewAddress(name: string) {
    let newRecord = this.store.createRecord('custom-email-address', {
      name,
      status: this.unverifiedEmailStatus,
    });
    this.senderEmailAddressesSettings?.senderEmailAddresses.pushObject(newRecord);
  }

  @action
  removeName(index: number) {
    this.trackEvent('clicked', 'remove_display_name');
    let addressToRemove = this.senderEmailAddresses.objectAt(index);

    if (addressToRemove) {
      addressToRemove.deleteRecord();
      taskFor(this.updateTask).perform();
    }
  }

  @action
  updateAndSaveName(index: number) {
    this.trackEvent('input_changed', 'update_display_name');

    if (this.senderEmailAddresses.toArray()[index].name === '') {
      this._handleEmptyName();
      return;
    }

    if (this._addressesHaveUnsavedChanges()) {
      taskFor(this.updateTask).perform();
    }
  }

  _handleEmptyName() {
    this.trackEvent('error', 'empty_display_name');
    this._revertAddressesChanges();
    this.notificationsService.notifyError(
      this.intl.t(
        'new-settings.channels.email.connect-email.email-setup.edit-address.add-display-names.error-empty-name',
      ),
    );
  }

  @action
  toggleSending() {
    this.trackEvent('clicked', `toggle_sending_${this.sendingEnabled ? 'off' : 'on'}`);
    if (this.sendingEnabled) {
      this.showConfirmUntoggleSendingModal = true;
    } else {
      this.saveSendingOn();
    }
  }

  @action
  saveSendingOn() {
    this._addNewAddress(this.selectedBrandName);
    taskFor(this.updateTask).perform();
  }

  @action
  saveSendingOff() {
    this.trackEvent('confirmed', 'toggle_sending_off');

    this.showConfirmUntoggleSendingModal = false;

    this.senderEmailAddresses.forEach((sea: any) => {
      sea.deleteRecord();
    });
    taskFor(this.updateTask).perform();
  }

  @action
  closeConfirmUntoggleSendingModal() {
    this.showConfirmUntoggleSendingModal = false;
    this.trackEvent('cancelled', `toggle_sending_${this.sendingEnabled ? 'off' : 'on'}`);
  }

  @action
  onBrandSelect(brandId: string) {
    this.selectedBrandId = brandId;
    if (this.senderEmailAddressesSettings) {
      this.senderEmailAddressesSettings.brandId =
        this.selectedBrandId === '0' ? null : this.selectedBrandId;
      taskFor(this.updateTask).perform();
    }
  }

  @action
  resetBrandSelection() {
    if (this.senderEmailAddressesSettings) {
      this.senderEmailAddressesSettings.brandId = this.initialBrandId?.toString() ?? null;
    }
    this.selectedBrandId = this.initialBrandId?.toString() ?? '0';
  }

  @action
  async verifyEmail() {
    await this.senderEmailVerificationService.resendVerificationEmail({
      senderEmailAddressSettingsId: this.emailAddress?.id ?? '',
      appId: this.appService.app.id,
      adminId: this.appService.app.currentAdmin.id,
    });
    this.trackEvent('clicked', 'send_verification_email');
  }

  @dropTask
  *updateTask(): TaskGenerator<void> {
    if (this.senderEmailAddressesSettings) {
      try {
        yield this.senderEmailAddressesSettings.save().then(() => {
          this.trackEvent('success', 'update_sending_addresses');
          this._pruneAddresses(this.senderEmailAddressesSettings);

          this.notificationsService.notifyConfirmation(
            this.intl.t('new-settings.channels.email.addresses.sidebar.updated-email-successfully'),
          );
        });
      } catch (error) {
        this.trackEvent('failed', 'update_sending_addresses');
        this.resetBrandSelection();
        this._revertAddressesChanges();
        this._notifyUpdateError(error);
      }
    }
  }

  _addressesHaveUnsavedChanges() {
    return this.senderEmailAddresses.toArray().some((sea: any) => sea.hasDirtyAttributes);
  }

  _revertAddressesChanges() {
    this.senderEmailAddresses.forEach((sea: any) => {
      sea.rollbackAttributes();
    });
  }

  _notifyUpdateError(error: any) {
    console.error('Error updating the record:', error);
    let message =
      this._getErrorMessage(error) ||
      this.intl.t('new-settings.channels.email.addresses.sidebar.failed-to-update-address');
    this.notificationsService.notifyResponseError(error, {
      default: message,
    });
  }

  _getErrorMessage(error: any) {
    try {
      if (error.jqXHR?.responseJSON?.errors?.length) {
        return error.jqXHR.responseJSON.errors[0].message;
      }
    } catch {
      return null;
    }
  }

  _pruneAddresses(settings: SenderEmailAddressSettings | undefined) {
    if (!settings) {
      return;
    }
    // seems to be a bug in our version of ember-data where we end up with both the
    // saved & unsaved records here, need to look at whether we can fix this with a local ID?
    // https://github.com/emberjs/data/issues/7354
    let unsaved = settings.senderEmailAddresses.filter((address: any) => address.isNew);
    settings.senderEmailAddresses.removeObjects(unsaved);
  }

  @action
  trackEvent(action: string, object: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object,
      place: this.place,
      context: 'email-setup-sidebar',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::DomainsAndAddresses::EditEmailAddress': typeof EditEmailAddressComponent;
  }
}
