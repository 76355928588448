/* import __COLOCATED_TEMPLATE__ from './permission-card.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

interface Args {
  canAccess: boolean;
  title: string;
  description: string;
  permission: string;
}

interface Signature {
  Args: Args;
  Element: Element;
  Blocks: {
    default: [];
  };
}

export default class PermissionCard extends Component<Signature> {
  @service declare permissionsService: any;
  @service declare appService: $TSFixMe;

  @action
  clickPermissionCard() {
    this.permissionsService.loadAllAdminsAndShowPermissionRequestModal(this.args.permission);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Common::PermissionCard': typeof PermissionCard;
    'new-settings/common/permission-card': typeof PermissionCard;
  }
}
