/* import __COLOCATED_TEMPLATE__ from './tags.hbs'; */
/* RESPONSIBLE TEAM: team-data-foundations */
import { action } from '@ember/object';
import { next } from '@ember/runloop';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type IntlService from 'ember-intl/services/intl';

interface Signature {
  Args: any;
  Element: never;
}

export default class Tags extends Component<Signature> {
  @service declare intl: IntlService;

  @service declare appService: any;

  get app() {
    return this.appService.app;
  }

  get tags() {
    return this.app.tags;
  }

  get admin() {
    return this.app.currentAdmin;
  }

  get tagIds() {
    return this.tags.map((tag: $TSFixMe) => tag.get('id'));
  }

  get visibleTags() {
    return this.admin.visible_tag_ids.map((id: $TSFixMe) => this.tags.findBy('id', id)).compact();
  }

  get hiddenTags() {
    return this.tags.filter((seg: any) => !this.visibleTags.includes(seg));
  }

  @action
  tagColumns(hiddenOrVisible: string) {
    return [
      {
        label: this.intl.t('account.visibility.tags.tag-name'),
        valueComponent: 'visibility/tags/table-cells/name',
      },
      {
        label: this.intl.t('account.visibility.tags.created-by'),
        valueComponent: 'visibility/tags/table-cells/created-by',
      },
      {
        label: this.intl.t('account.visibility.tags.created'),
        valueComponent: 'visibility/tags/table-cells/created',
      },
      {
        valueComponent: 'visibility/tags/table-cells/links',
      },
      {
        valueComponent: `visibility/tags/table-cells/actions-for-${hiddenOrVisible}`,
      },
    ];
  }

  @action
  scrollToBottomOfVisibleTagsTable() {
    let tableBody = document.querySelector('.visibility__tags-table tbody');
    if (tableBody) {
      tableBody.scrollIntoView(false);
    }
  }

  @action
  showTag(tag: $TSFixMe) {
    this.admin.addTagIdsToAdminsVisibleTagIds([tag.get('id')]);
    next(() => this.scrollToBottomOfVisibleTagsTable());
    this.admin.save();
  }

  @action
  hideTag(tag: $TSFixMe) {
    this.admin.removeTagIdsFromAdminsVisibleTagIds([tag.get('id')]);
    this.admin.save();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Personal::VisibleToYou::Tags': typeof Tags;
  }
}
