/* import __COLOCATED_TEMPLATE__ from './submenu-section.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */

import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { type NavItem } from './submenu-items';
import type IntlService from 'ember-intl/services/intl';
import { tracked } from '@glimmer/tracking';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

interface SubmenuSectionArgs {
  section: any;
  route: string;
  keyword: string;
  titleClass?: string;
  linkClass?: string;
  hasNoChildren?: boolean;
  routeRegex: string | RegExp;
  items: NavItem[];
  activeSubmenu: string;
  onSubmenuChange: (submenu: string) => void;
}

interface Signature {
  Element: HTMLDivElement;
  Args: SubmenuSectionArgs;
}

export default class SubmenuSection extends Component<Signature> {
  @service declare router: RouterService;
  @service declare intl: IntlService;
  @service declare appService: $TSFixMe;
  @service declare helpCenterService: $TSFixMe;
  @service declare customerService: $TSFixMe;

  @tracked isSubmenuOnActiveRouteOrToggled = this.isOnDefinedRoute;
  @tracked showCreateHelpCenterModal = false;

  itemTitle = (item: NavItem) => {
    if (this.args.keyword === 'helpcenter' && item.key === 'new-help-center') {
      return this.intl.t(`new-settings.submenu.${this.args.keyword}.new-help-center-menu`);
    } else {
      return this.intl.t(`new-settings.submenu.${this.args.keyword}.${item.key}`);
    }
  };

  get isSubmenuOpen() {
    if (this.isOnDefinedRoute) {
      return this.isSubmenuOnActiveRouteOrToggled;
    } else {
      return this.args.keyword === this.args.activeSubmenu;
    }
  }

  itemRoute = (item: NavItem): string => {
    return this.appService.app.canSeeIASettingsGA && item.gaRoute
      ? item.gaRoute
      : (item.route as string);
  };

  @action
  toggleSubmenuOpen() {
    if (this.isOnDefinedRoute) {
      this.isSubmenuOnActiveRouteOrToggled = !this.isSubmenuOnActiveRouteOrToggled;
    }
    this.args.onSubmenuChange(this.args.keyword);
  }

  get isOnDefinedRoute(): boolean {
    return Boolean(this.router.currentRouteName.match(this.args.routeRegex));
  }

  @action
  isSectionItemActive(item: NavItem): boolean {
    if (item.routeRegex) {
      return Boolean(this.router.currentRouteName.match(item.routeRegex));
    } else if (item.route) {
      return Boolean(this.router.currentRouteName.match(item.route));
    }
    return false;
  }

  get titleRedirectLink() {
    if (!this.args.hasNoChildren && this.args.items.firstObject) {
      return this.args.items.firstObject.route;
    }
    return 'apps.app.settings.index';
  }

  get appName() {
    return this.appService.app.name;
  }

  get app() {
    return this.appService.app;
  }

  get sectionTitle() {
    if (this.args.keyword === 'helpcenter') {
      return this.intl.t('new-settings.submenu.helpcenter.title', { workspace: this.appName });
    }
    if (this.args.keyword === 'proactive-support') {
      return this.intl.t('new-settings.submenu.proactive-support.outbound-title');
    }
    return this.intl.t(`new-settings.submenu.${this.args.keyword}.title`);
  }

  @action
  updateActiveMenu() {
    this.args.onSubmenuChange(this.args.keyword);
  }

  conditionalIcon(icon = 'alert'): InterfaceIconName {
    return icon as InterfaceIconName;
  }

  @action
  shouldShow(item: NavItem): boolean {
    if (this.app.canUseStandalone && !item.showForStandaloneApps) {
      return false;
    }

    if (item.shouldOnlyShowForStandaloneApps && !this.app.canUseStandalone) {
      return false;
    }

    if (item.hideOnFeatureFlag) {
      return !this.appService.app.canUseFeature(item.hideOnFeatureFlag);
    }
    if (item.showOnFeatureFlag) {
      return this.appService.app.canUseFeature(item.showOnFeatureFlag);
    }
    return item.customDisplayCondition?.(this.appService.app) ?? true;
  }

  @action
  navigableItem(item: NavItem): boolean {
    if (item.navigableCondition) {
      return item.navigableCondition(this.customerService.customer);
    }
    return true;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Navigation::SubmenuSection': typeof SubmenuSection;
    'new-settings/navigation/submenu-section': typeof SubmenuSection;
  }
}
