/* import __COLOCATED_TEMPLATE__ from './setup-and-go-live.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { statisticKeys } from 'embercom/models/data/outbound/constants';
import type { ChannelType } from 'embercom/lib/workflows/fin-workflow-preview';
import type ContentWrapper from 'embercom/models/content-service/content-wrapper';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import {
  BULK_ACTION_PROGRESSION_ID,
  BulkActionProgressionValues,
  type BulkActionProgressionEvent,
} from 'embercom/services/knowledge-hub-service';
import {
  EMAIL_CHANNEL,
  FIN_CHAT_CHANNELS,
  PHONE_CHANNEL,
} from 'embercom/controllers/apps/app/automation/fin-ai-agent/setup';
import ENV from 'embercom/config/environment';
import type AiAgentSetupService from 'embercom/services/ai-agent-setup-service';
import { type BannerContent } from './fin-content-banner';
import type finTrialService from 'embercom/services/fin-trial-service';
import type Profile from 'embercom/models/ai-agent/profile';
import type Store from '@ember-data/store';
import { action } from '@ember/object';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';
import { type TaskGenerator } from 'ember-concurrency';
import type RouterService from '@ember/routing/router-service';

const SORT_BY = 'updated_at';
const SORT_DIRECTION_ASC = 'desc';

interface Arguments {
  liveFinWorkflowsOverridableBySetup: ContentWrapper[];
  hasContentReadyForFin: boolean;
  reloadSetupRuleset: (setupType: ChannelType) => void;
  previewConfiguration: $TSFixMe;
  setupRuleset: $TSFixMe;
  channelType: ChannelType;
  noPreview?: boolean;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Arguments;
}

export default class SetupAndGoLive extends Component<Signature> {
  @service declare appService: any;
  @service declare store: Store;
  @service declare outboundHomeService: any;
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare realTimeEventService: { on: Function; off: Function; subscribeTopics: Function };
  @service declare contentEditorService: $TSFixMe;
  @service declare aiAgentSetupService: AiAgentSetupService;
  @service declare finTrialService: finTrialService;
  @service declare router: RouterService;

  @tracked ruleset: $TSFixMe;
  @tracked selectedLocale: string;
  @tracked openBotSectionId: string | null = null;
  @tracked workflows: ContentWrapper[] = [];
  @tracked finWorkflowsLastPageHit = false;

  setupSectionId = 'fin_setup_section';
  workflowsSectionId = 'workflows_section';

  workflowsOverview = {
    pageSize: 100,
    currentPage: 0,
    objectTypes: [
      objectTypes.customBot,
      objectTypes.inboundCustomBot,
      objectTypes.buttonCustomBot,
      objectTypes.triggerableCustomBot,
    ],
  };

  constructor(owner: unknown, args: any) {
    super(owner, args);

    this.selectedLocale = this.appService.app.locale;

    if (this.appService.app.finBlockedInTestApp) {
      return;
    }

    let currentRuleset = this.contentEditorService.ruleset;
    this.contentEditorService.unregister(currentRuleset);

    this.updateRuleset(this.args.setupRuleset);
    if (this.contentEditorService.ruleset?.id !== this.ruleset.id) {
      this.contentEditorService.register({ ruleset: this.ruleset });
    }

    this.setBulkActionProgressActions();

    this.setBotAccordion();

    taskFor(this.fetchFinWorkflows).perform();
  }

  setBotAccordion() {
    if (
      this.args.channelType === 'chat' &&
      this.aiAgentSetupService.finStats.liveFinProfilesCount > 0
    ) {
      return;
    }

    if (this.isFinSetupLive && this.isFinLiveInWorkflows) {
      return;
    }

    if (this.isFinSetupLive || !this.isFinLiveInWorkflows) {
      this.openBotSectionId = this.setupSectionId;
    } else {
      this.openBotSectionId = this.workflowsSectionId;
    }
  }

  willDestroy(): void {
    super.willDestroy();
    this.destroyNexusEvents();
  }

  get isFinSetupLive() {
    return this.contentEditorService.ruleset.isLive;
  }

  get isFinLiveInWorkflows() {
    if (this.args.channelType === EMAIL_CHANNEL) {
      return this.aiAgentSetupService.finStats.workflowsCount.liveFinEmailWorkflowsCount > 0;
    }

    return this.aiAgentSetupService.finStats.workflowsCount.liveFinChatWorkflowsCount > 0;
  }

  get emailSetupValid() {
    let result = this.aiAgentSetupService.validateEmailSetup;
    return result.isValid;
  }

  get canSetFinLive() {
    return (
      !this.isFinSetupLive &&
      this.args.hasContentReadyForFin &&
      this.appService.app.canUseExternalAi &&
      (this.args.channelType === EMAIL_CHANNEL ? this.emailSetupValid : true) &&
      this.behavior.targetChannels.length
    );
  }

  get bannerContent(): BannerContent {
    if (this.args.channelType === EMAIL_CHANNEL) {
      return {
        heading: 'operator.fin.setup.tabs.setup-and-go-live.banner.non-workflows.email.heading',
        description:
          'operator.fin.setup.tabs.setup-and-go-live.banner.non-workflows.email.description',
        helpLink: {
          text: 'operator.fin.setup.tabs.setup-and-go-live.banner.non-workflows.email.help-link',
          url: 'https://www.intercom.com/help/en/articles/9356221-fin-ai-agent-over-email',
        },
        dismissalKey: 'discovery-banner-fin-over-email-non-workflows',
      };
    }
    return {
      heading: this.args.hasContentReadyForFin
        ? 'operator.fin.setup.tabs.setup-and-go-live.banner.non-workflows.ready-for-live.heading'
        : 'operator.fin.setup.tabs.setup-and-go-live.banner.non-workflows.content-empty.heading',
      description: this.args.hasContentReadyForFin
        ? 'operator.fin.setup.tabs.setup-and-go-live.banner.non-workflows.ready-for-live.description'
        : 'operator.fin.setup.tabs.setup-and-go-live.banner.non-workflows.content-empty.description',
      dismissalKey: this.args.hasContentReadyForFin
        ? `discovery-banner-fin-ready-for-live`
        : `discovery-banner-fin-content-empty`,
    };
  }

  get behavior(): Profile {
    return this.ruleset.rulesetLinks.firstObject.object;
  }

  get localeOptions() {
    return this.behavior.genericTriageLocalizations.map((localization: $TSFixMe) => ({
      locale_name: localization.name,
      locale_id: `(${localization.locale.charAt(0).toUpperCase()}${localization.locale.slice(1)})`,
      value: localization.locale,
      state: true,
      default: localization.default,
      component: 'operator/generic-triage/locale-option-without-state-badge',
    }));
  }

  get hasOverridableWorkflows(): boolean {
    return this.args.liveFinWorkflowsOverridableBySetup.length > 0;
  }

  get isLoadingWorkflows() {
    return taskFor(this.fetchFinWorkflows).isRunning;
  }

  get hasFinWorkflowsForSelectedChannel(): boolean {
    return this.workflows.length > 0;
  }

  get shouldShowProfilesLink(): boolean {
    return (
      this.args.channelType === 'chat' && this.aiAgentSetupService.finStats.liveFinProfilesCount > 0
    );
  }

  get parentAppUrl(): string {
    return this.router.urlFor(
      'apps.app.automation.fin-ai-agent.setup',
      this.appService.app.parent_app_id,
    );
  }

  get channelSubheading(): string {
    switch (this.args.channelType) {
      case EMAIL_CHANNEL:
        return 'operator.fin.setup.tabs.setup-and-go-live.simple-setup.email-subheading';
      case PHONE_CHANNEL:
        return 'operator.fin.setup.tabs.setup-and-go-live.simple-setup.phone-subheading';
      default:
        return 'operator.fin.setup.tabs.setup-and-go-live.simple-setup.chat-subheading';
    }
  }

  private setBulkActionProgressActions() {
    this.realTimeEventService.on(BULK_ACTION_PROGRESSION_ID, this, 'handleBulkActionProgression');
  }

  private destroyNexusEvents() {
    this.realTimeEventService.off(BULK_ACTION_PROGRESSION_ID, this, 'handleBulkActionProgression');
  }

  async handleBulkActionProgression(event: BulkActionProgressionEvent) {
    let currentAdmin = this.appService.app.currentAdmin;
    if (currentAdmin.id !== event.admin_id.toString()) {
      return;
    }

    if (event.status === BulkActionProgressionValues.Complete) {
      if (!event.all_entities_unaffected) {
        setTimeout(() => {
          this.knowledgeHubService.fetchKnowledgeUsageSummary();
        }, ENV.APP._500MS);
      }
    }
  }

  @action
  openBotSectionChange(sectionId: string) {
    this.openBotSectionId = sectionId;
  }

  @action
  goToVoiceTesting() {
    this.router.transitionTo({ queryParams: { tab: 'voice-playground' } });
  }

  updateRuleset = (ruleset: $TSFixMe) => {
    let peekRuleset = this.store.peekRecord('matching-system/ruleset', ruleset.id);
    if (!peekRuleset) {
      this.store.pushPayload({ 'matching-system/ruleset': ruleset });
      peekRuleset = this.store.peekRecord('matching-system/ruleset', ruleset.id);
    }
    this.ruleset = peekRuleset;
    this.args.reloadSetupRuleset(this.args.channelType);
  };

  async contentSearch(pageFrom: number): Promise<object> {
    return this.outboundHomeService.contentSearchWithFilters(
      this.getWorkflowsSearchParams(pageFrom),
    );
  }

  private getWorkflowsSearchParams(pageFrom: number) {
    return {
      object_types: this.workflowsOverview.objectTypes,
      app_id: this.appService.app.id,
      per_page: this.workflowsOverview.pageSize,
      page_from: pageFrom,
      statistics: [statisticKeys.receipts],
      additionalSearchableData: {
        has_fin: true,
        target_channels:
          this.args.channelType === EMAIL_CHANNEL ? EMAIL_CHANNEL : FIN_CHAT_CHANNELS,
      },
      sort_by: SORT_BY,
      sort_direction: SORT_DIRECTION_ASC,
      intersect_keys: ['additional_searchable_data.target_channels'],
    };
  }

  @task({ restartable: true })
  *fetchFinWorkflows(): TaskGenerator<void> {
    let response = yield this.contentSearch(this.workflowsOverview.currentPage);
    this.workflowsOverview.currentPage = 0;
    this.workflows = response.contentWrappers;
    this.finWorkflowsLastPageHit = response.lastPageHit;
  }

  @task({ drop: true })
  *loadMoreFinWorkflows(): TaskGenerator<void> {
    this.workflowsOverview.currentPage++;
    let response = yield this.contentSearch(this.workflowsOverview.currentPage);
    this.workflows = [...this.workflows, ...response.contentWrappers];
    this.finWorkflowsLastPageHit = response.lastPageHit;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::Setup::SetupAndGoLive': typeof SetupAndGoLive;
  }
}
