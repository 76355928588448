/* import __COLOCATED_TEMPLATE__ from './archive-modal.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from 'tracked-built-ins';
import { action } from '@ember/object';
import type AwayStatusReason from 'embercom/models/away-status-reason';
import type IntlService from 'ember-intl/services/intl';
// @ts-ignore
import type NotificationsService from 'embercom/services/notifications-service';

interface Args {
  awayStatusReason: AwayStatusReason;
  onClose: () => void;
  onArchive: () => void;
}

interface Signature {
  Element: never;
  Args: Args;
}

export default class ArchiveModal extends Component<Signature> {
  @service declare session: any;
  @service store: any;
  @service declare intl: IntlService;
  // @ts-ignore
  @service declare notificationsService: NotificationsService;

  @tracked isLoading = false;
  @tracked missingMinimumReasonsCount = false;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.fetchDependentObjects();
  }

  get canArchive() {
    return !this.missingMinimumReasonsCount;
  }

  get modalTitle() {
    return this.intl.t(this._translationKey('archive.modal-title'), {
      label: this.args.awayStatusReason.label,
    });
  }

  async fetchDependentObjects() {
    this.isLoading = true;
    try {
      let response = await this.args.awayStatusReason.dependentObjects();
      let json = await response.json();
      this.missingMinimumReasonsCount = json.missing_minimum_reasons_count;
    } catch (error) {
      this.notificationsService.notifyError(
        this.intl.t(this._translationKey('notifications.dependent-objects-failure')),
      );
      this.args.onClose();
    } finally {
      this.isLoading = false;
    }
  }

  @action async archive() {
    this.args.onArchive();
  }

  @action close() {
    this.args.onClose();
  }

  _translationKey(key: string) {
    return `new-settings.helpdesk.assignments.other.away-reasons.${key}`;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Helpdesk::AwayStatusReasons::ArchiveModal': typeof ArchiveModal;
  }
}
