/* import __COLOCATED_TEMPLATE__ from './multi-email-input.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { isValidEmail } from 'embercom/lib/email';
import { schedule } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { isEmpty, typeOf } from '@ember/utils';
import type IntlService from 'ember-intl/services/intl';
import type Store from '@ember-data/store';

const EMAIL_SEPARATORS = [',', '|', ';', ' ', '\n', '\t'];
const EMAIL_SPLIT_REGEX = new RegExp(`[${EMAIL_SEPARATORS.join('\\')}]+`);

interface Args {
  initialEmails: string[] | Email[];
  onUpdateEmails: (emails: string[] | Email[]) => void;
  placeholder: string;
  validateAgainstExistingTeammatesAndInvites: boolean;
  showError?: string;
  forceErrorMessage?: string;
  addingEmailsUnavailableMessage?: string;
  onUpdatePartialInput?: (input: string) => void;
}

export type Email = {
  id?: number;
  error: boolean;
  errorMessage: string | null;
  value: string;
};

export default class MultiEmailInput extends Component<Args> {
  @service intl!: IntlService;
  @service declare appService: $TSFixMe;
  @service declare store: Store;
  @tracked partialEmail = '';
  @tracked allEmails: Email[] = [];

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    if (this.args.validateAgainstExistingTeammatesAndInvites) {
      this.loadInvitedAdmins();
    }
    this._parseAndValidate(this.args.initialEmails || []);
  }

  get app() {
    return this.appService.app;
  }

  get hasError() {
    let anyError = this.allEmails.any((email) => !!email.error);
    return this.args.showError || anyError;
  }

  @action onInput(event: Event & { target: HTMLInputElement }) {
    this._updatePartialInputCallback(event.target.value);
  }

  @action removeEmailByIndex(index: number) {
    let emails = this.allEmails;
    if (emails.length === 0 || index < 0 || index >= emails.length) {
      return;
    }
    let tempAllEmails = this.allEmails;
    tempAllEmails.splice(index, 1);
    this.allEmails = tempAllEmails;
    this._updateAllEmailsCallback(this.allEmails);
    this._updatePartialInputCallback(this.partialEmail);
  }

  @action handleKeydown(event: KeyboardEvent) {
    if (event.key === 'Enter' || EMAIL_SEPARATORS.includes(event.key)) {
      event.preventDefault();
      if (isEmpty(this.partialEmail)) {
        return;
      }
      this._addEmail(this.partialEmail);
      this.partialEmail = '';
      return;
    }
    if (event.key === 'Backspace' && this.allEmails.length > 0 && isEmpty(this.partialEmail)) {
      event.preventDefault();
      this.removeEmailByIndex(this.allEmails.length - 1);
    }
    this._scrollIntoViewAfterRender(event.target as HTMLElement);
  }

  @action handlePaste(event: ClipboardEvent) {
    event.preventDefault();
    let value = event.clipboardData?.getData('text');

    if (value) {
      let parsedClipboard = this._parseClipboard(value);
      parsedClipboard.forEach((email) => {
        this._addEmail(email);
      });
      this._scrollIntoViewAfterRender(event.target as HTMLElement);
    }
  }

  @action handleBlur(_event: FocusEvent) {
    if (isEmpty(this.partialEmail)) {
      return;
    }

    this._addEmail(this.partialEmail);
    this.partialEmail = '';
  }

  @action handleFocus(_event: FocusEvent) {
    let lastEmail = this.allEmails.length > 0 ? this.allEmails[this.allEmails.length - 1] : null;
    if (lastEmail && lastEmail.error) {
      this.partialEmail = lastEmail.value;
      this.removeEmailByIndex(this.allEmails.length - 1);
    }
  }

  @action focusInput(obj: MouseEvent) {
    if (obj.currentTarget === obj.target) {
      let focusElement =
        (obj?.currentTarget as HTMLElement).getElementsByTagName('input')[0] || obj.currentTarget;
      focusElement.focus();
    }
  }

  _parseAndValidate(emails: string[] | Email[]) {
    let parsedEmails: Email[] = [];
    emails.forEach((email) => {
      if (typeOf(email) === 'string') {
        parsedEmails.pushObject({
          value: email as string,
          ...this._emailValidation(
            email as string,
            parsedEmails.map((el) => el.value),
          ),
        });
      } else {
        parsedEmails.pushObject(email as Email);
      }
    });

    this.allEmails = parsedEmails;
    this._updateAllEmailsCallback(this.allEmails);
  }

  _addEmail(email: string) {
    let validatedEmail = {
      value: email,
      ...this._emailValidation(email),
    };

    this.allEmails = [...this.allEmails, validatedEmail];
    this._updateAllEmailsCallback(this.allEmails);
  }

  async loadInvitedAdmins() {
    await this.store.findAll('invited-admin');
  }

  _emailValidation(email: string, existingEmails = this._existingEmails()) {
    let emailErrorMessage = this._emailErrorMessage(email, existingEmails) || null;
    return {
      error: emailErrorMessage !== null,
      errorMessage: emailErrorMessage,
    };
  }

  _emailErrorMessage(email: string, existingEmails = this._existingEmails()) {
    if (!isValidEmail(email)) {
      return this.intl.t('components.multi-email-input.invalid-email-format');
    }
    if (this.args.validateAgainstExistingTeammatesAndInvites) {
      let adminEmails = new Set(this.app.admins.map((admin: any) => admin.email.toLowerCase()));
      let alreadyInvitedTeammateEmails = new Set(
        this.store
          .peekAll('invited-admin')
          .filter((invite: any) => invite.active)
          .map((invite: any) => invite.email.toLowerCase()),
      );

      if (adminEmails.has(email.toLowerCase())) {
        return this.intl.t('components.multi-email-input.email-already-in-use');
      }
      if (alreadyInvitedTeammateEmails.has(email.toLowerCase())) {
        return this.intl.t('components.multi-email-input.email-already-invited');
      }
    }
    if (this._isExistingEmail(email.toLowerCase(), existingEmails)) {
      return this.intl.t('components.multi-email-input.duplicate-email');
    }
    return null;
  }

  _isExistingEmail(email: string, existingEmails = this._existingEmails()) {
    return existingEmails.filter((el) => el === email).length > 0;
  }

  _existingEmails(): string[] {
    return this.allEmails.map((el) => el.value);
  }

  _parseClipboard(value: string) {
    return value
      .trim()
      .split(EMAIL_SPLIT_REGEX)
      .map((email) => email.trim())
      .map((email) => {
        if (this._surroundedWithBrackets(email)) {
          return email.slice(1, email.length - 1);
        } else {
          return email;
        }
      })
      .filter((email) => email !== '');
  }

  _surroundedWithBrackets(email: string) {
    if (email[0] === '<' && email[email.length - 1] === '>') {
      return true;
    }
    return false;
  }

  _scrollIntoViewAfterRender(element: HTMLElement) {
    schedule('afterRender', () => {
      element.scrollIntoView();
    });
  }

  _updateAllEmailsCallback(emails: string[] | Email[]) {
    if (this.args.onUpdateEmails) {
      this.args.onUpdateEmails(emails);
    }
  }

  _updatePartialInputCallback(input: string) {
    if (this.args.onUpdatePartialInput) {
      this.args.onUpdatePartialInput(input);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    MultiEmailInput: typeof MultiEmailInput;
  }
}
