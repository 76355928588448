/* import __COLOCATED_TEMPLATE__ from './knowledge-hub-bulk-actions-update.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */

import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {
    notification: {
      data: {
        message: string;
        error?: boolean;
      };
    };
  };
}

const KnowledgeHubBulkActionsUpdate = templateOnlyComponent<Signature>();
export default KnowledgeHubBulkActionsUpdate;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Notifications::KnowledgeHubBulkActionsUpdate': typeof KnowledgeHubBulkActionsUpdate;
    'notifications/knowledge-hub-bulk-actions-update': typeof KnowledgeHubBulkActionsUpdate;
  }
}
