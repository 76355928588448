/* import __COLOCATED_TEMPLATE__ from './teammates-content.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { dropTask } from 'ember-concurrency-decorators';
import { getInboxSeatInfo } from 'embercom/lib/admins/inbox-seat-info';
import { inject as service } from '@ember/service';
import { taskFor } from 'ember-concurrency-ts';
import { action } from '@ember/object';

interface Args {
  teammates: any;
  activeInvitedAdmins: any;
  roles: any;
  selectedFilterType: any;
  selectedFilterValue: any;
  onNewSettingsPage?: boolean;
  selectedTab?: string;
  updateFilter: (filterType: string, filterValue: string) => void;
}

export default class TeammatesContent extends Component<Args> {
  @service declare permissionsMutatorService: $TSFixMe;
  @service appService: any;

  @dropTask
  *loadInboxSeatInfo(): any {
    return yield getInboxSeatInfo(1);
  }

  @action
  async toggleInboxSeat(admin: any) {
    if (admin.get('hasInboxAccess')) {
      await this.permissionsMutatorService.removeInboxSeat(admin, this.args.teammates);
      taskFor(this.loadInboxSeatInfo).perform();
    } else {
      await this.permissionsMutatorService.giveInboxSeat(admin);
      taskFor(this.loadInboxSeatInfo).perform();
    }
  }

  get containerPadding(): string {
    return this.args.selectedTab === 'activity-logs' ? 'pr-6 pb-6' : 'px-6 pb-6';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Workspace::Teammates::TeammatesContent': typeof TeammatesContent;
  }
}
