/* import __COLOCATED_TEMPLATE__ from './following-guidance.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import type { FinSetupBehaviorSaveParams } from 'embercom/lib/operator/fin/types';
import type IntlService from 'embercom/services/intl';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import type Guideline from 'embercom/models/ai-agent/guidelines';
import { tracked } from '@glimmer/tracking';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import type { ChannelType } from 'embercom/lib/workflows/fin-workflow-preview';
interface Args {
  accordion: $TSFixMe;
  channelType: ChannelType;
  ruleset: $TSFixMe;
  previewConfiguration: $TSFixMe;
  partialSave: (params: FinSetupBehaviorSaveParams) => void;
}

export default class FollowingGuidance extends Component<Args> {
  @service declare appService: $TSFixMe;
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare knowledgeHubService: KnowledgeHubService;
  @tracked guidelines: Guideline[] = [];

  constructor(owner: any, args: Args) {
    super(owner, args);
    this.loadGuidelines();
  }

  async loadGuidelines() {
    this.guidelines =
      ((await this.store.findAll('ai-agent/guidelines')) as unknown as Guideline[]) || [];
  }

  get subheader() {
    let rulesCount = this.guidelines.length;
    return rulesCount
      ? this.intl.t('operator.fin.setup.setup-and-go-live.following-guidance.subheader', {
          count: rulesCount,
        })
      : '';
  }

  get rulesSummary(): {
    title: string;
    icon: InterfaceIconName;
    type: string;
  }[] {
    let results = [];
    let guidelines = this.guidelines;
    let toneGuidelines = guidelines.filter((guideline) => guideline.category === 'tone');
    let clarificationGuidelines = guidelines.filter(
      (guideline) => guideline.category === 'clarification',
    );
    let routingGuidelines = guidelines.filter((guideline) => guideline.category === 'routing');
    let miscGuidelines = guidelines.filter((guideline) => guideline.category === 'other');
    if (toneGuidelines.length) {
      results.push({
        title: this.intl.t(
          'operator.fin.setup.setup-and-go-live.following-guidance.communication-style',
          {
            count: toneGuidelines.length,
          },
        ),
        icon: 'messenger' as InterfaceIconName,
        type: 'tone',
      });
    }

    if (clarificationGuidelines.length) {
      results.push({
        title: this.intl.t(
          'operator.fin.setup.setup-and-go-live.following-guidance.context-clarification',
          {
            count: clarificationGuidelines.length,
          },
        ),
        icon: 'help-space' as InterfaceIconName,
        type: 'clarification',
      });
    }

    if (routingGuidelines.length) {
      results.push({
        title: this.intl.t(
          'operator.fin.setup.setup-and-go-live.following-guidance.handover-escalation',
          {
            count: routingGuidelines.length,
          },
        ),
        icon: 'new-direct-message' as InterfaceIconName,
        type: 'handover',
      });
    }
    if (miscGuidelines.length) {
      results.push({
        title: this.intl.t('operator.fin.setup.setup-and-go-live.following-guidance.misc-rules', {
          count: miscGuidelines.length,
        }),
        icon: 'list' as InterfaceIconName,
        type: 'misc',
      });
    }
    return results;
  }
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::Setup::Sections::FollowingGuidance': typeof FollowingGuidance;
  }
}
