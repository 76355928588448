/* import __COLOCATED_TEMPLATE__ from './answer-summary.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type IntlService from 'ember-intl/services/intl';
import type Conversation from 'embercom/objects/inbox/conversation';
import type PartGroup from 'embercom/objects/inbox/conversation-stream/part-group';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import type FinAnswer from 'embercom/objects/inbox/renderable/fin-answer';

interface FinAnswerRenderablePart extends RenderablePart {
  renderableData: FinAnswer;
}
interface Signature {
  Element: HTMLDivElement;
  Args: {
    partGroup: PartGroup;
    part: FinAnswerRenderablePart;
    conversation: Conversation;
    isStandalone?: boolean;
  };
}

export default class AnswerSummary extends Component<Signature> {
  @service declare intl: IntlService;
  @tracked isExpanded = false;

  get issueSummary() {
    return (
      'issueSummary' in this.args.part.renderableData && this.args.part.renderableData.issueSummary
    );
  }

  get canToggleContentCard(): boolean | null {
    let contentCard = document.querySelector('.debugger-fin-answer-card-container');
    return contentCard && contentCard?.clientHeight > 85;
  }

  get cardActionLabel(): string {
    return this.isExpanded
      ? this.intl.t('operator.fin.answer-debugger.content-card.collapse')
      : this.intl.t('operator.fin.answer-debugger.content-card.expand');
  }

  @action
  toggleContentCard(): void {
    this.isExpanded = !this.isExpanded;
  }

  appendPublicSources = (part: FinAnswerRenderablePart) => {
    return !!part.renderableData.sources && part.renderableData.sources.length > 0;
  };

  get isReadOnly() {
    return this.args.isStandalone || false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::AnswerDebugger::AnswerSummary': typeof AnswerSummary;
  }
}
