/* import __COLOCATED_TEMPLATE__ from './guide-card.hbs'; */
/* RESPONSIBLE TEAM: team-self-serve */

import Component from '@glimmer/component';
import { action } from '@ember/object';

interface Args {
  title: any;
  description: any;
  image: any;
  buttonCta?: string;
  imageWidth?: string;
  descriptionClass?: string;
  onCardClick: () => void;
}

interface Signature {
  Args: Args;
  Element: never;
}

export default class GuideCard extends Component<Signature> {
  @action
  handleClick() {
    this.args.onCardClick();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::GettingStarted::Common::GuideCard': typeof GuideCard;
    'onboarding/getting-started/common/guide-card': typeof GuideCard;
  }
}
